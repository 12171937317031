import React from 'react';
import { CardStepSa } from 'hult-toolkit';
import { useAppContext } from '../../context/AppContext';
import { ISbStoryData } from '@storyblok/react';

export interface ICardResource {
  blok: ISbStoryData & {
    label: string;
    title: string;
    description: string;
    link: any;
    doc: string;
  };
}

export const CardResource: React.FC<ICardResource> = ({ blok }) => {
  const { acceptanceLetter } = useAppContext();

  let resourceIcon = 'arrow-up-right-from-square';

  switch (blok.label) {
    case 'App':
      resourceIcon = 'mobile';
      break;
    case 'Doc':
      resourceIcon = 'memo';
      break;
    case 'Link':
      resourceIcon = 'arrow-up-right-from-square';
      break;
    case 'Site':
      resourceIcon = 'desktop';
      break;
    default:
      break;
  }

  function getAcceptanceLetterUrl() {

    const acceptanceLetterId = acceptanceLetter?.acceptanceLetter;
    const acceptanceLetterConditionsMet = acceptanceLetter?.notifiedDate;

    if (acceptanceLetterConditionsMet) {
      if (acceptanceLetterId) {
        return `${process.env.REACT_APP_ACCEPTANCE_URL}=${acceptanceLetterId}`;
      }

      if (acceptanceLetter?.acceptanceLetterUrl) {
        return acceptanceLetter?.acceptanceLetterUrl;
      }
    }

    return '';
  }

  const cardOnClick = blok.doc === 'Acceptance Letter' ? getAcceptanceLetterUrl() : blok.link.url

  return (
    <CardStepSa
      desc={blok.description}
      label={blok.label}
      onClick={() => window.open(cardOnClick, '_blank')}
      resource_icon={resourceIcon}
      state="resource"
      title={blok.title}
      data_name={blok.title}
    />
  );
}