import React, {useState} from 'react';
import {Icon} from '../ui/icon/icon';
import {
  StyledInvoiceContainer,
  StyledInvoiceHeader,
  StyledInvoiceBody,
  StyledInvoiceFooter,
  StyledContractInfoWrapper,
  StyledContractInfo,
  StyledInvoicedHeaderTitle,
  StyledPaymentPlanWrapper,
  StyledInvoicePaymentDataList,
  StyledInvoicePaymentData,
  StyledBold,
  StyledButtonContainer,
  StyledButton,
  StyledHideButton,
  StyledIcon,
} from './billing.styles';

import usd from '../../assets/images/usd.svg';
import aed from '../../assets/images/aed.svg';
import gbp from '../../assets/images/gbp.svg';
interface IInvoiceContainer {
  children?: React.ReactNode;
  childrenLength?: number;
  campus?: string;
  program?: string;
  status?: string;
  contractAcademicYear?: string;
  contractPaymentPlan?: string;
  contractCredits?: string;
  currency?: string;
  total?: string;
  paid?: string;
  paymentDue?: string;
  tab: string;
  showPaidInvoices?: any;
  onTogglePaidInvoices?: () => void;
  hasPaidInvoices?: boolean;
  buttonLabel?: React.ReactNode;
}

export const InvoiceContainer: React.FC<IInvoiceContainer> = ({
  children,
  childrenLength,
  campus,
  program,
  contractAcademicYear,
  contractPaymentPlan,
  contractCredits,
  currency,
  total,
  paid,
  paymentDue,
  tab,
  hasPaidInvoices,
  onTogglePaidInvoices,
  buttonLabel,
}) => {
  const [isBodyVisibleProgram, setIsBodyVisibleProgram] = useState(true);

  const handleToggleBodyProgram = () => {
    setIsBodyVisibleProgram(!isBodyVisibleProgram);
  };

  return (
    <StyledInvoiceContainer>
      {tab === 'unpaidInvoices' ? (
        <StyledInvoiceHeader bodyVisible={childrenLength !== 0}>
          <StyledInvoicedHeaderTitle>
            <Icon icon="exclamation-circle" />
            <p>You have x{childrenLength} Invoices requiring attention</p>
          </StyledInvoicedHeaderTitle>
        </StyledInvoiceHeader>
      ) : (
        <StyledInvoiceHeader bodyVisible={isBodyVisibleProgram}>
          <StyledContractInfoWrapper>
            <StyledContractInfo>
              {program && <p>{program}</p>}
              {campus && (
                <p>
                  {campus}, {contractAcademicYear}
                </p>
              )}
            </StyledContractInfo>

            <StyledPaymentPlanWrapper>
              <p>Payment Plan: {contractPaymentPlan}</p>
              <p>Credits: {contractCredits}</p>
            </StyledPaymentPlanWrapper>
          </StyledContractInfoWrapper>

          <StyledInvoicePaymentDataList container={true}>
            {currency && (
              <StyledInvoicePaymentData>
                <p>Currency</p>
                <p>
                  {currency === 'USD' && <img alt="Hult" src={usd} />}
                  {currency === 'GBP' && <img alt="Hult" src={gbp} />}
                  {currency === 'AED' && <img alt="Hult" src={aed} />}
                  {currency}
                </p>
              </StyledInvoicePaymentData>
            )}

            {total && (
              <StyledInvoicePaymentData>
                <p>Total</p>
                <p>{total}</p>
              </StyledInvoicePaymentData>
            )}

            {paid && (
              <StyledInvoicePaymentData>
                <p>Paid</p>
                <p>{paid}</p>
              </StyledInvoicePaymentData>
            )}

            {paymentDue && (
              <StyledInvoicePaymentData>
                <p>Payment due</p>
                <StyledBold status={paymentDue === '0' ? 'Paid' : ''}>
                  {paymentDue}
                </StyledBold>
              </StyledInvoicePaymentData>
            )}
          </StyledInvoicePaymentDataList>

          <StyledButtonContainer>
            <StyledButton onClick={handleToggleBodyProgram}>
              <p>{isBodyVisibleProgram ? 'Hide' : 'Show'} Invoices</p>
              <StyledIcon hideInvoice={true} bodyVisible={isBodyVisibleProgram}>
                <Icon icon="chevron-up" />
              </StyledIcon>
              <StyledIcon
                hideInvoice={true}
                bodyVisible={!isBodyVisibleProgram}
              >
                <Icon icon="chevron-down" />
              </StyledIcon>
            </StyledButton>
          </StyledButtonContainer>
        </StyledInvoiceHeader>
      )}

      {children && React.Children.count(children) > 0 && (
        <>
          {tab === 'unpaidInvoices' && (
            <StyledInvoiceBody>{children}</StyledInvoiceBody>
          )}

          {tab === 'invoicesByProgram' && isBodyVisibleProgram && (
            <>
              <StyledInvoiceBody>{children}</StyledInvoiceBody>
              <StyledInvoiceFooter>
                {hasPaidInvoices && (
                  <StyledHideButton onClick={onTogglePaidInvoices}>
                    <p>{buttonLabel}</p>
                  </StyledHideButton>
                )}
              </StyledInvoiceFooter>
            </>
          )}
        </>
      )}
    </StyledInvoiceContainer>
  );
};