import { IApplication, IAppState } from '../context/AppContext/appContext.types';
// import {useAppContext} from '../context/AppContext';

export function findBillingApplication(selectedApplication: string, applicationList: IAppState["applicationList"]): IApplication | undefined {

  let activeApplication: IApplication | undefined

  if (selectedApplication && applicationList) {
    const billingApplications: Array<IApplication> = applicationList.billing || [];
    activeApplication = billingApplications.find((item) => item.guid === selectedApplication);
  }

  return activeApplication;
}

export function findApplication(selectedApplication: string, applicationList: IAppState["applicationList"]): IApplication | undefined {
  let activeApplication: IApplication | undefined;
  if (applicationList && selectedApplication) {
    const { billing, owner } = applicationList;
    const applications: Array<IApplication> = (billing || []).concat(owner || []);
    activeApplication = applications.find((item) => item.guid === selectedApplication);
  }
  return activeApplication;
}
