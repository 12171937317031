import {ReactNode, createContext, useContext, useState, useEffect} from 'react';
import {useStoryblok} from '@storyblok/react';
import {PageLoader} from '../../components/ui/pageLoader';
import {ISettings} from './settings.types';

const SettingsContext = createContext<ISettings>({});

const SettingsContextProvider: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const [state, setState] = useState<ISettings>({});
  const {content} = useStoryblok('sa3-1/config/settings', {
    version: (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') || 'published',
  });

  useEffect(() => {
    let nextState = Object.assign({}, state);

    if (content?.navigation && content.navigation[0]) {
      const logo = content.navigation[0]?.logo?.filename;
      nextState = Object.assign({}, nextState, {navigation: {logo}});
    }

    if (content?.footer && content.footer[0]) {
      const text = content.footer[0].text;
      nextState = Object.assign({}, nextState, {footer: {text}});
    }

    if (content?.error_message && content.error_message[0]) {
      nextState = Object.assign({}, nextState, {
        errorMessage: {...content.error_message[0]},
      });
    }

    if (content?.thankyou_message && content.thankyou_message[0]) {
      nextState = Object.assign({}, nextState, {
        thankYouMessage: {...content.thankyou_message[0]},
      });
    }

    if (content?.background && content.background) {
      nextState = Object.assign({}, nextState, {
        background: {...content.background},
      });
    }

    setState(nextState);
  }, [content]);

  if (!content) {
    return <PageLoader />;
  }

  return (
    <SettingsContext.Provider value={state}>
      {children}
    </SettingsContext.Provider>
  );
};

function useSettingsContext() {
  return useContext(SettingsContext);
}

export {SettingsContextProvider, useSettingsContext};
