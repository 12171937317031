import React, { useState } from 'react';
import { ProgressBarSa } from 'hult-toolkit';
import 'hult-toolkit/dist/style.css';
import { useAppContext } from '../../context/AppContext';
import { IStep } from '../../data/types';
import { parseSteps } from '../StepListContainer/helpers';
import { useGetRequirementsV2Query } from '../../graphql';
import { ISbStoryData } from '@storyblok/react';
import { ICompletedSteps } from '../../context/AppContext/appContext.types';

export interface IProgressBar {
  steps: Array<IStep>;
  completedSteps?: any;
  nonCompletedSteps?: any;
  blok: ISbStoryData & {
    steps: Array<{
      step_label: string;
    }>;
  };
}

export const ProgressBar: React.FC<IProgressBar> = ({ steps, blok }) => {
  const { appStage, completedStepsDetails, selectedApplication, acceptanceLetter } = useAppContext();
  const [requirements, setRequirements] = useState<{
    completedSteps: IStep[];
    nonCompletedSteps: IStep[];
  }>({ completedSteps: [], nonCompletedSteps: [] });

  const { loading, error } = useGetRequirementsV2Query({
    variables: {
      guid: selectedApplication || '',
    },
    fetchPolicy: 'cache-and-network',
    onCompleted({ getApplicationRequirements }) {
      if (getApplicationRequirements) {
        const reqNames: string[] = getApplicationRequirements.flatMap(
          r => r?.step as string
        );

        reqNames.push('decfinance');

        const clearedSteps = steps.filter(s => {
          const idx = reqNames.indexOf(s.content.step_name);
          if (idx > -1) {
            if (
              s.content.step_name === 'decfinance' &&
              !getApplicationRequirements[idx]?.showOnApp
            ) {
              return false;
            }
          }
          return true;
        });

        setRequirements(
          parseSteps(
            clearedSteps,
            completedStepsDetails as ICompletedSteps
          )
        );
      }
    },
    onError(error) {
      console.log("useGetRequirementsV2Query error",error);
    },
  });

  const appStages = [
    'Enquiry',
    'In Progress',
    'Partial Application',
    'Accepted',
    'Confirmed',
  ];

  if (!appStage) {
    return null;
  }

  const mappedAppStage = appStage.replace('Conditionally ', '');

  let currentStepIndex = appStages.indexOf(mappedAppStage);

  if (currentStepIndex === -1) {
    return null;
  }

  const progressBarSteps: any[] = appStages.map((stage, index) => {
    const totalSteps = requirements.nonCompletedSteps.length + requirements.completedSteps.length;
    const completedPercentage = stage === 'Confirmed' ? 100 : (requirements.completedSteps.length / totalSteps) * 100;
    const isCurrentStep = index === currentStepIndex;
    const hideProgressLine = index === appStages.length - 1 ? 'hide' : undefined;
    const blokStep = blok.steps.find((blokStep, blokIndex) => blokIndex === index);
    const stepLabel = blokStep?.step_label || stage;

    if ((mappedAppStage === 'Accepted') && (!acceptanceLetter?.notifiedDate)) {
      currentStepIndex = 2;
    } else if ((mappedAppStage === 'Partial Application') && (!completedStepsDetails?.submitApplicationCompleted)) {
      currentStepIndex = 1;
    }

    return {
      active: isCurrentStep ? completedPercentage : index < currentStepIndex ? 100 : 0,
      hide_progress_line: hideProgressLine,
      label: stepLabel || stage,
    };
  });

  return (
    <>
      <ProgressBarSa steps={progressBarSteps} />
    </>
  );
};