import { useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { render } from 'storyblok-rich-text-react-renderer';
import { IMultiStep } from '../../data/types';
import { StyledQuestion } from './steps.styles';
import { useAppContext } from '../../context/AppContext';
import { useSettingsContext } from '../../context/SettingsContext';
import { useGetQuestionV2Query } from '../../graphql';
import { questionPayloadRender } from './steps.helpers';
import { AlertSa } from 'hult-toolkit';
import { variantMapping } from '../../utils/variantMapping';
import { LoadingInput } from '../QuestionInputs/TextInput/TextInput.helpers';
import { useQuestionContext } from '../../context/QuestionContext';
import { useStepContext } from '../../context/StepContext';
import QuestionsList from '../QuestionsList';

export const MultiStepStandard: FC<IMultiStep> = ({
  step_category_name = '',
  step_name,
  questions = [],
  isPreview,
}) => {

  const navigate = useNavigate();
  const { questionDispatch } = useQuestionContext();
  const { stepDispatch } = useStepContext();

  const { selectedApplication } = useAppContext();

  const { errorMessage } = useSettingsContext();
  const [isLoading, setIsLoading] = useState(true);

  const { error } = useGetQuestionV2Query({
    variables: {
      guid: selectedApplication || '',
      step: step_name || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: isPreview || !selectedApplication || !step_name,
    onCompleted({ getApplicationStep }) {

      const questionPayload = questionPayloadRender({
        isMultiStep: true,
        step_name: step_name || '',
        questions,
        savedValues: getApplicationStep,
        stepCategory: step_category_name
      })

      questionDispatch({
        type: 'update-question-context',
        payload: questionPayload
      })

      stepDispatch({
        type: 'update-step-context',
        payload: getApplicationStep
      })

      navigate(`/question?step=${step_category_name}&editing=${step_name}&q=${questionPayload.questionSlug}`)

      setIsLoading(false)

    },
    onError(err) {
      console.log("useGetQuestionV2Query error", err.message);
    },
  });

  if (isLoading) {
    return (
      <div className='input-wrapper'>
        <LoadingInput />
      </div>
    );
  }

  if (error) {
    console.error(error);

    return (
      <StyledQuestion>
        {errorMessage && (
          <AlertSa
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage?.variant].icon_color}
            title={errorMessage.title || ''}
          />
        )}
      </StyledQuestion>
    );
  }

  return (
    <StyledQuestion>
      <QuestionsList
        step_name={step_name}
        error={error}
        loading={isLoading}
      />
    </StyledQuestion>
  );
};
