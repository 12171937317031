import {useEffect} from 'react';
import {InteractionStatus} from '@azure/msal-browser';
import {
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import {loginRequest} from '../services/msal/config';
import {PageLoader} from '../components/ui/pageLoader';

import {Breadcrumbs} from '../components/BreadCrumb';
import {AccountInfo} from '../components/AccountInfo';
import {useAppDispatch} from '../context/AppContext';
import {useGetUserData} from '../context/AppContext/useGetUserData';
import { Layout } from 'hult-toolkit';

function AuthenticatedAccount() {
  const dispatch = useAppDispatch();
  useGetUserData(dispatch);
  return (
    <>
      <Breadcrumbs title="Account" />
      <AccountInfo />
    </>
  );
}

export function Account() {
  const isAuthenticated = useIsAuthenticated();
  const {instance, inProgress} = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
  });

  return (
    <Layout>
      <AuthenticatedTemplate>
        <AuthenticatedAccount />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <PageLoader />
      </UnauthenticatedTemplate>
    </Layout>
  );
}
