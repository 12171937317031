import styled from 'styled-components';

export const StyledPayerManagement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledPopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: #00000085;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledPopUpContent = styled.div`
  width: 580px;
`