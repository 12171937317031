interface IHeaderNavigation {
  label: string;
  link: string;
}

const headerNavigation: IHeaderNavigation[] = [
  {
    label: 'Bring a Friend',
    link: 'https://www.hult.edu/landing/hult/bringafriend/?utm_campaign=Appfriendheader',
  },
  {
    label: 'My Account',
    link: 'account',
  },
];

export default headerNavigation;
