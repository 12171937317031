import { useEffect, useState } from 'react';
import { IRecommendationUI } from './Recommendation.types';
import { checkIfOnlySpaces } from '../QuestionInputHelpers';
import { StyledRecommendationWrapper } from './recommendation.styles';
import { InputField } from 'hult-toolkit';
import { useAppContext } from '../../../context/AppContext';

export const RecommendationUI = ({
  defaultValue,
  handleChange,
}: IRecommendationUI) => {

  const { email } = useAppContext()
  const [recommendationObj, setRecommendationObj] = useState(defaultValue);
  const [recommendationEmail, setRecommendationEmail] = useState({
    value: defaultValue?.email,
    valid: false
  });
  const [isUserEmail, setIsUserEmail] = useState(false);

  useEffect(() => {
    const finalObj = {
      ...recommendationObj,
      email: recommendationEmail.value
    }

    if (recommendationObj) {
      const checkValidity = Object.entries(finalObj).every(ent => {
        return !checkIfOnlySpaces(ent[1] || '');
      });

      handleChange({
        value: {
          ...recommendationObj,
          email: recommendationEmail.value
        },
        valid: checkValidity && !isUserEmail && recommendationEmail.valid,
      });
    }
  }, [recommendationObj, recommendationEmail]);

  const recommendationEmailChange = (e: any) => {
    const sameAsUserEmail = e?.value === email

    setIsUserEmail(sameAsUserEmail)

    setRecommendationEmail({
      valid: e.valid,
      value: e.value ?? ''
    })
  }

  return (
    <StyledRecommendationWrapper>
      <InputField
        label="First Name"
        type='text'
        name="firstName"
        default_value={defaultValue?.firstName || ''}
        min_length={2}
        required
        onChange={(e) => {
          setRecommendationObj({
            ...recommendationObj,
            firstName: e?.value,
          });
        }}
      />
      <InputField
        label="Last Name"
        type='text'
        name="lastName"
        min_length={2}
        required
        default_value={defaultValue?.lastName || ''}
        onChange={(e) => {
          setRecommendationObj({
            ...recommendationObj,
            lastName: e?.value,
          });
        }}
      />
      <InputField
        name='name'
        type='text'
        label="Company Name or School Name"
        min_length={2}
        required
        default_value={defaultValue?.companyName || ''}
        onChange={(e) => {
          setRecommendationObj({
            ...recommendationObj,
            companyName: e.value,
          });
        }}
      />
      <InputField
        type="email"
        label="Email"
        name='email'
        required
        onChange={recommendationEmailChange}
        default_value={defaultValue?.email}
        invalid={isUserEmail}
        error_message={isUserEmail ? "You can't use your own email address" : ""}
      />
      <InputField
        type="text"
        name="jobTitle"
        label="Position/Job Title"
        min_length={2}
        required
        default_value={defaultValue?.jobTitle || ''}
        onChange={(e) =>
          setRecommendationObj({
            ...recommendationObj,
            jobTitle: e.value,
          })
        }
      />
    </StyledRecommendationWrapper>
  );
};
