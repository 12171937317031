import React from 'react';
import {IDictionary} from '../../data/types';

export type IAppStages =
  | 'Enquiry'
  | 'In Progress'
  | 'Partial Application'
  | 'Accepted'
  | 'Conditionally Accepted'
  | 'Confirmed'
  | 'Conditionally Confirmed';

export interface IAppDispatch
  extends React.Dispatch<{
    type: string;
    payload: any;
  }> {}

export interface ICompletedSteps {
  academicDisclosureCompleted: boolean;
  applicationFeeCompleted: boolean;
  confirmationDepositCompleted: boolean;
  consentCompleted: boolean;
  countryofDomicileCompleted: boolean;
  documentCompleted: boolean;
  submitApplicationCompleted: boolean;
  educationCompleted: boolean;
  employmentCompleted: boolean;
  englishlanguageCompleted: boolean;
  essayCompleted: boolean;
  identificationCompleted: boolean;
  parentCompleted: boolean;
  personalCompleted: boolean;
  programCompleted: boolean;
  recommendation1Completed: boolean;
  recommendation2Completed: boolean;
  referredByCompleted: boolean;
  resumeCompleted: boolean;
  termsAndConditionsCompleted: boolean;
  ugtranscriptCompleted: boolean;
  diplomaCompleted: boolean;
  hstranscriptCompleted: boolean;
  photoCompleted: boolean;
  qualificationCompleted: boolean;
  passportCompleted: boolean;
}

export interface IAppState extends IDictionary<any> {
  email?: string;
  acceptanceLetter?: {
    step?: string;
    acceptanceLetter?: string;
    acceptanceLetterUrl?: string;
    notifiedDate?: string;
  };
  appStage?: IAppStages;
  campus?: string;
  countryDailingCode?: string;
  givenName?: string;
  graduate?: string;
  phoneNumber?: string;
  program?: string;
  programStartTerm?: string;
  subStage?: string;
  surname?: string;
  studentId?: number;
  contextLoaded?: boolean;
  userLoaded?: boolean;
  application_owner?: string;
  platform?: 'StudentApplication 3.0' | 'StudentApplication 2.0';
  azureDetails?: {
    program: string;
    email: string;
    displayName: string;
  };
  gombaTrial?: {
    startDate?: string;
    endDate?: string;
  };
  completedStepsDetails?: ICompletedSteps;
  programData?: {
    campus?: string;
    program?: string;
    programStartTerm?: string;
    specialization?: string;
  };
  session?: {
    program?: string;
    campus?: string;
    programStartTerm?: string;
    termsAndConditionsDateTime?: string;
  };
  user?: IUser;
  applicationList?: {
    owner?: Array<IApplication>;
    billing?: Array<IApplication>;
  };
  selectedApplication?: string;
}

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  lastVisit: {
    guid: string;
    page: string;
  };
  phoneNumber: string;
  studentId: string;
  title: string;
}

export interface IApplication {
  access: UserAccessType;
  firstName: string;
  guid: string;
  lastName: string;
  stage: string;
  program: string;
  payerAcceptedAt: string;
  payerAcceptedTerms: string;
}

export enum UserAccessType {
  OWNER = 'OWNER',
  BILLIG = 'BILLING',
}
