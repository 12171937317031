const variables = {
  colors: {
    ivory: '#FFFAE7',
    ivoryDark: '#F1EEDF',
    white: '#FFFFFF',
    white60: 'rgba(255, 255, 255, 0.6)',
    black: '#000000',
    black5: 'rgba(0, 0, 0, 0.05)',
    black10: 'rgba(0, 0, 0, 0.10)',
    black15: 'rgba(0, 0, 0, 0.15)',
    black20: 'rgba(0, 0, 0, 0.20)',
    black40: 'rgba(0, 0, 0, 0.40)',
    darkGrey: '#2B2B2B',
    grey: '#555555',
    lightGrey: '#CCCCCC',
    primary: '#2C00FA',
    primaryLight: '#5145FA',
    masters: '#F07320',
    orange: '#F07320',
    orange5: 'rgba(240, 115, 32, 0.05)',
    orange20: 'rgba(240, 115, 32, 0.20)',
    orange80: 'rgba(240, 115, 32, 0.80)',
    red: '#F02020',
    red5: 'rgba(240, 32, 32, 0.05)',
    red80: 'rgba(240, 32, 32, 0.80)',
    green: '#00C400',
    green5: 'rgba(0, 196, 0, 0.05)',
    green20: 'rgba(0, 196, 0, 0.20)',
    green80: 'rgba(0, 196, 0, 0.80)',
    blue: '#2C00FA',
    lightBlue: '#5145FA',
    skyBlue: '#558CBB',
    yellow: "#FEB700",
  },
  breakPoints: {
    desktop: '960px',
    tablet: '768px',
    mobile: '414px',
  },
  fonts: {
    circularBook: 'EFCircularWebBook',
    circularBookItalic: 'EFCircularWebBookItalic',
    circularMedium: 'EFCircularWebMedium',
    circularMediumItalic: 'EFCircularWebMediumItalic',
    circularBold: 'EFCircularWebBold',
  },
};

const mixins = {
  mixins: {
    container: `
          width: 100%;
          margin: 0 auto;
          max-width: ${variables.breakPoints.desktop};
          @media (min-width: ${variables.breakPoints.tablet}) {
              padding-left: 32px;
              padding-right: 32px;
          }
          @media (max-width: ${variables.breakPoints.tablet}) {
              padding-left: 24px;
              padding-right: 24px;
          }
      `,
    grid3: `
        margin: 24px 0 0;
        display: grid;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        grid-template-columns: repeat(3, 1fr);
        @media (max-width: ${variables.breakPoints.tablet}) {
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 16px;
        }
        @media (max-width: ${variables.breakPoints.mobile}) {
          grid-template-columns: repeat(1, 1fr);
        }
      
        & > div {
          min-width: 0;
        }
      `,
    title: `
          font-family: ${variables.fonts.circularMedium}; 
          color: ${variables.colors.black};
          font-size: 48px;
          line-height: 52px;
          @media (max-width: ${variables.breakPoints.tablet}) {
            font-size: 32px;
            line-height: 36px;
          }
      `,
    heading: `
          font-family: ${variables.fonts.circularMedium}; 
          color: ${variables.colors.black};
          font-size: 18px;
          line-height: 22px;
      `,
    body: `
          font-family: ${variables.fonts.circularBook}; 
          color: ${variables.colors.grey};
          font-size: 16px;
          line-height: 20px;
          @media (max-width: ${variables.breakPoints.tablet}) {
            font-size: 14px;
            line-height: 18px;
          }
      `,
    bold: `
          font-family: ${variables.fonts.circularMedium}; 
          color: ${variables.colors.black};
          font-size: 16px;
          line-height: 20px;
      `,
    label: `
        font-family: ${variables.fonts.circularMedium}; 
        color: ${variables.colors.black};
        font-size: 14px;
  
        @media (max-width: ${variables.breakPoints.mobile}) {
          font-size: 12px;
        }
      `,
    span: `
          font-family: ${variables.fonts.circularBook}; 
          color: ${variables.colors.grey};
          font-size: 12px;
          line-height: 16px;
      `,
    anchor: `
          font-family: ${variables.fonts.circularBook}; 
          color: ${variables.colors.black};
          text-decoration: underline;
      `,
    icon: `
          color: ${variables.colors.darkGrey};
          font-size: 16px;
          line-height: 20px;
        `,
    keyframeAnimation: `
        @keyframes placeHolderShimmer {
          0% {
            transform: translate(-130%, 0);
          }
          100% {
            transform: translate(130%, 0);
          }
        }
      `,
    elementAnimation: `
        cursor: initial;
        position: relative;
        overflow: hidden;
        background-color: rgba(0,0,0,0.05);
        color: transparent;
  
        &:before{
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          height: 100%;
          width: 80%;
          animation-duration: .7s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: ease-in-out;
          transform: translate(0, 0);
          background: linear-gradient(to right, transparent 8%, rgba(255, 255, 255, .20) 50%, transparent 100%);
        }
      `,
  },
};

const theme = {...variables, ...mixins};

export {theme};
