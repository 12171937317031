interface IDataLayerEvent {
  event: string;
  content?: string;
}

const setDataLayer = (data: IDataLayerEvent) => {
  const {event, content} = data;
  if (
    window &&
    window.dataLayer &&
    typeof window.dataLayer.push === 'function'
  ) {
    window.dataLayer.push({
      event,
      eventMeta: {
        content: content || '(3.0)',
      },
    });
  }
};

export {setDataLayer};
