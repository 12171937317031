import styled from 'styled-components';
import {StyledIcon} from '../../icon/icon.styles';

export interface IStyledNavTab {
  active?: boolean;
}

export const StyledNavigationTab = styled.a<IStyledNavTab>`
  text-decoration: none;
  position: relative;
  cursor: pointer;
  
  & p {
    color: ${props => props.theme.colors.black40};
    
    @media (max-width: ${props => props.theme.breakPoints.tablet}) {
      font-size: 16px;
    }

    ${props =>
      props.active &&
      `color: ${props.theme.colors.black}; font-family: ${props.theme.fonts.circularMedium} `}

  }
`;

export const StyledBorderBottom = styled.div<IStyledNavTab>`
  ${props =>
    props.active &&
    `
    height: 3px;
    width: 100%;
    background: ${props.theme.colors.black};
    border-radius: 8px;
    position: absolute;
    bottom: -11px;
    `}
`;
