import styled from 'styled-components';

export const StyledPayerTermsContainer = styled.div`
background: white;
padding: 24px;
box-shadow: 0px 0.2px 1.9px rgba(0, 0, 0, 0.01),
  0px 0.4px 4.3px rgba(0, 0, 0, 0.015), 0px 0.8px 7.7px rgba(0, 0, 0, 0.018),
  0px 1.3px 12.8px rgba(0, 0, 0, 0.022), 0px 2.1px 21.2px rgba(0, 0, 0, 0.025),
  0px 3.7px 37px rgba(0, 0, 0, 0.03), 0px 8px 80px rgba(0, 0, 0, 0.04);
  border-radius: 4px;

    & h1{
        margin-top: 0;
    }
`;