import React, { useState } from 'react';
import { INavigationTabsWrapper } from './navigationTabs.types';
import {
  StyledNavigationTabsContainer,
  StyledNavigationTabs,
  StyledNavigationTabsWrapper,
  StyledBottomBorder,
} from './navigationTabs.styles';
import { NavigationTab } from '../navigationTab/navigationTab';

export const NavigationTabs: React.FC<INavigationTabsWrapper> = ({
  title,
  tabs,
  active,
  onTabChange,
}) => {
  const [activeTab, setActiveTab] = useState(active || tabs[0]?.key || '');

  const handleTabClick = (tabKey: string) => {
    setActiveTab(tabKey);
    onTabChange(tabKey);
  };

  return (
    <StyledNavigationTabsContainer>
      <StyledNavigationTabs>
        <h1>{title}</h1>
        <StyledNavigationTabsWrapper>
          {tabs.map((tab) => (
            <NavigationTab
              key={tab.key}
              title={tab.title}
              active={activeTab === tab.key}
              onClick={() => handleTabClick(tab.key)}
            />
          ))}
        </StyledNavigationTabsWrapper>
      </StyledNavigationTabs>
      <StyledBottomBorder />
    </StyledNavigationTabsContainer>
  );
};