import { useQuestionContext } from '../../context/QuestionContext';
import { StyledText } from '../QuestionForm/questionForm.styles';
import { LoadingInput } from '../QuestionInputs/TextInput/TextInput.helpers';
import { QuestionProgramBackButton } from '../BackButton';
import { PicklistInput } from '../QuestionInputs/PickListInput';
import { IQuestionsList } from '../QuestionsList/QuestionsList.types';
import { replacePlaceholders } from '../SingleQuestion/SingleQuestion.helper';
import { StyledQuestionWrapper } from '../Steps/steps.styles';
import { QuestionListSubmission } from './QuestionListSubmission';

const QuestionsListProgram = ({
  loading,
}: IQuestionsList) => {

  const { questionState, questionDispatch } = useQuestionContext()
  const { savedSession, sessionValues, visibleQuestions, questionSlug, submitObj } = questionState

  if (loading) {
    return <LoadingInput />
  }

  if (!questionState?.visibleQuestions.length) {
    return <></>
  }

  const sessionVars = { ...savedSession, ...sessionValues }

  const displayedQuestions = visibleQuestions?.map((q) => {
    const currentQ = q?.slug === questionSlug;

    return currentQ ? (
      <StyledQuestionWrapper key={q.slug}>
        {visibleQuestions?.map((q) => {

          const qContent = q?.content
          const currentQ = q?.slug === questionSlug;
          const qMapping = qContent?.question_mapping || ''

          return currentQ ? (
            <>
              <StyledText>
                <h2>{qContent?.display_title}</h2>
                <p>{replacePlaceholders(qContent?.description || '', sessionVars)}</p>
              </StyledText>
              <div className="input-wrapper">
                <PicklistInput
                  select_ds_options={qContent?.select_ds_options}
                  select_blok_options={qContent?.select_blok_options}
                  dependentOn={qContent?.dependent_on}
                  handleChange={(e) => {
                    const updated = submitObj.savedValue !== e.value

                    questionDispatch({
                      type: 'update-submit-object',
                      payload: {
                        submitObj: {
                          ...questionState.submitObj,
                          ...e,
                          updated,
                          addToSession: true
                        }
                      },
                    })
                  }}
                />
              </div>
              <QuestionListSubmission />
            </>
          ) : <></>
        })}
      </StyledQuestionWrapper>
    ) : <></>
  })

  return (
    <StyledQuestionWrapper>
      <QuestionProgramBackButton loading={loading} />
      {displayedQuestions}
    </StyledQuestionWrapper>
  )
};

export default QuestionsListProgram