import {useState, useEffect} from 'react';
import {useAppContext} from '../../../context/AppContext';
import {IDictionary, sbLinkType} from '../../../data/types';
import {StyledFormstack} from './Formstack.styles';
import {AlertSa} from 'hult-toolkit';

interface IFormstack {
  display_title?: string;
  question_mapping?: string;
  formstack_form_link?: sbLinkType;
  handleChange?: (e: any) => any;
}

const formMapping: IDictionary<string> = {
  selfreflection: 'SELF_REFLECTION',
  decFinance: 'DecFinance',
  academicDisclosure: 'ACADEMIC_DISCLOSURE',
};

export const FormstackUi: React.FC<IFormstack> = ({
  display_title,
  question_mapping,
  formstack_form_link,
  handleChange,
}) => {
  const {studentId} = useAppContext();
  const [formComplete, setFormComplete] = useState(false);

  const iframeUrl = `${formstack_form_link?.url}?studentId=${studentId}&key=${
    formMapping[question_mapping || '']
  }`;

  window.addEventListener('message', event => {
    const data = event.data;
    if (data === 'fs-form-submit') {
      setFormComplete(true);
    }
  });

  useEffect(() => {
    if (formComplete) {
      handleChange && handleChange({value: true, valid: true});
    }
  }, [formComplete]);

  return (
      <StyledFormstack>
        {studentId ? (
          <iframe
            src={iframeUrl}
            title={display_title || 'Form'}
            height="900"
          ></iframe>
        ) : (
          <AlertSa
            desc={'Please refresh the page and try again.'}
            icon={'times'}
            icon_color={'red'}
            title={'Oops! Something went wrong'}
          />
        )}
      </StyledFormstack>
  );
};
