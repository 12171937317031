import styled from 'styled-components';

interface IStyledLoading {
  loading?: boolean;
}

interface IQuestionType {
  question_type?: string;
}

export const StyledQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDisableTextSelect = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: progress;
`;

export const StyledQuestionContainer = styled.div<IQuestionType>`
  /* max-width: ${props => props.question_type === 'formstack' ? '100%' : '50%'}; */
  max-width: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    max-width: 100%;
  }
`;

export const StyledText = styled.div<IStyledLoading>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${props =>
    props.loading &&
    `
    ${props.theme.mixins.keyframeAnimation}
  `}

  & h2 {
    ${props => props.theme.mixins.title};
    margin: 0;
    ${props =>
      props.loading &&
      `
      ${props.theme.mixins.elementAnimation}
    `}
  }

  & p {
    color: ${props => props.theme.colors.grey};
    margin: 16px 0 0 !important;
    ${props =>
      props.loading &&
      `
      ${props.theme.mixins.elementAnimation}
    `}
  }
`;

export const StyledButtonContainer = styled.div`
`;

export const StyledDatePickerNative = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    display: block;

    & > div {
      margin: 0;
    }
  }
`;

export const StyledSchoolSentence = styled.div`
  color: ${props => props.theme.colors.grey};
  display: flex;
`;

export const StyledSchoolClickHere = styled.div`
  cursor: pointer;
  text-decoration: underline;
  padding-left: 5px;
`;
