import { FC, useEffect, useState } from 'react';
import { isSameValue } from '../QuestionInputHelpers';
import { IEmailInput } from './EmailInput.types';
import { useAppContext } from '../../../context/AppContext';
import { useQuestionContext } from '../../../context/QuestionContext';
import { useStepContext } from '../../../context/StepContext';
import { InputField } from 'hult-toolkit';

export const EmailInput: FC<IEmailInput> = ({
  handleChange,
  label = ' ',
  defaultValue,
  required = true
}) => {
  const { questionState } = useQuestionContext()
  const { stepState } = useStepContext()
  const { email } = useAppContext();

  const { questionMapping } = questionState

  const defaultEmail = defaultValue || stepState[questionMapping] || '';

  const [isUserEmail, setIsUserEmail] = useState(false)
  const [emailObj, setEmailObj] = useState({
    valid: false,
    value: defaultEmail
  })

  useEffect(() => {
    handleChange(emailObj);
  }, [emailObj]);

  const emailChange = (e: any) => {

    const sameAsUsersEmail = isSameValue(e.value, email)

    setEmailObj({
      value: e.value,
      valid: e.valid && !sameAsUsersEmail
    })

    setIsUserEmail(sameAsUsersEmail)
  }

  return (
    <InputField
      type="email"
      label={label}
      name={questionMapping || ''}
      onChange={emailChange}
      default_value={defaultEmail}
      invalid={isUserEmail}
      required={required}
      error_message={isUserEmail ? "You can't use your own email address" : ""}
    />
  );
};
