import styled from 'styled-components';

export interface IStyledInvoice {
  status?: string;
  bodyVisible?: boolean;
  container?: boolean;
}

export interface IStyledIcon {
  bodyVisible?: boolean;
  hideInvoice?: boolean;
}

export const StyledBillingPage = styled.div``;

export const StyledHero = styled.div`
  margin: 32px auto;

  & h1 {
    ${props => props.theme.mixins.h1}
  }
  & p {
    ${props => props.theme.mixins.body}
  }
`;

export const StyledSection = styled.div`
  margin: 64px auto;
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    margin: 48px auto;
  }

  & header {
    border-bottom: 2px solid ${props => props.theme.colors.darkGrey};
    padding-bottom: 8px;
    margin-bottom: 24px;

    & p {
      ${props => props.theme.mixins.heading}
    }
  }
`;

export const StyledSubHeadline = styled.h2``;

export const StyledInvoice = styled.div<IStyledInvoice>`
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px #0000000a;
  border-left: 8px solid;

  border-color: ${props =>
    props.status === 'Past Due' && props.theme.colors.yellow};
  border-color: ${props =>
    (props.status === 'Upcoming' || props.status === 'Due Today') &&
    props.theme.colors.skyBlue};
  border-color: ${props =>
    props.status === 'Paid' && props.theme.colors.black15};

  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
    border-left: 0;
    border-top: 8px solid;

    border-color: ${props =>
      props.status === 'Past Due' && props.theme.colors.yellow};
    border-color: ${props =>
      (props.status === 'Upcoming' || props.status === 'Due Today') &&
      props.theme.colors.skyBlue};
    border-color: ${props =>
      props.status === 'Paid' && props.theme.colors.black15};
  }

  @media (min-width: ${props => props.theme.breakPoints.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledInvoiceDataList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #f9f8f2;
  padding: 16px 24px;
  min-width: 240px;
  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
    padding: 16px;
  }
`;
export const StyledInvoiceData = styled.div`
  display: flex;
  align-items: flex-end;

  & p {
    margin: 0;
    font-size: 16px;

    &:nth-of-type(1) {
      padding-right: 4px;
      ${props => props.theme.mixins.bold}
    }
    &:nth-of-type(2) {
      color: ${props => props.theme.colors.black40};
    }
    margin: 0;
  }
`;

export const StyledInvoiceDueDate = styled.div`
  display: flex;

  & p {
    color: ${props => props.theme.colors.black40};
    &:nth-of-type(1) {
      padding-right: 4px;
      font-size: 14px;
      font-family: ${props => props.theme.fonts.circularMedium};
    }

    &:nth-of-type(2) {
      font-size: 14px;
    }
    margin: 0;
  }
`;

export const StyledInvoiceStatus = styled.p<IStyledInvoice>`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.circularMedium};
  padding: 2px 4px;
  border-radius: 2px;
  color: white;
  color: ${props =>
    props.status === 'Past Due' && `${props.theme.colors.black};`};
  width: fit-content;

  background-color: ${props =>
    props.status === 'Paid' && `${props.theme.colors.black15};`};
  background-color: ${props =>
    props.status === 'Past Due' && `${props.theme.colors.yellow};`};
  background-color: ${props =>
    (props.status === 'Upcoming' || props.status === 'Due Today') &&
    props.theme.colors.skyBlue};
`;

export const StyledInvoiceActions = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 24px;

  @media (min-width: ${props => props.theme.breakPoints.mobile}) {
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    width: 100%;
    padding: 16px;
    justify-content: space-between;
  }
`;

export const StyledInvoicePaymentDataList = styled.div<IStyledInvoice>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px;
  align-items: center;
  border-right: 1px solid #0000001a;
  border-left: 1px solid #0000001a;

  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
    flex-direction: column;
    align-items: flex-start;
    border-right: 0;
    border-left: 0;
    border-top: 1px solid #0000001a;
    border-bottom: 1px solid #0000001a;
    gap: 16px;
    ${props => (props.container ? `padding: 16px 24px;` : `padding: 16px;`)}
    ${props => props.container && `margin: 16px 0;`}
  }
`;

export const StyledInvoicePaymentData = styled.div`
  & p {
    margin: 0;
    &:nth-of-type(1) {
      margin-bottom: 8px;
      ${props => props.theme.mixins.bold};
    }

    &:nth-of-type(2) {
      color: ${props => props.theme.colors.black};
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    img {
      border-radius: 50%;
      height: 16px;
      width: 16px;
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;

    p {
      font-size: 16px;
      &:nth-of-type(1) {
        margin-bottom: 0px;
      }
    }
  }
`;

export const StyledBold = styled.p<IStyledInvoice>`
  ${props => props.theme.mixins.bold};
  ${props =>
    props.status === 'Paid' && `font-family: ${props.theme.fonts.circularBook}`}
`;

export const StyledInvoiceContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.black5};
  border-radius: 8px;
  background: ${props => props.theme.colors.ivoryDark};
`;

export const StyledInvoiceHeader = styled.div<IStyledInvoice>`
  background: #f9f8f2;
  padding: 16px 0;
  border-radius: ${props => (props.bodyVisible ? `8px 8px 0 0` : `8px`)};
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
    flex-direction: column;
  }
`;

export const StyledInvoiceBody = styled.div`
  margin: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${props => props.theme.breakPoints.desktop}) {
    grid-template-columns: repeat(2, 49%);
    margin: 16px;
    display: grid;
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const StyledInvoiceFooter = styled.div`
  margin: 16px 24px;
`;

export const StyledContractInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-width: 248px;
  min-width: 225px;
  margin: 0 24px;
`;

export const StyledContractInfo = styled.div`
  & p {
    margin: 0 0 8px 0;

    &:nth-of-type(1) {
      ${props => props.theme.mixins.bold};
      font-size: 16px;
    }

    &:nth-of-type(2) {
      ${props => props.theme.mixins.bold};
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
`;

export const StyledPaymentPlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  & p {
    margin: 0;
    color: ${props => props.theme.colors.black40};
    font-size: 14px;
  }
`;

export const StyledButtonContainer = styled.div`
  margin: auto;
  min-width: 233px;
`;

export const StyledInvoicedHeaderTitle = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 24px;

  & p {
    font-family: ${props => props.theme.fonts.circularMedium};
    color: ${props => props.theme.colors.black};
    font-size: 18px;
    line-height: 22px;
  }

  & span {
    & svg {
      height: 18px;
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    align-items: center;
  }
`;

export const StyledHideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  & p {
    color: ${props => props.theme.colors.black};
    font-size: 18px;
    display: flex;
    align-items: center;

    span {
      padding: 0 4px;
      font-family: ${props => props.theme.fonts.circularMedium};
    }

    svg {
      padding-left: 8px;
    }
  }
`;

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  cursor: pointer;
  margin: auto;

  p {
    color: ${props => props.theme.colors.black};
    text-decoration: underline;
    font-size: 16px;
    min-width: 104px;
  }
`;

export const StyledIcon = styled.div<IStyledIcon>`
  ${props =>
    props.hideInvoice &&
    `
    background: white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    padding: 8px;
    width: 32px;
    height: 32px;
    `}

  display: flex;
  align-items: center;
  ${props =>
    props.bodyVisible
      ? `
      display: flex;
    `
      : `
      display: none;
    `}
`;

export const StyledInvoiceContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
