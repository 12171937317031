import {IAppState} from '../context/AppContext/appContext.types';
import {IDictionary} from '../data/types';

export function replaceVars(state: IAppState, text: string) {

  const formattedDateTime = state.session?.termsAndConditionsDateTime
    ? new Date(state.session.termsAndConditionsDateTime).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : '';

  const mapping: IDictionary<string> = {
    '{{firstname}}': state.givenName || '',
    '{{lastname}}': state.surname || '',
    '{{program}}': state.session?.program || '',
    '{{campus}}': state.session?.campus || '',
    '{{startterm}}': state.session?.programStartTerm || '',
    '{{educationdegree}}': state.educationDegree || '',
    '{{school}}': JSON.parse(state.school || '{}').schoolName || '',
    '{{employmentcompanyname}}': state.employmentCompanyName || '',
    '{{parentfirstname}}': state.parentFirstName || '',
    '{{termsandconditionsdatetime}}': formattedDateTime,
    '{{payerfirstname}}': state.user?.firstName || '',
    '{{payerlastname}}': state.user?.lastName || '',
  };

  var re = new RegExp(Object.keys(mapping).join('|'), 'gi');
  const s = text.replace(re, function (matched) {
    return mapping[matched.toLowerCase()];
  });

  return s;
}
