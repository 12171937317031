import { ISubmitObj } from "../SingleQuestion/SingleQuestion.types";

export function replacePlaceholders(sentence: string, obj: any) {
  const placeholderRegex = /\{\{(\w+)\}\}/g;
  return sentence.replace(placeholderRegex, (match, key) => obj.hasOwnProperty(key) ? obj[key] : match);
}

export function renderUpdateObj({ qMapping, value }: ISubmitObj, inputObj: { [x: string]: string }) {

  let newObj = { ...inputObj }

  if (qMapping === 'nativeEnglish') {
    newObj['primaryLanguage'] = value === 'true' ? 'English' : ''
  }

  if (qMapping === 'address') {
    return {
      address1: value?.address1,
      address2: value?.address2,
      city: value?.city,
      country: value?.countryName,
      postalCode: value?.postalCode,
      addressState: value?.addressState,
      addressLine: `${value?.address1} ${value?.address2 ?? ''}`
    }
  }

  if (qMapping === 'billingAddress') {
    const resObj = {
      billingLine1: value?.address1,
      billingLine2: value?.address2,
      billingCity: value?.city,
      billingState: value?.addressState,
      billingCountry: value?.country,
      billingPostalCode: value?.postalCode,
      billingAddressLine: `${value?.address1} ${value?.address2 ?? ''}`,
      billingAddressSupplied: true,
    }

    return resObj
  }

  if (qMapping === 'phone') {
    return {
      phone: value
    }
  }

  if (qMapping.includes('recommendation')) {

    newObj = {
      recommendation: value
    }
  }

  return newObj
}