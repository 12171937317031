import {useEffect} from 'react';
import {InteractionStatus} from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import {loginRequest} from '../services/msal/config';

import {PageLoader} from '../components/ui/pageLoader';
import {useAppContext} from '../context/AppContext';
import {stageMapping, useQuery} from '../utils';
import {useNavigate} from 'react-router-dom';
import {StoryblokComponent, useStoryblok} from '@storyblok/react';

interface IAuthenticatedQuestion {
  slug: string;
}

const AuthenticatedQuestion: React.FC<IAuthenticatedQuestion> = ({slug}) => {
  const {graduate, appStage} = useAppContext();
  const stepSlug = `${graduate}/${stageMapping(
    appStage
  )}/${slug}`.toLowerCase();

  const story = useStoryblok(`sa3-1/config/steps/${stepSlug}`, {
    version:
      (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') ||
      'published',
    resolve_relations: 'step.questions',
  });

  const {content} = story;

  if (!content) {
    return <PageLoader />;
  }

  return <StoryblokComponent blok={content} />;
};

export const Question: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const {instance, inProgress} = useMsal();
  const {appStage, graduate} = useAppContext();
  const navigate = useNavigate();

  const slug = useQuery().get('step');

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
  });

  useEffect(() => {
    if (!slug) navigate('/');
  }, [slug]);

  return (
    <>
        <AuthenticatedTemplate>
          {(!!(appStage && graduate && slug) && (
            <AuthenticatedQuestion slug={slug} />
          )) || <PageLoader />}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <PageLoader />
        </UnauthenticatedTemplate>
    </>
  );
};
