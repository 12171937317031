import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { Breadcrumbs } from '../BreadCrumb';
import { IStep } from '../../data/types';
import { IStepsProps } from './steps.types';
import { StepStandard } from './stepStandard';
import { StepMultiEntry } from './stepMultiEntry';
import { StepPayment } from './stepPayment';
import { StepDocument } from './StepDocument';
import { FormStack } from '../QuestionInputs/FormStack';
import { useUpdateLastVisitMutation } from '../../graphql';
import { TermsAndConditions } from '../QuestionInputs/TermsAndConditions';
import { StepContextProvider } from '../../context/StepContext';
import { QuestionContextProvider } from '../../context/QuestionContext';
import { StepProgram } from './stepProgram';
import { Layout, SectionSa } from 'hult-toolkit';
import PayerManagement from '../QuestionInputs/PayerManagement';

const StepContainer: React.FC<{
  content: IStep;
  isPreview?: boolean;
}> = ({ content, isPreview }) => {

  const { step_type } = content;
  const { selectedApplication } = useAppContext()

  const location = useLocation();
  const [updateLastVisitMutation] = useUpdateLastVisitMutation();

  useEffect(() => {
    if (location?.pathname) {
      updateLastVisitMutation({
        variables: {
          guid: selectedApplication || '',
          page: `${location?.pathname}${location?.search || ''}`,
        },
      });
    }
  }, [location])

  const stepArgs = {
    ...content,
    isPreview
  }

  switch (step_type) {
    case 'document':
      return <StepDocument {...stepArgs} />;
    case 'multi-record':
      return <StepMultiEntry {...stepArgs} />;
    case 'payment':
      return <StepPayment {...stepArgs} />;
    case 'terms-and-conditions':
      return <TermsAndConditions {...stepArgs} />;
    case 'formstack':
      return <FormStack {...stepArgs} />;
    case 'program':
      return (
        <StepContextProvider>
          <QuestionContextProvider>
            <StepProgram {...stepArgs} />
          </QuestionContextProvider>
        </StepContextProvider>
      )
    case 'standard':
      return (
        <StepContextProvider>
          <QuestionContextProvider>
            <StepStandard {...stepArgs} />
          </QuestionContextProvider>
        </StepContextProvider>
      );
    case 'payer-management':
      return <PayerManagement />
    default:
      return <>Missing</>;
  }
};

export const Step: React.FC<IStepsProps> = ({ blok, isPreview }) => {
  const { display_name } = blok;

  return (
    <Layout>
      <Breadcrumbs title={display_name || ''} />
      <SectionSa>
        <StepContainer content={blok} isPreview={isPreview} />
      </SectionSa>
    </Layout>
  );
};
