import React from 'react';
import {useSettingsContext} from '../../context/SettingsContext';
import {
  StyledBackgroundImgLeft,
  StyledBackgroundImgRight,
} from './background.styles';
import {sbAssetType} from '../../data/types';
import { useAppContext } from '../../context/AppContext';

export const Background: React.FC = () => {
  const settings = useSettingsContext();
  const {graduate} = useAppContext();

  const backgroundArray = Object.values(settings.background || {}) as {
    program?: string;
    shape_left?: sbAssetType;
    shape_right?: sbAssetType;
  }[];

  return (
    <>
      {backgroundArray.map((backgroundItem, index) => {
        if (backgroundItem.program === graduate) {
          return (
            <div key={index}>
              <StyledBackgroundImgLeft>
                <img src={backgroundItem.shape_left?.filename} />
              </StyledBackgroundImgLeft>
              <StyledBackgroundImgRight>
                <img src={backgroundItem.shape_right?.filename} />
              </StyledBackgroundImgRight>
            </div>
          );
        } else {
          return (
            <div key={index}>
              <StyledBackgroundImgLeft>
                <img src={backgroundArray[0].shape_left?.filename} />
              </StyledBackgroundImgLeft>
              <StyledBackgroundImgRight>
                <img src={backgroundArray[0].shape_right?.filename} />
              </StyledBackgroundImgRight>
            </div>
          );
        }
      })}
    </>
  );
};