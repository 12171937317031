import Cookies from "js-cookie";

const cookiesList = [
  { cookieKey: '_campaign', attributeName: 'campaign' },
  { cookieKey: '_content', attributeName: 'content' },
  { cookieKey: '_medium', attributeName: 'medium' },
  { cookieKey: '_referrer', attributeName: 'referrer' },
  { cookieKey: '_source', attributeName: 'source' },
  { cookieKey: '_term', attributeName: 'term' },
]

export const cookieTrackingValues = () => {
  const cookieObj: any = {}

  cookiesList.forEach(cookie => {
    if (Cookies.get(cookie.cookieKey)) {
      cookieObj[cookie.attributeName] = Cookies.get(cookie.cookieKey)
    }
  })

  return cookieObj
}