import React, {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMsal, useIsAuthenticated} from '@azure/msal-react';
import {render} from 'storyblok-rich-text-react-renderer';
import {StyledMain, StyledBanner, StyledHeader, StyledFooter} from './pageLayout.styles';
import headerNavigation from '../../data/headerNavigation';
import {findBillingApplication} from '../../utils';
import {useSettingsContext} from '../../context/SettingsContext';
import {ISettings} from '../../context/SettingsContext/settings.types';
import {DropdownBannerSa, FooterSa, HeaderSa, Layout} from 'hult-toolkit';
import {useAppContext, useAppDispatch} from '../../context/AppContext';

interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = props => {
  const {instance} = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const settings = useSettingsContext();
  const navigate = useNavigate();
  const {
    appStage,
    completedStepsDetails,
    selectedApplication,
    applicationList,
  } = useAppContext();
  const {navigation, footer} = settings as ISettings;
  const dispatch = useAppDispatch();
  const applications = applicationList?.billing?.concat(
    applicationList?.owner || []
  );

  interface SelectedValue {
    name: string;
    value: string;
    valid: boolean;
  }

  const handleOnChange = (selectedValue: SelectedValue | {}) => {
    if ('value' in selectedValue && typeof selectedValue.value === 'string') {
      dispatch({
        type: 'select-application',
        payload: selectedValue.value,
      });
    } else {
      console.error('Invalid selected value:', selectedValue);
    }
  };

  const validStages = [
    'Partial Application',
    'Accepted',
    'Conditionally Accepted',
    'Conditionally Confirmed',
    'Confirmed',
    'Rejected',
  ];

  const billingApplication = selectedApplication
    ? findBillingApplication(selectedApplication, applicationList)
    : undefined;

  return (
    <>
      {settings && (
        <>
          <StyledHeader>
            <HeaderSa
              logo={{url: navigation?.logo || ''}}
              logo_url="/"
              labels={
                isAuthenticated
                  ? [
                      ...headerNavigation
                        .filter(
                          ({label}) =>
                            label !== 'Bring a Friend' ||
                            (!billingApplication &&
                              appStage &&
                              completedStepsDetails?.submitApplicationCompleted &&
                              validStages.includes(appStage))
                        )
                        .map(({label, link}) => ({
                          label,
                          href: link.startsWith('http') ? link : undefined,
                          target: link.startsWith('http')
                            ? '_blank'
                            : undefined,
                          onClick: !link.startsWith('http')
                            ? () => navigate(link)
                            : undefined,
                        })),
                      {
                        label: 'Log out',
                        onClick: () => {
                          instance.logout();
                        },
                      },
                    ]
                  : []
              }
            />
          </StyledHeader>
          {selectedApplication && applications && applications.length > 1 && (
            <StyledBanner>
              <DropdownBannerSa
                title=" "
                text="Access to"
                icon="user"
                placeholder="Select an application"
                dropdown_options={
                  applications?.map(application => ({
                    name: application.firstName + ' ' + application.lastName,
                    value: application.guid,
                  })) || []
                }
                onChange={value => handleOnChange(value)}
                default_value={selectedApplication}
              />
            </StyledBanner>
          )}
          <StyledMain>{props.children}</StyledMain>
          <StyledFooter data-testid="footer">
            <FooterSa text={footer?.text ? render(footer?.text) : ''} />
          </StyledFooter>
        </>
      )}
    </>
  );
};
