import {
  useGetApplicationContextQuery,
  useCompleteApplicationStepMutation,
} from '../../graphql';

export function useGetAppData(
  guid: string | undefined,
  dispatch: (value: any) => void
) {
  const [completeStepMutation] = useCompleteApplicationStepMutation();
  return useGetApplicationContextQuery({
    variables: {
      guid: guid || '',
    },
    skip: !guid,
    onCompleted(data) {
      // Update Logged In step everytime we get the context
      completeStepMutation({
        variables: {
          guid: guid || '',
          step: 'loggedIn',
        },
      });

      const {
        studentApplication,
        session,
        azureDetails,
        program,
        completedSteps,
        acceptanceLetter,
        applicationPayers,
      } = data;

      if (applicationPayers) {
        dispatch({
          type: 'update-application-payers',
          payload: applicationPayers,
        });
      }

      if (studentApplication) {
        dispatch({
          type: 'update-state',
          payload: studentApplication,
        });
      }

      if (session) {
        dispatch({
          type: 'update-session',
          payload: session,
        });
      }

      if (azureDetails) {
        dispatch({
          type: 'update-azure-details',
          payload: azureDetails,
        });
      }

      if (program) {
        dispatch({
          type: 'update-program-details',
          payload: program,
        });
      }

      if (completedSteps) {
        dispatch({
          type: 'update-completed-steps',
          payload: completedSteps,
        });
      }

      if (acceptanceLetter) {
        dispatch({
          type: 'update-acceptance-letter',
          payload: acceptanceLetter,
        });
      }

      dispatch({
        type: 'update-state',
        payload: {contextLoaded: true},
      });
    },
    onError(error) {
      console.log('useGetApplicationContextQuery error', error);
      throw error;
    },
  });
}
