import {IAppStages, ICompletedSteps} from '../../context/AppContext/appContext.types';
import {IStep} from '../../data/types';

export function checkCompletion(stage?: string, subStage?: string) {
  if (stage?.startsWith('Partial') && subStage === 'Completed Application')
    return 'locked';
  return 'complete';
}

export function activePastPartialStage(stage?: IAppStages) {
  const appStage = stage?.toLowerCase() || '';
  const acceptableStages = [
    'accepted',
    'confirmed',
    'conditionally accepted',
    'conditionally confirmed',
  ];

  return acceptableStages.includes(appStage);
}

export function parseSteps(
  steps: IStep[],
  completedStepsDetails?: ICompletedSteps
) {
  const nonCompletedSteps = [] as IStep[];
  const completedSteps = steps.filter(item => {
    if (
      completedStepsDetails &&
      completedStepsDetails[`${item.content.step_name}Completed` as keyof ICompletedSteps]
    )
      return item;
    nonCompletedSteps.push(item);
    return null;
  });

  return {
    completedSteps,
    nonCompletedSteps,
  };
}

export function appLock(
  item: IStep,
  completedStepsDetails?: ICompletedSteps,
  onClick?: (item?: IStep) => void
) {
  const {required_steps, required_message} = item?.content;

  const lockedLogic =
    required_steps &&
    !required_steps.every((str: string) => completedStepsDetails?.[`${str}` as keyof ICompletedSteps]);

  function stepCardClick() {
    if (lockedLogic) {
      alert(required_message || '');
    } else {
      onClick && onClick(item);
    }
  }

  return {
    stepCardClick,
    stepCardState: lockedLogic ? 'locked' : 'default',
  };
}
