import React from 'react';
import {ISbStoryData, StoryblokComponent} from '@storyblok/react';
import {Grid, SectionSa} from 'hult-toolkit';
import {StepListContainer} from '../StepListContainer';
import {StepList} from '../StepListContainer/stepList';
import {IStep} from '../../data/types';
import { replaceVars } from '../../utils/replaceVars';
import { useAppContext } from '../../context/AppContext';
import ConditionalOption from '../ConditionalOption';
import { IConditionalOptionProp } from '../ConditionalOption/conditionalOption.types';

export interface ISection {
  blok: ISbStoryData & {
    title?: string;
    desc?: string;
    grid_gutters?: "0px" | "8px" | "16px" | "32px" | undefined;
    grid: number;
    body?: any[];
    _uid: string;
    steps: IStep[];
    locked_steps: IStep[];
    conditional_option: IConditionalOptionProp[];
  };
  isPreview?: boolean;
  steps?: IStep[];
}

const Section: React.FC<ISection> = ({blok, isPreview, steps}) => {
  const appState = useAppContext();
  return (
    blok.conditional_option ? (
      <ConditionalOption condition_option_props={blok.conditional_option[0]}>
          <SectionSa title={blok.title && replaceVars(appState, blok.title)} copy={blok.desc}>
            <Grid column={Number(blok.grid)} grid_gutters={blok.grid_gutters}>
              {blok.body &&
                blok.body.map((c: any) => (
                  <StoryblokComponent key={c._uid} blok={c} steps={steps} />
                ))}         
            </Grid>
            {blok.steps.length > 0 && (
              <>
                {!isPreview && <StepListContainer {...blok} key={blok._uid} />}
                {isPreview && (
                  <StepList
                    completedSteps={[]}
                    nonCompletedSteps={blok.steps}
                    locked_steps={blok.locked_steps}
                    grid={blok.grid}
                  />
                )}
              </>
            )}
          </SectionSa>
      </ConditionalOption>
    ) : (
      <div>
        <SectionSa title={blok.title && replaceVars(appState, blok.title)} copy={blok.desc}>
          <Grid column={Number(blok.grid)} grid_gutters={blok.grid_gutters}>
            {blok.body &&
              blok.body.map((c: any) => (
                <StoryblokComponent key={c._uid} blok={c}  steps={steps} />
              ))}
          </Grid>
          {blok.steps.length > 0 && (
            <>
              {!isPreview && <StepListContainer {...blok} key={blok._uid} />}
              {isPreview && (
                <StepList
                  completedSteps={[]}
                  nonCompletedSteps={blok.steps}
                  locked_steps={blok.locked_steps}
                  grid={blok.grid}
                />
              )}
            </>
          )}
        </SectionSa>
      </div>
    )
  );
};

export default Section;
