import { useAppContext } from '../../context/AppContext';
import { StepsCard } from '../StepsCard';
import { appLock } from './helpers';
import { IStepList } from './stepListContainer.types';
import ConditionalOption from '../ConditionalOption';
import { Grid } from 'hult-toolkit';

export const StepList: React.FC<IStepList> = ({
  completedSteps = [],
  nonCompletedSteps = [],
  locked_steps = [],
  completionState,
  grid,
  onClick,
}) => {
  const { completedStepsDetails, selectedApplication, subStage } = useAppContext();

  const displayedNoneCompleted = nonCompletedSteps.map(item => {
    const { stepCardClick, stepCardState } = appLock(
      item,
      completedStepsDetails,
      onClick
    );
    const itemConditionalOption = item?.content?.conditional_option?.[0]
    const lockedState = (subStage === 'Completed Application' && item.content.step_name !== 'decfinance');
    const determinedState = lockedState ? 'locked' : stepCardState;
  
    return (
      <ConditionalOption
        condition_option_props={itemConditionalOption}
        initState={determinedState}
        key={item.content._uid}
      >
        <StepsCard
          key={item.uuid as string}
          name={item.content.display_name}
          state={determinedState as 'locked' | 'default'}
          onClick={lockedState ? undefined : stepCardClick}
        />
      </ConditionalOption>
    );
  })

  const displayCompletedSteps = completedSteps.map(item => {
    const itemConditionalOption = item?.content?.conditional_option?.[0]

    const lockedState = (subStage === 'Completed Application' && item.content.step_name !== 'decfinance');
    const determinedState = lockedState ? 'locked' : (item.content.step_type === 'document' || item.content.step_type === 'formstack' ? 'submit' : 'complete');

    return (
      <ConditionalOption
        condition_option_props={itemConditionalOption}
        initState={'complete'}
        key={item.content._uid}
      >
        <StepsCard
          state={determinedState}
          name={item.content.display_name}
          key={item.uuid as string}
          onClick={() => {
            if (!lockedState) onClick && onClick(item);
          }}
        />
      </ConditionalOption>
    )
  })

  const hasSteps = nonCompletedSteps.length > 0 || completedSteps.length > 0 || (locked_steps && locked_steps.length > 0)

  return (
    <>
      {hasSteps && (
        <Grid column={Number(grid)}>
          {displayedNoneCompleted}
          {displayCompletedSteps}
          {locked_steps &&
            locked_steps.map(item => (
              <StepsCard
                state="locked"
                name={item.content.display_name}
                key={item.uuid as string}
              />
            ))}
        </Grid>
      )}
    </>
  );
};
