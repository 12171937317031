import React, { useEffect, useRef } from 'react';

import {setDataLayer} from '../../services/ga';
import {StyledStepsCard} from './stepsCard.styles';
import { CardStepSa } from 'hult-toolkit';

export interface IStepsCard {
  name?: string;
  state?: "complete" | "default" | "resource" | "loading" | "locked" | "submit" | "hidden" | undefined;
  onClick?: Function;
}

export const StepsCard: React.FC<IStepsCard> = ({name, onClick, state = 'default'}) => {

  const cardRef = useRef<HTMLDivElement | null>(null);
  function handleClick() {
    onClick && onClick();
    setDataLayer({
      event: 'stdapp_sectionStarted',
      content: '(3.0) - ' + name,
    });
  }

  useEffect(() => {
    if (cardRef.current && cardRef.current.parentElement && state === 'hidden') {
      cardRef.current.parentElement.parentElement?.remove()
    }
  }, [state]);


  return (
    <StyledStepsCard ref={cardRef}>
      <CardStepSa 
        data_name={name}
        title={name}
        onClick={() => handleClick()}
        state={state}
        />
    </StyledStepsCard>
  );
};
