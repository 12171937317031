import { StoryblokComponent } from '@storyblok/react';
import { StepListContainer } from '../StepListContainer';
import { StyledDynamicWrapper } from './page.styles';
import { StepList } from '../StepListContainer/stepList';
import { IPageProps } from './page.types';
import { Background } from '../Background';
import { PayerTermsCmp } from '../PayerTerms';
import { useAppContext } from '../../context/AppContext';
import { findBillingApplication } from '../../utils';
import { Layout, SectionSa } from 'hult-toolkit';

export const Page: React.FC<IPageProps> = ({ blok, isPreview }) => {
  const { selectedApplication, applicationList } = useAppContext();

  const activeApplication = selectedApplication
    ? findBillingApplication(selectedApplication, applicationList)
    : undefined;

  if (
    activeApplication &&
    activeApplication.access === 'BILLING' &&
    (activeApplication.payerAcceptedTerms === 'false' ||
      !activeApplication.payerAcceptedTerms)
  ) {
    return (
      <>
        <Background />
        <StyledDynamicWrapper>
          <Layout>
            <SectionSa>
              <PayerTermsCmp />
            </SectionSa>
          </Layout>
        </StyledDynamicWrapper>
      </>
    );
  }

  return (
    <>
      <Background />
      <StyledDynamicWrapper>
        {blok.content.map((c: any, index: string) => (
          <Layout key={index}>
            <StoryblokComponent key={c._uid} blok={c} steps={blok.steps} />
          </Layout>
        ))}
      </StyledDynamicWrapper>
      <Layout>
        <SectionSa>
          {!isPreview && (
            <StepListContainer {...blok} key={blok._uid} grid={3} />
          )}
          {isPreview && (
            <StepList
              completedSteps={[]}
              nonCompletedSteps={blok.steps}
              locked_steps={blok.locked_steps}
              grid={3}
            />
          )}
        </SectionSa>
      </Layout>
    </>
  );
};
