import styled from 'styled-components';

export const StyledAddPayerContainer = styled.div`
  border-radius: 8px 8px 0 0;
  padding: 32px 0 64px 0;
  position: relative;
  margin-top: 32px;


  &:before{
    content: "";
    position: absolute;
    top:0;
    left: 0;
    width: 9999999px;
    background: ${props => props.theme.colors.ivoryDark};
    height: 100%;
    transform: translateX(-50%);
  }
`;

export const StyledAddPayer = styled.div`
  position: relative;
`;

export const StyledTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & h1 {
    ${props => props.theme.mixins.heading};
  }
`;

export const StyledWidth = styled.div`
  width: 60%;

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    width: 100%;
  }
`;

export const StyledPayerInfo = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.black10};
  border-top: 1px solid ${props => props.theme.colors.black10};
  padding: 16px 0 32px 0;
  margin: 16px 0 32px 0;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledNameInput = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    flex-direction: column;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 32px 0;

  & h1 {
    ${props => props.theme.mixins.heading};
  }
`;
