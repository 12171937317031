import {useEffect, useState} from 'react';
import {IQuestionProps} from '../../QuestionForm/questionForm.types';
import {IAddressObj, ICountryObj} from './address.types';
import {useStepContext} from '../../../context/StepContext';
import {getDatasourceEntries} from '../../../services/storyblok';
import {StyledAddressWrapper} from './address.styles';
import {SavedAddress} from './savedAddress';
import {AddressLookup} from './addressLookup';
import {AddressInput} from './addressInput';
import {AddressConfirmation} from './addressConfirmation';

declare global {
  interface Window {
    initMap: () => void;
  }
}

export const Address = ({handleChange, buttonProps}: IQuestionProps) => {
  const {stepState} = useStepContext();
  const addressRecord = {
    address1: stepState?.address1 ?? '',
    address2: stepState?.address2 ?? '',
    city: stepState?.city ?? '',
    country: stepState?.country ?? '',
    countryName: stepState?.country ?? '',
    postalCode: stepState?.postalCode ?? '',
    addressState: stepState?.addressState ?? '',
  };
  const billingRecord = {
    address1: stepState?.billingLine1 ?? '',
    address2: stepState?.billingLine2 ?? '',
    city: stepState?.billingCity ?? '',
    country: stepState?.billingCountry ?? '',
    countryName: stepState?.billingCountry ?? '',
    postalCode: stepState?.billingPostalCode ?? '',
    addressState: stepState?.billingState ?? '',
  };
  const savedRecord =
    stepState?.step === 'billing' ? billingRecord : addressRecord;
  const [addressObj, setAddressObj] = useState<IAddressObj>(savedRecord);
  const [countriesData, setCountriesData] = useState<Array<ICountryObj>>([]);
  const [suggestedAddress, setSuggestedAddress] = useState<IAddressObj>({});
  const [selectedAddress, setSelectedAddress] = useState<IAddressObj>({});
  const [editMode, setEditMode] = useState<boolean>(false);

  // Address empty checker
  const isAddressEmpty = (address: IAddressObj) => {
    return (
      !address ||
      (!address.address1 &&
        !address.address2 &&
        !address.city &&
        !address.postalCode &&
        !address.addressState &&
        !address.countryName)
    );
  };

  // Get country data from storyblok ds
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const data = await getDatasourceEntries('country-lookup');
        const resData = data
          .map(({label, value}) => ({value, name: label}))
          .sort((a, b) => a.name.localeCompare(b.name));
        setCountriesData(resData);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchCountryData();
  }, []);

  // Set country name and value based on the value match with storyblok ds
  useEffect(() => {
    if (addressObj.country && countriesData.length > 0) {
      const countryEntry = countriesData.find(country =>
        country.name.includes(addressObj.country || '')
      );
      if (countryEntry) {
        setAddressObj(prevAddress => ({
          ...prevAddress,
          country: countryEntry.value,
          countryName: addressObj.country,
        }));
      }
    }
  }, [addressObj, countriesData]);

  // Handle selection of address
  const handleAddressSelect = (address: IAddressObj) => {
    handleChange({
      value: address,
      valid: true,
    });
    setSelectedAddress(address);
  };

  return (
    <StyledAddressWrapper>
      {isAddressEmpty(addressObj) && !editMode && (
        <AddressLookup
          address1={addressObj.address1 || ''}
          setAddressObj={setAddressObj}
          setEditMode={setEditMode}
        />
      )}
      {editMode && (
        <AddressInput
          addressObj={addressObj}
          countriesData={countriesData}
          setAddressObj={setAddressObj}
          setSuggestedAddress={setSuggestedAddress}
          setEditMode={setEditMode}
          handleAddressSelect={handleAddressSelect}
        />
      )}
      {Object.keys(suggestedAddress).length !== 0 && (
        <AddressConfirmation
          addressObj={addressObj}
          suggestedAddress={suggestedAddress}
          selectedAddress={selectedAddress}
          buttonProps={buttonProps}
          handleAddressSelect={handleAddressSelect}
        />
      )}
      {!isAddressEmpty(addressObj) &&
        !editMode &&
        Object.keys(suggestedAddress).length === 0 && (
          <SavedAddress
            addressObj={addressObj}
            setEditMode={setEditMode}
            handleAddressSelect={handleAddressSelect}
            buttonProps={buttonProps}
          />
        )}
    </StyledAddressWrapper>
  );
};
