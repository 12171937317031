// MSAL imports
import {
  EventType,
  EventMessage,
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';

import TagManager from 'react-gtm-module';

import {client as ApolloClient} from '../apollo';
import {msalConfig} from './config';
import {setDataLayer} from '../ga';

let instance: MsalUtility;
class MsalUtility {
  private msalInstance: PublicClientApplication;

  constructor() {
    if (instance) {
      throw new Error('New instance cannot be created!!');
    }

    this.msalInstance = new PublicClientApplication(msalConfig);
    this.init();

    instance = this;
  }

  /**
   * getInstance
   */
  public getInstance() {
    return this.msalInstance;
  }

  private init() {
    const msalInstance = this.msalInstance;
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);

        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GTM_CONTAINER_ID || '',
          dataLayer: {
            userId: payload.uniqueId,
          },
        };
        TagManager.initialize(tagManagerArgs);

        setDataLayer({
          event: 'stdapp_startApp',
          content: '(3.0) - App Started',
        });
      }

      if (event.eventType === EventType.LOGOUT_END) {
        localStorage.clear();
        sessionStorage.clear();
        ApolloClient.resetStore();
      }
    });
  }
}

let msal = Object.freeze(new MsalUtility());

export default msal;
