import React, { useState } from 'react';
import {
  StyledBillingPage,
  StyledInvoiceContainerWrapper,
} from './billing.styles';
import { NavigationTabs } from '../ui/navigationTabs/navigationTabs/navigationTabs';
import { BillingInvoiceTab } from './billingInvoiceTab';
import { BillingContractTab } from './billingContractTab';
import { render } from 'storyblok-rich-text-react-renderer';
import { useAppContext } from '../../context/AppContext';
import { useSettingsContext } from '../../context/SettingsContext';
import {
  useGetApplicationBillingContractsQuery,
  useGetBillingDocumentsV2Query,
} from '../../graphql';
import {PageLoader} from '../ui/pageLoader';
import {IBillingDocument, IBillingContract} from './billing.types';
import { AlertSa } from 'hult-toolkit';
import { variantMapping } from '../../utils/variantMapping';

const tabs = [
  { key: 'unpaidInvoices', title: 'Unpaid Invoices' },
  { key: 'invoicesByProgram', title: 'Invoices by Program' },
];

export const BillingCmp: React.FC = () => {
  const { selectedApplication } = useAppContext();
  const { errorMessage } = useSettingsContext();
  const [activeTab, setActiveTab] = useState('unpaidInvoices');

  const handleTabChange = (tabTitle: string) => setActiveTab(tabTitle);

  const getBillingDocuments = useGetBillingDocumentsV2Query({
    variables: {
      guid: selectedApplication || '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 360000,
    skip: !selectedApplication,
  });

  const getBillingContracts = useGetApplicationBillingContractsQuery({
    variables: {
      guid: selectedApplication || '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 360000,
    skip: !selectedApplication,
  });

  if (getBillingDocuments.loading || getBillingContracts.loading) {
    return <PageLoader />;
  }

  if (getBillingDocuments.error || getBillingContracts.error) {
    console.error(getBillingDocuments.error || getBillingContracts.error);
    return (
      <>
        {errorMessage && (
          <AlertSa
            title={errorMessage.title}
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage?.variant].icon_color}
          />
        )}
      </>
    );
  }

  const billingDocs =
    (getBillingDocuments.data
      ?.getApplicationBillingDocuments as IBillingDocument[]) || [];
  const billingContracts =
    (getBillingContracts.data
      ?.getApplicationBillingContracts as IBillingContract[]) || [];

  return (
    <StyledBillingPage>
      <NavigationTabs
        title="Your invoices"
        active={activeTab}
        onTabChange={handleTabChange}
        tabs={tabs}
      />

      {activeTab === 'unpaidInvoices' ? (
        <BillingInvoiceTab activeTab={activeTab} billingDocs={billingDocs} />
      ) : (
        <StyledInvoiceContainerWrapper>
          <BillingContractTab
            activeTab={activeTab}
            billingContracts={billingContracts}
          />
        </StyledInvoiceContainerWrapper>
      )}
    </StyledBillingPage>
  );
};
