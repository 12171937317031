import {CardStepSa, Grid} from 'hult-toolkit';
import {useAppContext, useAppDispatch} from '../../context/AppContext';
import {IApplication} from '../../context/AppContext/appContext.types';
import {useUpdateLastVisitMutation} from '../../graphql';
import { programMapping } from '../../utils';

export const ApplicationsList: React.FC = () => {
  const {applicationList, user} = useAppContext();
  const dispatch = useAppDispatch();
  const [updateLastVisit] = useUpdateLastVisitMutation();
  let applications: Array<IApplication> = [];

  if (applicationList?.billing && applicationList?.owner) {
    applications = applicationList.billing.concat(applicationList.owner);
  }

  const handleAppSelect = (id: string) => {
    dispatch({
      type: 'select-application',
      payload: id,
    });

    updateLastVisit({
      variables: {
        guid: id,
        page: '',
      },
    });
  };

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      {applications && (
        <Grid column={2}>
          {applications.map(item => (
            <CardStepSa
              data_name={programMapping(item.program.toUpperCase())}
              key={item.guid}
              onClick={() => handleAppSelect(item.guid)}
              label={capitalizeFirstLetter(item.access)}
              title={item.firstName + ' ' + item.lastName}
              desc={programMapping(item.program.toUpperCase())}
              state="resource"
              resource_icon="user"
            />
          ))}
        </Grid>
      )}
    </>
  );
};
