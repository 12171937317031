
import {useNavigate} from 'react-router-dom';
import {BreadcrumbItem} from '../ui/breadcrumb/breadcrumbItem/breadcrumbItem';
import {BreadcrumbWrapper} from '../ui/breadcrumb/breadcrumbWrapper/breadcrumbWrapper';
import {IBreadcrumbs} from './breadcrumb.types';
import { useEffect } from 'react';

export const Breadcrumbs: React.FC<IBreadcrumbs> = ({title}) => {
  let navigate = useNavigate();

  return (
    <BreadcrumbWrapper>
      <BreadcrumbItem
        title="Home"
        cursor="pointer"
        onClick={() => {
          navigate('/');
        }}
      />
      <BreadcrumbItem title={title} />
    </BreadcrumbWrapper>
  );
};
