import styled from 'styled-components';

export interface IStyledStepGroup {}

export const StyledStepGroup = styled.div<IStyledStepGroup>`
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    margin: 64px auto;
  }
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    margin: 48px auto;
  }
  @media (max-width: ${props => props.theme.breakPoints.mobile}) {
    margin: 32px auto;
  }
  & header {
    border-bottom: 2px solid ${props => props.theme.colors.darkGrey};
    padding-bottom: 8px;

    & p {
      ${props => props.theme.mixins.heading}
      margin: 0;
    }
  }
`;
export const StyledStepGroupGrid = styled.div`
  ${props => props.theme.mixins.grid3}
`;
