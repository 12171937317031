import { IQuestionState } from "./QuestionContext.types";

export const defaultObj: IQuestionState = {
  submitObj: {
    valid: false,
    value: '',
    name: '',
    savedValue: '',
    updated: false,
    qMapping: '',
  },
  questions: [],
  allQuestionMappings: [],
  questionMappingList: [],
  visibleQuestions: [],
  questionsOrder: [],
  questionSlug: '',
  questionIndex: 0,
  questionMapping: '',
  multiStepMode: false,
  firstQuestion: false,
  lastQuestion: false,
  stepUpdated: false,
  stepName: '',
  savedValues: {},
  savedSession: {},
  sessionValues: {}
}