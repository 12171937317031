  import {useEffect} from 'react';
  import {InteractionStatus} from '@azure/msal-browser';
  import {StoryblokComponent, useStoryblok} from '@storyblok/react';
  import {
    useIsAuthenticated,
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
  } from '@azure/msal-react';

  import {loginRequest} from '../services/msal/config';
  import {PageLoader} from '../components/ui/pageLoader';

  import {Breadcrumbs} from '../components/BreadCrumb';
import { Layout } from 'hult-toolkit';

  function AuthenticatedAddPayer() {

    const {content} = useStoryblok('sa3-1/payer-form', {
      version:
        (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') ||
        'published',
    });


    if (!content) {
      return <PageLoader />;
    }
    return (
      <>
        <Layout className='payer'>
          <Breadcrumbs title="Add Authorized User Form" />
          <StoryblokComponent key={content._uid} blok={content} />
        </Layout>
      </>
    );
  }

  export function PayerForm() {
    const isAuthenticated = useIsAuthenticated();
    const {instance, inProgress} = useMsal();

    useEffect(() => {
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(loginRequest);
      }
    });

    return (
      <>
        <AuthenticatedTemplate>
          <AuthenticatedAddPayer />
        </AuthenticatedTemplate>

        <Layout>
          <UnauthenticatedTemplate>
            <PageLoader />
          </UnauthenticatedTemplate>
        </Layout>
      </>
    );
  }
