import React, {useEffect} from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import {StoryblokComponent, useStoryblok} from '@storyblok/react';
import {PageLoader} from '../components/ui/pageLoader';
import {useAppContext} from '../context/AppContext';
import {Home} from './Home';
import {InteractionStatus} from '@azure/msal-browser';
import {useLocation} from 'react-router-dom';
import {loginRequest} from '../services/msal/config';
import {PayerHome} from './PayerHome';
import {Background} from '../components/Background';
import {UserAccessType} from '../context/AppContext/appContext.types';
import { Layout } from 'hult-toolkit';

const AuthenticatedDashboard = () => {
  const {applicationList, selectedApplication} = useAppContext();

  const applications = (applicationList?.billing || []).concat(
    applicationList?.owner || []
  );

  if (selectedApplication) {
    const app = applications.find(a => a.guid === selectedApplication);
    switch (app?.access) {
      case UserAccessType.BILLIG:
        return <PayerHome />;
      case UserAccessType.OWNER:
        return <Home />;
      default:
        return <PageLoader />;
    }
  }

  const slug = `sa3-1/dashboard/`;

  const {content} = useStoryblok(slug, {
    version:
      (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') ||
      'published',
  });

  if (!content) {
    return <PageLoader />;
  }

  return (
    <>
      <Background />
      <Layout>
        <StoryblokComponent blok={content} />
      </Layout>
    </>
  );
};

export const Dashboard: React.FC = () => {
  const {applicationList, user} = useAppContext();
  const isAuthenticated = useIsAuthenticated();
  const {instance, inProgress} = useMsal();
  const {hash} = useLocation();

  useEffect(() => {
    let token;
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      if (hash) {
        const hashParams = new URLSearchParams(hash.substring(1));
        if (hashParams.has('id_token')) {
          token = hashParams.get('id_token');
        }
      }
      if (token) {
        instance.loginRedirect({
          ...loginRequest,
          extraQueryParameters: {
            id_token_hint: token,
          },
        });
      } else {
        instance.loginRedirect(loginRequest);
      }
    }
  });

  return (
    <>
      <AuthenticatedTemplate>
        {(!!(applicationList && user) && <AuthenticatedDashboard />) || (
          <PageLoader />
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <PageLoader />
      </UnauthenticatedTemplate>
    </>
  );
};
