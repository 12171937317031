import { isValidJSON } from "../QuestionInputs/QuestionInputHelpers";

export const emptySchoolObj = {
  schoolName: '',
  schoolValue: '',
};

function getSchoolName(schoolObjStr: string) {
  return isValidJSON(schoolObjStr) ? JSON.parse(schoolObjStr)?.schoolName : ""
}

export function replacePlaceholders(sentence: string, obj: any) {
  const placeholderRegex = /\{\{(\w+)\}\}/g;

  return sentence.replace(placeholderRegex, (match, key) => {

    if (obj.hasOwnProperty(key)) {
      if (key === 'school') {
        return getSchoolName(obj[key])
      }
      return obj[key]
    }

    return match
  });
}

export function checkValueUpdated(value: any, savedValue: any, qMapping: string) {

  if (savedValue === null) {
    return value !== ''
  }

  if (typeof savedValue === 'boolean') {
    return value !== JSON.stringify(savedValue)
  }

  if (qMapping === 'address') {
    return JSON.stringify(value) !== JSON.stringify(savedValue)
  }

  if (qMapping === 'billingAddress') {
    const { countryName, ...restValue } = value;

    return JSON.stringify(restValue) !== JSON.stringify(savedValue)
  }

  if (value && isMMDDYYYYDateString(value)) {
    return convertToISOFormat(value) !== savedValue
  }

  return value !== savedValue
}


export const renderValue = (value: any) => {
  if (value && isMMDDYYYYDateString(value)) {
    return convertToISOFormat(value)
  }

  return value
}

export function dateMapping(qMap: string): { format: "MM/DD/YYYY" | "MM/YYYY"; allowFuture: boolean } {
  switch (qMap) {
    case 'dob':
      return {
        format: "MM/DD/YYYY",
        allowFuture: false
      };
    case 'educationEndDate':
      return {
        format: "MM/YYYY",
        allowFuture: true
      };
    default:
      return {
        format: "MM/YYYY",
        allowFuture: false
      };
  }
}

export function convertDateFormat(dateString: string) {

  if (isISODateString(dateString)) {
    const date = new Date(dateString);

    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${month}-${day}-${year}`;
  }
  
  if (isYYYYMMDDDateString(dateString)) {
    const [year, month, day] = dateString.split('-');

    return `${month}-${day}-${year}`
  }

  return dateString;
}

export function convertToISOFormat(dateString: string): string {

  const [month, day, year] = dateString.split('-');

  const yearNumber = parseInt(year, 10);
  const monthNumber = parseInt(month, 10) - 1; // Months are zero-indexed in JS Date
  const dayNumber = parseInt(day, 10);

  const date = new Date(Date.UTC(yearNumber, monthNumber, dayNumber));

  return date.toISOString();
}

export function isISODateString(dateString: string): boolean {

  const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/;

  return isoFormatRegex.test(dateString);
}

export function isMMDDYYYYDateString(dateString: string): boolean {
  const mmddyyyyRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-\d{4}$/;

  return mmddyyyyRegex.test(dateString);
}

export function isYYYYMMDDDateString(dateString: string): boolean {
  const yyyymmddRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

  return yyyymmddRegex.test(dateString);
}
