import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  IBillingContract,
  IBillingDocument,
  ShowPaidInvoicesState,
} from './billing.types';
import {Icon} from '../ui/icon/icon';
import {Invoice} from './Invoice';
import {InvoiceContainer} from './InvoiceContainer';
import {
  addDecimalIfNeeded,
  formatAndToggleSignIfNeeded,
  getUTCDate,
  dateFormat,
  filterBillingDocuments,
} from './billing.helper';
import {StyledIcon} from './billing.styles';

interface IBillingContractTab {
  activeTab: string;
  billingContracts: IBillingContract[];
}

export const BillingContractTab: React.FC<IBillingContractTab> = ({
  activeTab,
  billingContracts,
}) => {
  const navigate = useNavigate();
  const [showPaidInvoices, setShowPaidInvoices] =
    useState<ShowPaidInvoicesState>({});

  const dateNow = Date.now();

  const handleTogglePaidInvoices = (contractId: string) => {
    setShowPaidInvoices(prevShowPaidInvoices => ({
      ...prevShowPaidInvoices,
      [contractId]: !prevShowPaidInvoices[contractId],
    }));
  };

  return (
    <>
      {billingContracts.map(billingContract => {
        const billingDocuments = JSON.parse(
          JSON.stringify(billingContract.billingDocuments)
        ) as IBillingDocument[];

        const {paid} = filterBillingDocuments(billingDocuments);

        return (
          <>
            <InvoiceContainer
              key={billingContract.contractId}
              childrenLength={billingDocuments?.length}
              tab={activeTab}
              campus={billingContract.contractCampus}
              program={billingContract.contractProgram}
              contractAcademicYear={billingContract.contractAcademicYear}
              contractPaymentPlan={billingContract.contractPaymentPlan}
              contractCredits={billingContract.contractTotalCreditsBilled}
              currency={billingContract.currencyIsoCode}
              total={addDecimalIfNeeded(billingContract.contractRollUpLines)}
              paid={formatAndToggleSignIfNeeded(
                billingContract.contractAmountPaid
              )}
              paymentDue={addDecimalIfNeeded(
                billingContract.contractOutstandingAmount
              )}
              showPaidInvoices={billingContract.contractId}
              onTogglePaidInvoices={() =>
                handleTogglePaidInvoices(billingContract.contractId)
              }
              hasPaidInvoices={paid.length > 0}
              buttonLabel={
                showPaidInvoices ? (
                  <>
                    {showPaidInvoices[billingContract.contractId]
                      ? 'Hide'
                      : 'Show'}
                    <span>{paid.length}</span>
                    {paid.length === 1 ? ' paid invoice' : ' paid invoices'}
                    <StyledIcon
                      bodyVisible={showPaidInvoices[billingContract.contractId]}
                    >
                      <Icon icon="chevron-up" />
                    </StyledIcon>
                    <StyledIcon
                      bodyVisible={
                        !showPaidInvoices[billingContract.contractId]
                      }
                    >
                      <Icon icon="chevron-down" />
                    </StyledIcon>
                  </>
                ) : null
              }
            >
              {billingDocuments &&
                billingDocuments
                  .sort((a, b) =>
                    a.documentOutstandingValue === '0'
                      ? 1
                      : b.documentOutstandingValue === '0'
                      ? -1
                      : getUTCDate(a.documentDueDate) -
                        getUTCDate(b.documentDueDate)
                  )
                  .map(bd =>
                    showPaidInvoices[billingContract.contractId] ||
                    parseFloat(bd.documentOutstandingValue) !== 0 ? (
                      <Invoice
                        key={bd.documentId}
                        status={
                          parseFloat(bd.documentOutstandingValue) === 0
                            ? 'Paid'
                            : dateFormat(bd.documentDueDate) ===
                              dateFormat(
                                new Date(dateNow).toISOString().split('T')[0]
                              )
                            ? 'Due Today'
                            : getUTCDate(bd.documentDueDate) <
                              getUTCDate(
                                new Date(dateNow).toISOString().split('T')[0]
                              )
                            ? 'Past Due'
                            : 'Upcoming'
                        }
                        document_name={bd.documentName}
                        currency={bd.currencyIsoCode}
                        total={addDecimalIfNeeded(bd.documentTotal)}
                        paid={formatAndToggleSignIfNeeded(bd.amountPaid)}
                        paymentDue={addDecimalIfNeeded(
                          bd.documentOutstandingValue
                        )}
                        due_date={dateFormat(bd.documentDueDate)}
                        documentUrl={bd.documentUrl}
                        onClick={() =>
                          navigate({
                            pathname: `/question`,
                            search: `?step=program-fee&bd=${bd.documentName}`,
                          })
                        }
                      />
                    ) : null
                  )}
            </InvoiceContainer>
          </>
        );
      })}
    </>
  );
};
