import styled from 'styled-components';

export const StyledBanner = styled.div`
  position: relative;
  z-index: 1;
`;

export const StyledMain = styled.div`
  overflow-x: hidden;
  width: 100%;
  min-height: calc(100vh - 128px - 72px);
  font-family: ${props => props.theme.fonts.circularBook};
  @media (max-width: 425px) {
    min-height: calc(100vh - 192px - 72px);
  }
  @media (max-width: 375px) {
    min-height: calc(100vh - 208px - 72px);
  }
  @media (max-width: 320px) {
    min-height: calc(100vh - 240px - 72px);
  }

    &.payer {
      & > *:nth-last-of-type(1) {
        margin-bottom: 0;
        @media (max-width: ${props => props.theme.breakPoints.tablet}) {
          margin-bottom: 0;
        }
      }
    }
`;

export const StyledHeader = styled.header`
  position: relative;
  z-index: 10;
`;

export const StyledFooter = styled.footer`
  position: relative;
`;