import { DocumentNode } from '@apollo/client';
import { IDictionary } from '../../data/types';
import { cache } from '../../services/apollo';
import { GetApplicationContextDocument, GetQuestionV2Document } from '../../graphql';

export function readCache(guid: string, query: DocumentNode, inputVariables?: any) {

  let variables = {
    guid,
    ...inputVariables,
  }

  return cache.readQuery({
    query: query,
    variables,
  });
}

export function completedEnquiryStage(cachedData: any) {
  const atEnquiryStage = cachedData?.studentApplication?.appStage === 'Enquiry'
  const programStepCompleted = cachedData?.completedSteps?.programCompleted

  return atEnquiryStage && programStepCompleted
}

export function completedInProgressStage(cachedData: any) {
  const inProgressStage = cachedData?.studentApplication?.appStage === 'In Progress'
  const programStepCompleted = cachedData?.completedSteps?.submitApplicationCompleted

  return inProgressStage && programStepCompleted
}

export function updateStudentApplication(payload: {
  guid: string;
  newObject: IDictionary<any>;
}) {
  try {
    const { guid, newObject } = payload;

    const cachedData: any = readCache(guid, GetApplicationContextDocument);

    let updatedData = {
      ...cachedData,
      completedSteps: {
        ...cachedData?.completedSteps,
        ...newObject,
      }
    }

    if (completedEnquiryStage(updatedData)) {
      updatedData = {
        ...updatedData,
        studentApplication: {
          ...updatedData?.studentApplication,
          appStage: 'In Progress'
        }
      }
    }

    if (completedInProgressStage(updatedData)) {
      updatedData = {
        ...updatedData,
        studentApplication: {
          ...updatedData?.studentApplication,
          appStage: 'Partial Application'
        }
      }
    }

    cache.writeQuery({
      query: GetApplicationContextDocument,
      data: updatedData,
    });

    const newCache: any = readCache(guid, GetApplicationContextDocument)

    return {
      ...newCache?.studentApplication,
      completedStepsDetails: newCache?.completedSteps,
    }
  } catch (error) {
    console.log("updateStudentApplication error", error)
  }
}

export function updateStudentTypeCache(payload: {
  guid: string;
  step: string;
  newObject: IDictionary<any>;
  query: DocumentNode;
}) {
  const { guid, step, newObject, query } = payload;

  const previousData = readCache(guid, GetQuestionV2Document, { step });

  const updatedData = {
    getApplicationStep: {
      guid,
      step,
      // @ts-expect-error
      ...previousData?.getApplicationStep,
      ...newObject,
    },
  };

  cache.writeQuery({
    query: query,
    data: updatedData
  });
}
