export function checkIfOnlySpaces(str: string) {
  return /^\s*$/.test(str);
}

export function isValidJSON(jsonString: string) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

function isString(input: any) {
  return typeof input === 'string';
}

export function isSameValue(item: any, isSelected: any) {
  if (isString(item) && isString(isSelected)) {
    return item.toLowerCase() === isSelected.toLowerCase();
  }

  return item === isSelected;
}

export function DependantValueDefault(
  dependentOn: string,
  dependantValue: string
) {
  if (dependentOn === 'program') {
    return dependantValue?.toLowerCase();
  }

  return dependantValue;
}