import styled from 'styled-components';

export const StyledIcon = styled.div`
  padding: 0 8px;
`;

export const StyledMultiRecordNewCard = styled.div`
  min-height: 130px;
  width: 100%;
  border: 2px dashed black;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 414px) {
    min-height: 209px;
  }
`;
