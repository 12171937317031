import { FC } from 'react';
import { ISbStoryData, StoryblokComponent } from '@storyblok/react';
import { StyledDynamicWrapper } from '../Page/page.styles';
import { IDictionary } from '../../data/types';

interface IRootProps {
  blok: ISbStoryData & {
    Content: IDictionary<any>;
  };
}

export const Root: FC<IRootProps> = ({ blok }) => {
  return (
    <>
      <StyledDynamicWrapper>
        {blok.Content.map((c: any) => (
          <StoryblokComponent key={c._uid} blok={c} />
        ))}
      </StyledDynamicWrapper>
    </>
  );
};
