import styled from 'styled-components';

export const StyledPageLoaderWrapper = styled.div`
  position: relative;
`;

export const StyledPageLoaderContainer = styled.div`
  background: ${props => props.theme.colors.ivory};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPageLoader = styled.div`
  position: relative;
  width: 48px;
  height: 48px;

  > div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border: 4px solid;
    border-radius: 50%;
    animation: circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.theme.colors.darkGrey} transparent
      transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
  }

  @keyframes circle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
