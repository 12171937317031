import React, {createContext, useContext, useReducer} from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
} from '@azure/msal-react';

import {IAppDispatch, IAppState, IApplication, IUser} from './appContext.types';
import {PageLoader} from '../../components/ui/pageLoader';

import {appReducer} from './appReducer';
import {useGetAppData} from './useGetAppData';
import {useGetUserData} from './useGetUserData';

const AppContext = createContext<IAppState>({});
const AppDispatchContext = createContext<IAppDispatch>(() => {});

const AuthenticatedContext: React.FC<{
  email: string;
  children: React.ReactNode;
}> = ({children, email}) => {
  const [state, dispatch] = useReducer(appReducer, {
    email,
  });

  const {selectedApplication} = state;

  const userData = useGetUserData(dispatch);
  const appData = useGetAppData(selectedApplication, dispatch);

  if (userData.loading || appData.loading) return <PageLoader />;

  if (userData.error || appData.error) {
    console.log(userData.error, appData.error);
    throw userData.error || appData.error;
  }

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};

const AppContextProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const account = useAccount();

  let email: string | null = null;
  if (account?.idTokenClaims?.email) {
    email = account?.idTokenClaims?.email as string;
  }

  return (
    <>
      <AuthenticatedTemplate>
        {email ? (
          <AuthenticatedContext email={email}>{children}</AuthenticatedContext>
        ) : (
          <PageLoader />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>
    </>
  );
};

function useAppContext() {
  return useContext(AppContext);
}

function useAppDispatch() {
  return useContext(AppDispatchContext);
}

export {AppContextProvider, useAppContext, useAppDispatch};
