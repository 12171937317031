import React from 'react';
import {CardStepSa} from 'hult-toolkit';

export interface IMultiRecordParentCard {
  step?: string;
  parentFirstName?: string;
  parentLastName?: string;
  parentRelationship?: string;
  parentEmail?: string;
  onClickDelete?: any;
  onClickEdit?: any;
  complete?: boolean;
}

export const MultiRecordParentCard: React.FC<IMultiRecordParentCard> = ({
  parentFirstName,
  parentLastName,
  parentRelationship,
  parentEmail,
  onClickDelete,
  onClickEdit,
  complete,
}) => {
  return (
    <CardStepSa
      multistep={true}
      multistep_title={parentRelationship}
      label={'Incomplete'}
      onEdit={() => onClickEdit()}
      onDelete={() => onClickDelete()}
      state={complete ? 'complete' : 'default'}
      title={
        parentFirstName
        ? `${parentFirstName} ${parentLastName ? parentLastName : ''}`
        : ''
      }
      desc={parentEmail}
    />
  );
};
