import React from 'react';
import {StoryblokComponent, useStoryblok} from '@storyblok/react';

import {PageLoader} from '../components/ui/pageLoader';
import {Layout} from 'hult-toolkit';

export const NoPageFound: React.FC = () => {
  const {content} = useStoryblok(`sa3-1/not-found`, {
    version:
      (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') ||
      'published',
  });

  if (!content) {
    return <PageLoader />;
  }

  return (
    <Layout>
      <StoryblokComponent blok={content} />
    </Layout>
  );
};
