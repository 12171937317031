import styled from 'styled-components';

export const StyledNavigationTabsContainer = styled.div`
  margin-bottom: 32px;

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    margin-bottom: 24px;
  }
`;

export const StyledNavigationTabs = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & h1 {
    margin-bottom: 0;
    font-size: 24px;
  }

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const StyledNavigationTabsWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export const StyledBottomBorder = styled.div`
  height: 3px;
  width: 100%;
  background: ${props => props.theme.colors.black5};
  border-radius: 8px;
  margin-top: 8px;
`;
