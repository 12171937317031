import { useGetUserContextQuery } from '../../graphql';

export function useGetUserData(dispatch: (value: any) => void) {
  return useGetUserContextQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted({ getApplications, getUser }) {

      if (getApplications) {
        dispatch({
          type: 'update-applications',
          payload: getApplications,
        });
      }

      const applicationsList = [...getApplications?.billing, ...getApplications?.owner]

      if (applicationsList.length === 1) {
        dispatch({ type: 'select-application', payload: applicationsList[0].guid });
      } else if (getUser?.lastVisit?.guid) {
        dispatch({
          type: 'select-application',
          payload: getUser?.lastVisit?.guid,
        })
      }

      if (getUser) {
        dispatch({
          type: 'update-user',
          payload: getUser,
        });
      }

      dispatch({
        type: 'update-state',
        payload: { userLoaded: true },
      });
    },
    onError(error) {
      console.log("useGetUserContextQuery error", error);
    },
  });
}
