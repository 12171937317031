import { IGetNewDynamicState } from "./conditionalOption.types";

export   const getNewDynamicState = ({
  condition, 
  matchValue,
  result, 
  initState = 'default'
}: IGetNewDynamicState): string => {

  const conditionsMet = (condition === 'eq' && matchValue) || (condition === 'neq' && !matchValue);

  switch (result) {
    case 'show':
      return conditionsMet ? initState : 'hidden';
    case 'hide':
      return conditionsMet ? 'hidden' : initState;
    case 'lock':
      return conditionsMet ? 'locked' : initState;
    default:
      return 'default';
  }
};