import { createContext, useReducer, useContext } from 'react';
import { Action, StepContextType, StepState } from './StepContext.types';

const StepContext = createContext<StepContextType | undefined>(undefined);

const StepContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const stepReducer = (state: StepState, action: Action): StepState => {
    switch (action.type) {
      case 'update-step-context':
        return { ...state, ...action.payload };
      case 'update-program-context':
        
        return { 
          ...action.payload,
          step: state['step'],
          guid: state['guid'],
          __typename: state['__typename'],
          campus: '',
          earlyAction: '',
          specialization: ''
        };
      default:
        throw new Error('Unknown action: ' + action.type);
    }
  };

  const [stepState, stepDispatch] = useReducer(stepReducer, { step: '' } as StepState);

  return (
    <StepContext.Provider value={{ stepState, stepDispatch }}>
      {children}
    </StepContext.Provider>
  );
};

function useStepContext(): StepContextType {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error('useStepContext must be used within a StepContextProvider');
  }
  return context;
}

export { StepContextProvider, useStepContext };