import styled from 'styled-components';

export const StyledAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const StyledAddressContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;