import React from 'react';
import {Icon} from '../icon/icon';
import banner from '../../../assets/images/banner.png';
import {IBanner} from './banner.types';
import {Button} from 'hult-toolkit';
import './banner.scss';

export const Banner: React.FC<IBanner> = ({
  tag_label,
  tag_icon,
  tag_color,
  title,
  desc,
  buttons,
}) => {

  const colorMap: Record<string, string> = {
    blue: '#0C5B9E',
    green: '#0E6D63',
    red: '#AB050F',
  };

  const tagStyle = {
    '--tag-color': tag_color ? colorMap[tag_color] : '#0C5B9E',
  } as React.CSSProperties;

  return (
    <div className={'banner'}>
      <div className={'banner__content'}>
        <div className={'banner__content__tag'} style={tagStyle}>
          {tag_icon && <Icon className="fas" icon={tag_icon} />}
          {tag_label && <p>{tag_label}</p>}
        </div>
        {title && <h1>{title}</h1>}
        {<p className={'banner__content__desc'}>{desc}</p>}
        <div className={'banner__content__buttons'}>
          {buttons &&
            buttons.map((content: any, idx: any) => (
              <Button
                key={`hero-button-${idx}`}
                label={content.label}
                icon={{
                  animate: '',
                  library: 'solid',
                  name: content.icon,
                }}
                variant={content.variant}
                Wrapper={content.Wrapper}
                WrapperProps={content.WrapperProps}
              />
            ))}
        </div>
      </div>
      <div
        className={'banner__img'}
        style={{
          backgroundImage: `url(${banner})`,
        }}
      />
    </div>
  );
};
