import React, {useEffect, useRef} from 'react';
import {ISbStoryData} from '@storyblok/react';
import {sbLinkType} from '../../data/types';
import {useAppContext} from '../../context/AppContext';
import {replaceVars} from '../../utils';
import {BannerSa} from 'hult-toolkit';
import {transformMarkdown} from '../../utils/markdownHelper';

export interface IHeroButton {
  variant:
    | 'ghost'
    | 'primary'
    | 'secondary'
    | 'secondary_alt'
    | 'tertiary'
    | 'tertiary_alt'
    | 'ghost_alt'
    | 'disabled'
    | 'valid'
    | 'invalid';
  label: string;
  icon: string;
  link: sbLinkType;
}

export interface IHero {
  blok: ISbStoryData & {
    title: string;
    text: string;
    buttons: Array<IHeroButton>;
    tag: Array<{
      label: string;
      color: 'blue' | 'green' | 'red';
      icon: string;
      icon_color: 'yellow' | 'white';
    }>;
  };
}

export const Hero: React.FC<IHero> = ({blok}) => {
  const appState = useAppContext();
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const {acceptanceLetter, appStage} = useAppContext();

  useEffect(() => {
    const bannerP = bannerRef.current?.querySelector('p') as HTMLDivElement;
    if (bannerP) {
      const transformedText = transformMarkdown(
        replaceVars(appState, blok.text)
      ).replace(/<\/?span[^>]*>/g, '');
      bannerP.innerHTML = transformedText;
    }
  }, [blok.text]);

  const heroButtons = blok.buttons.map(button => ({
    label: button.label,
    variant: button.variant,
    icon: {
      name: button.icon,
    },
    Wrapper: 'button',
    WrapperProps: {
      onClick: () => {
        window.open(button.link.url), '_blank';
      },
    },
  }));

  const acceptanceLetterConditionsMet = () => {
    const onAcceptedStage = appStage === 'Accepted' || appStage === 'Conditionally Accepted';
    const hasBeenNotified = acceptanceLetter?.notifiedDate !== null;

    return onAcceptedStage && hasBeenNotified
  }

  if (acceptanceLetterConditionsMet()) {
    const acceptanceLetterId = acceptanceLetter?.acceptanceLetter;
    const acceptanceLetterIdLink = `${process.env.REACT_APP_ACCEPTANCE_URL}=${acceptanceLetterId}`
    const acceptanceLetterUrlLink = acceptanceLetter?.acceptanceLetterUrl;
    const renderedLink = acceptanceLetterId ? acceptanceLetterIdLink : acceptanceLetterUrlLink

    if (renderedLink) {
      heroButtons.push({
        label: 'View Letter',
        variant: 'secondary',
        Wrapper: 'button',
        icon: {
          name: 'arrow-up-right-from-square',
        },
        WrapperProps: {
          onClick: () => window.open(renderedLink, '_blank')
        },
      });
    }
  }

  const tag = blok.tag && blok.tag.length > 0 ? blok.tag[0] : null;

  return (
    <div ref={bannerRef}>
      <BannerSa
        buttons={heroButtons}
        desc={replaceVars(appState, blok.text)}
        tag={
          tag
            ? [
                {
                  label: tag.label,
                  color: tag.color,
                  icon: tag.icon,
                  icon_color: tag.icon_color,
                },
              ]
            : undefined
        }
        title={replaceVars(appState, blok.title)}
      />
    </div>
  );
};
