export const variantMapping: {
    [key: string]: {
      icon: string;
      icon_color: "red" | "green" | "orange";
    };
  } = {
    error: {
      icon_color: "red",
      icon: "times"
    },
    warning: {
      icon_color: "orange",
      icon: "triangle-exclamation"
    },
    success: {
      icon_color: "green",
      icon: "check"
    },
  };