export function programMapping(value: string): string {
    const mapping: { [key: string]: string } = {
        "BBA": "Bachelor of Business Administration",
        "MBA": "One-Year MBA",
        "EMBA": "Executive MBA",
        "GLOnlineMBA": "Global Online MBA",
        "MIB": "Master's in International Business",
        "MBAN": "Master's in Business Analytics",
        "MEI": "Master's in Entrepreneurship and Innovation",
        "MFIN": "Master's in Finance",
        "MIM": "Master's in International Marketing",
        "DBA": "Doctorate of Business Administration",
        "EC": "Executive Coaching",
        "TC": "Team Coaching",
        "DipCS": "Postgraduate Diploma in Supervision",
        "DOC": "Doctorate in Organizational Change",
        "FINMBA": "Boston-New York Finance MBA",
        "MIBI": "Master’s in International Business – Internship Track",
        "MIBA": "Master's in International Business - Analytics Track",
        "MBAAN": "One-Year MBA - Analytics Track",
        "SBBC": "Hult Summer Business Bootcamp",
        "HultStaffMBA": "Hult Staff MBA",
        "EYMSUS": "EY Masters in Sustainability",
        "EYMBAN": "EY Masters in Business Analytics",
        "EYCPA10": "EY Career Path Accelerator - 10 Credits",
        "EYCPA15": "EY Career Path Accelerator - 15 Credits",
        "EYCPA20": "EY Career Path Accelerator - 20 Credits",
        "MIMA": "Master's in Marketing and Analytics",
        "EYTMBA": "EY Tech MBA"
    };
    
        // Create reverse mapping
        const reverseMapping = Object.keys(mapping).reduce((acc, key) => {
            acc[mapping[key]] = key;
            return acc;
        }, {} as { [key: string]: string });
    
        if (value === undefined) {
            return "";
        }
    
        // Check if the value is in the original mapping or the reverse mapping
        return mapping[value] || reverseMapping[value] || value;
    }