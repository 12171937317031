import { useEffect, useState } from 'react';
import { isValidJSON } from '../QuestionInputHelpers';
import { IQuestionProps } from '../../QuestionForm/questionForm.types';
import { RecommendationUI } from './RecommendationUI';
import { useStepContext } from '../../../context/StepContext';

const emptyRecommendation = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  jobTitle: '',
};

export const RecommendationInput = ({
  handleChange,
}: IQuestionProps) => {

  
  const { stepState } = useStepContext()
  const savedRecord = stepState.recommendation || '';

  const defaultRecommendation = () => {
    if (savedRecord === null || isValidJSON(savedRecord) === false) {
      return emptyRecommendation;
    }

    return JSON.parse(savedRecord);
  };

  const [recommendationObj, setRecommendationObj] = useState(
    defaultRecommendation()
  );
  const [recommendationValid, setRecommendationValid] = useState(
    savedRecord !== null
  );

  useEffect(() => {
    handleChange({
      value: JSON.stringify(recommendationObj),
      valid: recommendationValid,
    });
  }, [recommendationObj]);

  return (
    <RecommendationUI
      defaultValue={defaultRecommendation()}
      handleChange={(e: any) => {
        setRecommendationObj(e.value);
        setRecommendationValid(e.valid);
      }}
    />
  );
};
