import {InputField, Button} from 'hult-toolkit';
import {useEffect} from 'react';
import {IAddressObj} from './address.types';
import { setDataLayer } from '../../../services/ga';

interface AddressLookupProps {
  address1: string;
  setAddressObj: React.Dispatch<React.SetStateAction<IAddressObj>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddressLookup = ({
  address1,
  setAddressObj,
  setEditMode,
}: AddressLookupProps) => {
  // Handle view state for manual for filling
  const handleManualEntry = () => {
    setEditMode(true);
    setAddressObj({});
    setDataLayer({
      event: 'stdapp_address',
      content: '(3.0) - Manual Input',
    });
  };

  // Load the script for google api
  const loadScript = (url: string, callback: () => void) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    document.head.appendChild(script);
  };

  // Fill in address form with google data
  const fillInAddress = (place: google.maps.places.PlaceResult) => {
    const componentForm: {[key: string]: string} = {
      street_number: 'address1',
      route: 'address1',
      subpremise: 'address2',
      postal_town: 'city',
      locality: 'city',
      administrative_area_level_1: 'addressState',
      country: 'country',
      postal_code: 'postalCode',
    };

    const newAddressObj: {[key: string]: string} = {address1: ''};

    place.address_components?.forEach(component => {
      component.types.forEach(type => {
        const key = componentForm[type];
        if (key) {
          if (key === 'address1') {
            newAddressObj[key] = `${newAddressObj[key] || ''} ${
              component.long_name
            }`.trim();
          } else {
            newAddressObj[key] = component.long_name;
          }
        }
      });
    });

    return newAddressObj;
  };

  // Autocomplete logic from google using the name of the lookup input
  const initAutocomplete = (
    fillInAddress: (place: google.maps.places.PlaceResult) => void
  ) => {
    const input = document.getElementsByName(
      'autocomplete'
    )[0] as HTMLInputElement;
    if (input && window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        fields: ['address_components', 'geometry', 'name'],
        types: ['geocode'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          window.alert(`No details available for input: '${place.name}'`);
          return;
        }
        fillInAddress(place);
      });
    }
  };

  // Handlde filling address and set states
  const handleFillAddress = (place: google.maps.places.PlaceResult) => {
    const filledAddress = fillInAddress(place);
    setAddressObj(filledAddress);
    setEditMode(true);
  };


  // Initalise google autocomplete
  const initializeGoogleAutocomplete = () => {
    window.initMap = () => initAutocomplete(handleFillAddress);
    if (window.google && window.google.maps) {
      initAutocomplete(handleFillAddress);
    } else {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_ADDRESS}&libraries=places&callback=initMap`,
        () => {}
      );
    }
  };

  // Initialise autocomplete on load
  useEffect(() => {
    initializeGoogleAutocomplete();
  }, []);

  return (
    <>
      <InputField
        placeholder="Start typing your address"
        name="autocomplete"
        type="text"
        onChange={() => {}}
        value={address1}
        show_validation={false}
      />
      <Button
        label="Manually enter address"
        icon={{
          name: 'pencil',
        }}
        variant="ghost"
        Wrapper="button"
        WrapperProps={{
          onClick: handleManualEntry,
        }}
      />
    </>
  );
};
