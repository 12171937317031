import {useAppContext} from '../context/AppContext';

export const stageMapping = (stage?: string) => {
  const {completedStepsDetails, acceptanceLetter} = useAppContext();

  const notified = acceptanceLetter?.notifiedDate;

  const applicationNotSubmitted =
    !completedStepsDetails?.submitApplicationCompleted;

  switch (stage?.toLowerCase()) {
    case 'enquiry':
      return 'enquiry';
    case 'partial application':
      return applicationNotSubmitted ? 'in-progress' : 'partial';
    case 'accepted':
      return notified ? 'accepted' : 'partial';
    case 'conditionally accepted':
      return notified ? 'conditionally-accepted' : 'partial';
    case 'conditionally confirmed':
      return 'conditionally-confirmed';
    case 'confirmed':
      return 'confirmed';
    case 'rejected':
      return 'rejected';
    case 'withdrawn':
      return 'withdrawn';
    case 'cancellation':
      return 'cancellation';
    case 'deferral':
      return 'deferral';
    default:
      return 'in-progress';
  }
};
