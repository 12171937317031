import React, {useEffect} from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import {InteractionStatus} from '@azure/msal-browser';
import {loginRequest} from '../services/msal/config';
import {StoryblokComponent, useStoryblok} from '@storyblok/react';
import {PageLoader} from '../components/ui/pageLoader';
import {useAppContext} from '../context/AppContext';
import {useLocation} from 'react-router-dom';

const AuthenticatedPayerHome = () => {
  const slug = `sa3-1/payer-portal/home-page`.toLowerCase();

  const {content} = useStoryblok(slug, {
    version:
      (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') ||
      'published',
    resolve_relations:
      'page.locked_steps,page.steps,section.steps,section.locked_steps',
  });

  if (!content) {
    return <PageLoader />;
  }

  return <StoryblokComponent blok={content} />;
};

export const PayerHome: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const {instance, inProgress} = useMsal();
  const {appStage, graduate} = useAppContext();
  const {hash} = useLocation();

  useEffect(() => {
    let token;
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      if (hash) {
        const hashParams = new URLSearchParams(hash.substring(1));
        if (hashParams.has('id_token')) {
          token = hashParams.get('id_token');
        }
      }
      if (token) {
        instance.loginRedirect({
          ...loginRequest,
          extraQueryParameters: {
            id_token_hint: token,
          },
        });
      } else {
        instance.loginRedirect(loginRequest);
      }
    }
  });

  return (
    <>
      <AuthenticatedTemplate>
        {(!!(appStage && graduate) && <AuthenticatedPayerHome />) || (
          <PageLoader />
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <PageLoader />
      </UnauthenticatedTemplate>
    </>
  );
};
