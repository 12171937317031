import { FC, useEffect, useState } from 'react';
import { ISchoolLookUp } from './SchoolLookUp.types';
import { isValidJSON } from '../QuestionInputHelpers';
import { LookupField } from 'hult-toolkit';
import { useStepContext } from '../../../context/StepContext';
import { useQuestionContext } from '../../../context/QuestionContext';
import { emptySchoolObj } from '../../SingleQuestion/SingleQuestion.helper';

interface IDataSource {
  content: {
    name: string;
    value: string;
    alt_name: string;
  };
}

export const SchoolInput: FC<ISchoolLookUp> = ({ handleChange, datasource }) => {
  const { stepState } = useStepContext();
  const { questionState } = useQuestionContext();

  const savedSchool = stepState[questionState?.questionMapping];

  const defaultSchoolObj = isValidJSON(savedSchool) ? JSON.parse(savedSchool) : emptySchoolObj;

  const defaultInputVal = () => {
    if (defaultSchoolObj?.schoolName && defaultSchoolObj?.schoolValue) {
      return {
        name: defaultSchoolObj?.schoolName,
        value: defaultSchoolObj?.schoolValue,
      };
    }
    return undefined;
  };

  const [schoolObj, setSchoolObj] = useState(defaultSchoolObj);
  const [validSchool, setValidSchool] = useState(false);
  const [inputTitle, setInputTitle] = useState<string>('');
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const res = {
      value: JSON.stringify(schoolObj),
      valid: validSchool,
    };

    handleChange(res);
  }, [schoolObj, validSchool]);

  let debounceTimer: NodeJS.Timeout;

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    if (inputTitle.length > 1) {
      setLoading(true);
      debounceTimer = setTimeout(() => {
        fetch(
          `https://api.storyblok.com/v2/cdn/stories?starts_with=${datasource}&per_page=10&token=${process.env.REACT_APP_STORYBLOK_DATASOURCE_TOKEN}&version=draft&search_term=${inputTitle}&cv=2`
        )
          .then(res => res.json())
          .then(({ stories }) => {
            const resStories = stories.map(({ content }: any) => ({
              content: {
                name: content?.name,
                value: content?.id,
                alt_name: content?.locale_name,
              },
            }));
            setResult(resStories);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }, 2000);
    } else {
      setResult([]);
      setLoading(false);
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [inputTitle]);

  return (
    <LookupField
      label=""
      name="school"
      allow_free={true}
      default_value={defaultInputVal()}
      required
      fetching={loading}
      options={result}
      onChange={(selectedValue) => {
        if (typeof selectedValue.value === 'object') {
          setSchoolObj({
            schoolName: selectedValue.value.name,
            schoolValue: selectedValue.value.value,
          });
          setValidSchool(selectedValue.valid)
        } else if (typeof selectedValue.value === 'string') {
          setInputTitle(selectedValue.value);
          setSchoolObj({
            schoolName: selectedValue.value,
            schoolValue: selectedValue.value,
          });
          setValidSchool(selectedValue.value.length > 2 && selectedValue.valid)
        }
      }}
    />
  );
};