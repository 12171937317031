const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_AZURE_SIGNUP_POLICY,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AZURE_SIGNUP_AUTH,
    },
  },
  authorityDomain: process.env.REACT_APP_AZURE_AUTH_DOMAIN,
};

export default b2cPolicies;
