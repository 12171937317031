import React, {useRef, useEffect} from 'react';
import {IDocumentUpload} from './documentUpload.types';
import {transformMarkdown} from '../../../utils/markdownHelper';
import {Button} from 'hult-toolkit';
import './documentUpload.scss';

export const DocumentUpload: React.FC<IDocumentUpload> = ({
  state,
  title,
  copy,
  filename,
  accept,
  onChange,
  viewHandler,
  deleteHandler,
}) => {
  const copyRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (copyRef.current && copy) {
      copyRef.current.innerHTML = transformMarkdown(copy).replace(
        /<\/?span[^>]*>/g,
        ''
      );
    }
  }, [copy]);

  const isLoading = state === 'loading';
  const isUploaded = state === 'uploaded';

  return (
    <div
      className={`document-upload ${
        isLoading ? 'document-upload--loading' : ''
      }`}
    >
      <div className={'document-upload__container'}>
        <div className={'document-upload__container__heading'}>
          {title && (
            <h3
              className={`document-upload__container__heading__title ${
                isLoading
                  ? 'document-upload__container__heading__title--loading'
                  : ''
              }`}
            >
              {title}
            </h3>
          )}
          {copy && (
            <p
              className={`document-upload__container__heading__copy ${
                isLoading
                  ? 'document-upload__container__heading__copy--loading'
                  : ''
              }`}
              ref={copyRef}
            ></p>
          )}
        </div>
        {isUploaded ? (
          <div
            className={
              'document-upload__container__document document-upload__container__document--uploaded'
            }
          >
            {filename && <p>{filename}</p>}
            <div className={'document-upload__container__document__options'}>
              <Button
                label={'View'}
                variant={'ghost'}
                icon={{
                  animate: '',
                  library: 'solid',
                  name: 'eye',
                }}
                icon_reverse={'true'}
                size={'small'}
                Wrapper="button"
                WrapperProps={{onClick: (e: any) => viewHandler && viewHandler(e)}}
              />
              <Button
                label={'Delete'}
                variant={'ghost'}
                icon={{
                  animate: '',
                  library: 'solid',
                  name: 'times',
                }}
                icon_reverse={'true'}
                size={'small'}
                Wrapper="button"
                WrapperProps={{
                  onClick: (e: any) => deleteHandler && deleteHandler(e),
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={`document-upload__container__document ${
              isLoading
                ? 'document-upload__container__document--loading'
                : 'document-upload__container__document--default'
            }`}
          >
            <Button
              label={'Add document'}
              variant={state === 'loading' ? 'disabled' : 'tertiary'}
              icon={{
                animate: '',
                library: 'solid',
                name: 'plus',
              }}
            />
            <input type="file" accept={accept} onChange={onChange} />
          </div>
        )}
      </div>
    </div>
  );
};
