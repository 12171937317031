import React from 'react';
import {IIcon} from './icon.types';
import {StyledIcon} from './icon.styles';

export const Icon: React.FC<IIcon> = ({
  library = 'fa-regular',
  icon = 'times',
  className,
}) => {
  return (
    <span>
      <StyledIcon
        data-testid="icon"
        className={`fa-sharp ${library} fa-${icon} ${className}`}
      />
    </span>
  );
};
