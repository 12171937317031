import React, { createContext, useReducer, useContext } from 'react';
import { Action, IQuestionContextType, IQuestionState } from './QuestionContext.types';
import { defaultObj } from './QuestionContextUtils';

const QuestionContext = createContext<IQuestionContextType | undefined>(undefined);

const QuestionContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const questionReducer = (state: IQuestionState, action: Action): IQuestionState => {
    switch (action.type) {
      case 'complete-step':
        return {
          ...state,
          stepCompleted: action.payload.stepCompleted
        }
      case 'update-question-context':
        return {
          ...state,
          ...action.payload
        }
      case 'update-question-session':
        return {
          ...state,
          sessionValues: {
            ...state.sessionValues,
            ...action.payload
          }
        }
      case 'update-submit-object':
        return {
          ...state,
          submitObj: {
            ...state.submitObj,
            ...action.payload.submitObj
          }
        }
      default:
        throw new Error('Unknown action: ' + action.type);
    }
  };

  const [questionState, questionDispatch] = useReducer(questionReducer, defaultObj);

  return (
    <QuestionContext.Provider value={{ questionState, questionDispatch }}>
      {children}
    </QuestionContext.Provider>
  );
};

function useQuestionContext(): IQuestionContextType {
  const context = useContext(QuestionContext);
  if (!context) {
    throw new Error('useQuestionContext must be used within a questionContextProvider');
  }
  return context;
}

export {
  QuestionContextProvider,
  useQuestionContext
};