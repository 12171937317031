import React from 'react';
import {IBreadcrumbItem} from './breadcrumbItem.types';
import {Icon} from '../../icon/icon';
import './breadcrumbItem.scss';

export const BreadcrumbItem: React.FC<IBreadcrumbItem> = ({
  title,
  url,
  onClick,
  cursor = 'default',
}) => {

  const cursorStyle = {
    '--cursor': cursor,
  } as React.CSSProperties;

  return (
    <div className={'crumb'} style={cursorStyle}>
      {title && title.length > 1 ? <Icon icon="arrow-right-long" /> : null}
      <a href={url} onClick={onClick}>
        {title}
      </a>
    </div>
  );
};
