import React from 'react';

import {StyledMultiRecordNewCard} from './multiRecordSteps.styles';
import { Button } from 'hult-toolkit';

export interface IMultiRecordNewCard {
  label: string;
  onClick: any;
}

export const MultiRecordNewCard: React.FC<IMultiRecordNewCard> = ({
  label,
  onClick,
}) => {
  return (
    <StyledMultiRecordNewCard>
      <Button
        Wrapper="button"
        WrapperProps={{
          onClick: () => onClick(),
        }}
        variant={'tertiary'}
        label={label}
        icon={{
          animate: '',
          library: 'solid',
          name: 'plus',
        }}
      />
    </StyledMultiRecordNewCard>
  );
};
