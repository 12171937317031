import React, {useEffect, useState} from 'react';
import {findApplication, useQuery} from '../../utils';
import {useGetPaymentSessionQuery} from '../../graphql';

import '@adyen/adyen-web/dist/adyen.css';
import {useAppContext} from '../../context/AppContext';
import {useScript} from '../../utils/useScript';
import {PageLoader} from '../ui/pageLoader';
import {PaymentWrapper} from './PaymentWrapper';

declare global {
  interface Window {
    flywire?: any;
  }
}

export interface IFlywireAPayment {
  step_name: string;
  display_name: string;
}

const isQuals = (program: string) =>
  ['tc', 'ec', 'doc', 'dipcs'].includes(program.toLowerCase());

const isEYCPA = (program: string) =>
  ['eycpa10', 'eycpa20', 'eycpa15'].includes(program.toLowerCase());

function getDestination(step: string, program: string, currency: string) {
  if (process.env.REACT_APP_FLYWIRE_ENVIRONMENT === 'demo') {
    if (isQuals(program)) {
      return 'AEE';
    }
    return 'HSD';
  }

  if (isEYCPA(program)) {
    return 'HUX';
  }

  if (isQuals(program)) {
    switch (step) {
      case 'confirmationDeposit':
        return 'QAE';
      case 'applicationFee':
        return 'AEH';
      default:
        return 'AEK';
    }
  }

  switch (`${step}_${currency}`) {
    case 'confirmationDeposit_AED':
    case 'reactivationDeposit_AED':
      return 'HCD';
    case 'confirmationDeposit_GBP':
    case 'reactivationDeposit_GBP':
      return 'HBP';
    case 'confirmationDeposit_USD':
    case 'reactivationDeposit_USD':
      return 'HSD';
    case 'programFee_AED':
    case 'tuitionFee_AED':
      return 'HLA';
    case 'programFee_GBP':
    case 'tuitionFee_GBP':
      return 'HLG';
    case 'programFee_USD':
    case 'tuitionFee_USD':
    default:
      return 'HLU';
  }
}

function getFunctionType(stepName: string) {
  switch (stepName) {
    case 'applicationFee':
      return 'Application Fee';
    case 'confirmationDeposit':
      return 'Confirmation Deposit Payment';
    case 'tuitionFee':
    case 'programFee':
    default:
      return 'Program Fee Payment';
  }
}

function initPayment(stepName: string, paymentData: any, cData: any) {
  const applicantInfo = findApplication(
    cData.selectedApplication,
    cData.applicationList
  );
  const address = cData.user?.address ? JSON.parse(cData.user?.address) : {};

  const senderArgs = {
    sender_email: cData?.user?.email,
    sender_first_name: cData?.user?.firstName,
    sender_last_name: cData?.user?.lastName,
    sender_country: paymentData?.countryCode,
    sender_phone: cData?.user?.phoneNumber,
    sender_address1: address?.address1,
    sender_address2: address?.address2,
    sender_city: address?.city,
    sender_state: address?.state,
    sender_zip: address?.postalCode,
  };

  const studentArgs = {
    student_first_name: applicantInfo?.firstName,
    student_last_name: applicantInfo?.lastName,
    student_email: cData?.application_owner,
    student_id: paymentData.studentId,
  };

  const flywireArgs = {
    provider: 'embed',
    env: process.env.REACT_APP_FLYWIRE_ENVIRONMENT,
    locale: 'en-EN',
    destination: getDestination(
      stepName,
      cData.program,
      paymentData.currencyIsoCode
    ),
    payment_type: getFunctionType(stepName),
    amount: paymentData.documentOutstandingValue,
    ...senderArgs,
    ...studentArgs,
    read_only: 'payment_type',
    callback_url: process.env.REACT_APP_FLYWIRE_CALLBACK,
    callback_id: paymentData.reference,
    theme: {
      header: false,
      backgroundColor: '#ffffff',
      brandColor: '#3498db',
    },
  };

  if (window.flywire?.Payment) {
    window.flywire.Payment.render(flywireArgs, '#flywire-payex');
  }
}

function getStep(step_name: string, billingDocument: string | null) {
  switch (step_name.toLowerCase()) {
    case 'confirmationdeposit':
    case 'applicationfee':
    case 'reactivationdeposit':
      return step_name;
    default:
      return `billing_document_${billingDocument}`;
  }
}

export const FlywireAPayment: React.FC<IFlywireAPayment> = ({
  step_name,
  display_name,
}) => {
  const appState = useAppContext();

  let query = useQuery();
  const [rendeder, setRenderer] = useState(false);
  const billingDocument = query.get('bd');

  const flywireStatus = useScript(
    'https://wl.flywire.com/assets/js/flywire.js'
  );

  const step = getStep(step_name, billingDocument);

  const {loading = true, data} = useGetPaymentSessionQuery({
    variables: {
      guid: appState.selectedApplication || '',
      step: step,
      type: 'flywire',
      url: window.location.href,
    },
    fetchPolicy: 'cache-and-network',
    skip: !appState.selectedApplication,
  });

  const success = query.get('success') ? true : false;

  useEffect(() => {
    if (
      !loading &&
      data &&
      !success &&
      !rendeder &&
      flywireStatus === 'ready'
    ) {
      initPayment(step_name, data?.getPaymentSession, appState);
      setRenderer(true);
    }
  }, [loading, flywireStatus]);

  if (loading) {
    return <PageLoader />;
  }

  const dataResult = data?.getPaymentSession?.result?.toLowerCase() || '';

  return (
    <PaymentWrapper display_name={display_name} result={dataResult}>
      <div id="flywire-payex"></div>
    </PaymentWrapper>
  );
};
