import { Banner } from '../banner';
import { IBannerWrapper } from './bannerWrapper.types';

export const BannerWrapper = ({ blok }: IBannerWrapper) => {
  const heroButtons = blok.buttons.map((button) => ({
    variant: button.variant,
    label: button.label,
    icon: button.icon,
    WrapperProps: {
      onClick: () => {
        window.open(button.link.url), '_blank';
      },
    },
  }));

  return (
      <Banner
        tag_label={blok?.tag_label}
        tag_icon={blok?.tag_icon}
        tag_color={blok?.tag_color}
        title={blok?.title}
        desc={blok?.desc}
        buttons={heroButtons}
      />
  );
};
