import React from 'react';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from 'react-router-dom';

import {client} from './services/apollo';
import {ApolloProvider} from '@apollo/client';
import {MsalProvider} from '@azure/msal-react';

import msal from './services/msal';
import {initStoryblok} from './services/storyblok';

import {ThemeProvider} from 'styled-components';

import {GlobalStyle} from './assets/styles/globalStyle';
import {theme} from './assets/styles/theme';

import {AppContextProvider} from './context/AppContext';
import {SettingsContextProvider} from './context/SettingsContext';

import {PageLayout} from './pages/PageLayout';
import {Question, Account, Editor, NoPageFound} from './pages';
import {Billing} from './pages/Billing';
import {PayerForm} from './pages/PayerForm';
import {Dashboard} from './pages/Dashboard';

initStoryblok();

export const App: React.FC = () => (
  <>
    <GlobalStyle theme={theme} />
    <Router>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msal.getInstance()}>
          <ApolloProvider client={client}>
            <AppContextProvider>
              <SettingsContextProvider>
                <PageLayout>
                  <Switch>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/question/" element={<Question />} />
                    <Route path="/billing/" element={<Billing />} />
                    <Route path="/payer-form/" element={<PayerForm />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/preview/" element={<Editor />} />
                    <Route path="*" element={<NoPageFound />} />
                  </Switch>
                </PageLayout>
              </SettingsContextProvider>
            </AppContextProvider>
          </ApolloProvider>
        </MsalProvider>
      </ThemeProvider>
    </Router>
  </>
);

export default App;
