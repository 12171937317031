import {useStoryblok, StoryblokComponent} from '@storyblok/react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '../utils';
import {PageLoader} from '../components/ui/pageLoader';

function EditorPage() {
  const query = useQuery();

  const story = useStoryblok(`${query.get('slug')}`, {
    version: 'draft',
    resolve_relations: 'page.steps,page.locked_steps,step.questions,section.steps,section.locked_steps',
  });

  if (!story?.content) {
    return <PageLoader />;
  }

  return <StoryblokComponent blok={story.content} isPreview />;
}

export function Editor() {
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!query.get('_storyblok') || !query.get('slug'))
      navigate('/', {replace: true});
  }, []);

  if (!query.get('_storyblok') || !query.get('slug')) {
    return <PageLoader />;
  }

  return <EditorPage />;
}
