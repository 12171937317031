import {useEffect} from 'react';
import {useAppContext} from '../context/AppContext';
import {StoryblokComponent, useStoryblok} from '@storyblok/react';
import {InteractionStatus} from '@azure/msal-browser';
import {
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import {PageLoader} from '../components/ui/pageLoader';
import {loginRequest} from '../services/msal/config';
import {Breadcrumbs} from '../components/BreadCrumb';
import {Layout, SectionSa} from 'hult-toolkit';
import {BackButton} from '../components/BackButton';

function AuthenticatedBilling() {
  const {content} = useStoryblok(`sa3-1/billing`, {
    version:
      (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') ||
      'published',
  });

  return (
    <>
      <Breadcrumbs title={'Billing'} />
      <SectionSa>
        <BackButton />
        <StoryblokComponent blok={content} />
      </SectionSa>
    </>
  );
}

export function Billing() {
  const {appStage, graduate} = useAppContext();
  const isAuthenticated = useIsAuthenticated();
  const {instance, inProgress} = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
  });

  return (
    <Layout>
      <AuthenticatedTemplate>
        {(appStage && graduate && <AuthenticatedBilling />) || <PageLoader />}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <PageLoader />
      </UnauthenticatedTemplate>
    </Layout>
  );
}
