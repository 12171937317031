interface IPaymentWrapper {
  display_name: string
  result: string;
  children: JSX.Element;
}

export const PaymentWrapper = ({ display_name, children, result }: IPaymentWrapper) => {
  switch (result) {
    case 'not_found':
      return <h1>Payment cannot be found</h1>
    case 'paid':
      return (
        <>
          <p>You have already paid the {display_name}. Thank you very much!</p>
          <p>If you have any inquiries, please kindly reach out to your enrollment advisor.</p>
        </>
      )
    default:
      return (
        <>
          {children}
        </>
      )
  }
}