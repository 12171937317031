import React from "react";
import { IBreadcrumbWrapper } from "./breadcrumbWrapper.types";
import './breadcrumbWrapper.scss'

export const BreadcrumbWrapper: React.FC<IBreadcrumbWrapper> = ({
  children,
}) => {
  return (
      <div className={'breadcrumb'}>
        {children}
      </div>
  );
};