import { AlertSa, LookupMultiField } from "hult-toolkit";
import { useState, useEffect } from "react";
import { useSettingsContext } from "../../../context/SettingsContext";
import { getDatasourceEntries } from "../../../services/storyblok";
import { variantMapping } from "../../../utils/variantMapping";
import { LoadingInput } from "../TextInput/TextInput.helpers";
import { IMultiLookUp } from "./MultiLookup.types";
import { useStepContext } from "../../../context/StepContext";
import { useQuestionContext } from "../../../context/QuestionContext";

interface ILookUpObj {
  value: string;
  name: string;
}

interface IMultiLookUpSubmitObj {
  value: ILookUpObj[];
  valid: boolean;
}

export const MultiLookUpInput = ({
  datasource,
  handleChange,
}: IMultiLookUp) => {

  const { stepState } = useStepContext()
  const { questionState } = useQuestionContext()
  const { errorMessage } = useSettingsContext();
  const { questionMapping } = questionState;

  const defaultValue = stepState?.[questionMapping] || '';

  const [dsOptions, setDsOptions] = useState<ILookUpObj[] | undefined>([]);
  const [selectedOptions, setSelectedOptions] = useState<ILookUpObj[]>([]);
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const [submitObj, setSubmitObj] = useState<IMultiLookUpSubmitObj>({
    value: [],
    valid: false,
  });

  function setDefaultSelectedOptions(inputData: any[]) {
    if (questionMapping === 'allLanguages') {
      const languageStrings = defaultValue.split(';');

      const languageObjects = inputData.filter((obj) => {
        return languageStrings.includes(obj.value);
      });

      setSelectedOptions(defaultValue === '' ? [] : languageObjects);

      return
    }

    setSelectedOptions(JSON.parse(defaultValue))
  }

  useEffect(() => {
    if (datasource)
      getDatasourceEntries(datasource || '')
        .then(data => {

          const formattedData = data.map(({ value, label }) => ({
            name: label,
            value: value
          }))

          setDefaultSelectedOptions(formattedData)
          setDsOptions(formattedData);
          setOptionsLoaded(true);
        })
        .catch(error => {
          console.error(error);
          const storyblokError = errorMessage?.desc?.content[0].content[0].text;
          return (
            <>
              {errorMessage && (
                <AlertSa
                  desc={storyblokError}
                  icon={variantMapping[errorMessage.variant].icon}
                  icon_color={variantMapping[errorMessage?.variant].icon_color}
                  title={errorMessage?.title || ''}
                />
              )}
            </>
          );
        });
  }, [datasource]);

  useEffect(() => {

    const submittingVal = () => {
      if (questionMapping === 'allLanguages') {
        const allLanguagesArr = submitObj.value.map((obj) => obj?.value)
        const allLanguagesStr = allLanguagesArr.join(";")

        return allLanguagesStr
      }

      return JSON.stringify(submitObj.value)
    };

    handleChange({
      value: submittingVal(),
      valid: submitObj.valid,
    });
  }, [submitObj]);

  if (!optionsLoaded) {
    return <LoadingInput />;
  }

  const inputChange = (e: any) => {
    setSubmitObj(e);
  };

  const multiFieldOptions = dsOptions?.map(({ name, value }) => {
    return {
      content: {
        name: name,
        alt_name: name,
        value
      }
    }
  })

  return (
    <LookupMultiField
      options={multiFieldOptions || []}
      onChange={inputChange}
      name={""}
      default_value={selectedOptions || []}
    />
  );
};
