import styled from 'styled-components'

export const StyledSubmitApplication = styled.div`
  margin: 0 !important;
  position: relative;
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.white};
  cursor: pointer;
`;
export const StyledSubmitApplicationContainer = styled.div`
  ${props => props.theme.mixins.container};

  & > div{    
    box-shadow: none;

    & > div{
      margin-right: 0;
      margin-left: 0;
    }
  }
`
