import styled from 'styled-components';

export const StyledBackgroundImgLeft = styled.div`
  position: absolute;
  top: 50%;
  z-index: 0;

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    display: none;
  }
`;

export const StyledBackgroundImgRight = styled.div`
  position: absolute;
  right: 0;
  top: 66px;
  z-index: 0;

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    top: 0;
  }
`;