import React from 'react';
import {InvoiceContainer} from './InvoiceContainer';
import {IBillingDocument} from './billing.types';
import {
  addDecimalIfNeeded,
  dateFormat,
  filterBillingDocuments,
  formatAndToggleSignIfNeeded,
  getUTCDate,
} from './billing.helper';
import {Invoice} from './Invoice';
import {useNavigate} from 'react-router-dom';

interface IBillingInvoiceTab {
  activeTab: string;
  billingDocs: IBillingDocument[];
}

export const BillingInvoiceTab: React.FC<IBillingInvoiceTab> = ({
  activeTab,
  billingDocs,
}) => {
  const navigate = useNavigate();

  const dateNow = Date.now();

  const {pastdue, upcoming, duetoday} = filterBillingDocuments(billingDocs);

  return (
    <InvoiceContainer
      key="unpaidInvoices"
      childrenLength={pastdue.length + upcoming.length + duetoday.length}
      tab={activeTab}
    >
      {[...pastdue, ...duetoday, ...upcoming].map(bd => (
        <Invoice
          key={bd.documentId}
          status={
            parseFloat(bd.documentOutstandingValue) === 0
              ? 'Paid'
              : dateFormat(bd.documentDueDate) ===
                dateFormat(new Date(dateNow).toISOString().split('T')[0])
              ? 'Due Today'
              : getUTCDate(bd.documentDueDate) <
                getUTCDate(new Date(dateNow).toISOString().split('T')[0])
              ? 'Past Due'
              : 'Upcoming'
          }
          document_name={bd.documentName}
          currency={bd.currencyIsoCode}
          total={addDecimalIfNeeded(bd.documentTotal)}
          paid={formatAndToggleSignIfNeeded(bd.amountPaid)}
          paymentDue={addDecimalIfNeeded(bd.documentOutstandingValue)}
          due_date={dateFormat(bd.documentDueDate)}
          documentUrl={bd.documentUrl}
          onClick={() =>
            navigate({
              pathname: `/question`,
              search: `?step=program-fee&bd=${bd.documentName}`,
            })
          }
        />
      ))}
    </InvoiceContainer>
  );
};
