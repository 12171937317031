import React from 'react';
import {
  StyledInvoice,
  StyledInvoiceDataList,
  StyledInvoiceData,
  StyledInvoiceStatus,
  StyledInvoiceDueDate,
  StyledInvoicePaymentDataList,
  StyledInvoicePaymentData,
  StyledBold,
  StyledInvoiceActions,
} from './billing.styles';
import {Button} from 'hult-toolkit';
import usd from '../../assets/images/usd.svg';
import aed from '../../assets/images/aed.svg';
import gbp from '../../assets/images/gbp.svg';

interface IInvoice {
  status: string;
  document_name: string;
  total: string;
  paymentDue: string;
  paid: string;
  currency: string;
  due_date: string;
  documentUrl?: string;
  onClick?: (e: any) => any;
}

const viewDocument = (documentUrl?: string) => {
  if (window && documentUrl) {
    const nTab = window.open(documentUrl, '_blank');
    if (nTab) {
      nTab.focus();
    }
  }
};

export const Invoice: React.FC<IInvoice> = ({
  status,
  document_name,
  currency,
  paymentDue,
  paid,
  total,
  due_date,
  documentUrl,
  onClick,
}) => {
  return (
    <StyledInvoice status={status}>
      <StyledInvoiceDataList>
        {status && (
          <StyledInvoiceStatus status={status}>{status}</StyledInvoiceStatus>
        )}
        {document_name && (
          <StyledInvoiceData>
            <p>Invoice ID: </p>
            <p>{document_name}</p>
          </StyledInvoiceData>
        )}
        {due_date && (
          <StyledInvoiceDueDate>
            <p>Due:</p>
            <p>{due_date}</p>
          </StyledInvoiceDueDate>
        )}
      </StyledInvoiceDataList>

      <StyledInvoicePaymentDataList>
        {currency && (
          <StyledInvoicePaymentData>
            <p>Currency</p>
            <p>
              {currency === 'USD' && <img alt="Hult" src={usd} />}
              {currency === 'GBP' && <img alt="Hult" src={gbp} />}
              {currency === 'AED' && <img alt="Hult" src={aed} />}
              {currency}
            </p>
          </StyledInvoicePaymentData>
        )}

        {total && (
          <StyledInvoicePaymentData>
            <p>Total</p>
            <p>{total}</p>
          </StyledInvoicePaymentData>
        )}

        {paid && (
          <StyledInvoicePaymentData>
            <p>Paid</p>
            <p>{paid}</p>
          </StyledInvoicePaymentData>
        )}

        {paymentDue && (
          <StyledInvoicePaymentData>
            <p>Payment due</p>
            <StyledBold status={status}>{paymentDue}</StyledBold>
          </StyledInvoicePaymentData>
        )}
      </StyledInvoicePaymentDataList>

      <StyledInvoiceActions>
        {documentUrl && (
          <Button
            Wrapper="button"
            WrapperProps={{onClick: () => viewDocument(documentUrl)}}
            label="View"
            size="small"
            variant={'tertiary'}
          />
        )}
        <Button
          Wrapper="button"
          WrapperProps={{onClick}}
          icon={{
            animate: '',
            library: 'solid',
            name: 'arrow-right',
          }}
          label="Pay"
          size="small"
          variant={status === 'Paid' ? 'disabled' : 'primary'}
        />
      </StyledInvoiceActions>
    </StyledInvoice>
  );
};
