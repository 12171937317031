import React, {useState, useEffect} from 'react';
import {IAddressObj, ICountryObj, IValidationComponent} from './address.types';
import {InputField, SelectField, Button} from 'hult-toolkit';
import {StyledAddressContent} from './address.styles';
import {stateNameMapping} from '../../../utils/stateNameMapping';

interface AddressInputProps {
  addressObj: IAddressObj;
  countriesData: Array<ICountryObj>;
  setSuggestedAddress: React.Dispatch<React.SetStateAction<IAddressObj>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setAddressObj: React.Dispatch<React.SetStateAction<IAddressObj>>;
  handleAddressSelect: (address: IAddressObj) => void;
}

export const AddressInput = ({
  addressObj,
  countriesData,
  setSuggestedAddress,
  setEditMode,
  setAddressObj,
  handleAddressSelect,
}: AddressInputProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);

  // Validate the form
  const validateAllFields = () => {
    const {address1, city, postalCode, addressState, country} = addressObj;
    const isAddress1Valid = typeof address1 === 'string' && address1.length > 0;
    const isCityValid = typeof city === 'string' && city.length > 0;
    const isPostalCodeValid =
      typeof postalCode === 'string' && postalCode.length > 0;
    const isAddressStateValid =
      typeof addressState === 'string' && addressState.length > 0;
    const isCountryValid = typeof country === 'string' && country !== undefined;

    setIsValid(
      isAddress1Valid &&
        isCityValid &&
        isPostalCodeValid &&
        isAddressStateValid &&
        isCountryValid
    );
  };

  // Address Object update and handle country
  const updateAddressObj = (
    selectedValue: {
      name: string;
      required: boolean;
      valid: boolean;
      value: string | undefined;
    },
    country: boolean
  ) => {
    if (country) {
      const selectedCountry = countriesData.find(
        country => country.value === selectedValue.value
      );

      setAddressObj(prevAddress => ({
        ...prevAddress,
        country: selectedCountry?.value || selectedValue.value,
        countryName: selectedCountry?.name || '',
      }));
    } else {
      setAddressObj(prevAddress => ({
        ...prevAddress,
        [selectedValue.name]: selectedValue.valid ? selectedValue.value : '',
      }));
    }
  };

  // Handle input changes
  const handleInputChange = (selectedValue: any, country: boolean) => {
    updateAddressObj(selectedValue, country);
  };

  // Google address validation
  const validateAddress = async () => {
    if (!isValid) {
      console.error('Form is not valid.');
      return;
    }

    try {
      const response = await fetch(
        `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_ADDRESS}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address: {
              addressLines: [addressObj.address1, addressObj.address2],
              locality: addressObj.city,
              administrativeArea: addressObj.addressState,
              postalCode: addressObj.postalCode,
            },
            languageOptions: {
              returnEnglishLatinAddress: 'true',
            },
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error('API response error here:', errorData);

        // Check if the error is due to an unsupported region code
        if (
          errorData.error &&
          errorData.error.code === 400 &&
          errorData.error.message.includes('Unsupported region code')
        ) {
          setSuggestedAddress(addressObj);
          handleAddressSelect(addressObj);
          setEditMode(false);
          return;
        }

        throw new Error(`Request failed with status ${response.status}`);
      }

      const data = await response.json();
      const validatedAddress = data.result.englishLatinAddress;

      if (validatedAddress && validatedAddress.addressComponents) {
        const addressComponents = validatedAddress.addressComponents;
        const getAddressComponent = (type: string, index = 0) => {
          const components = addressComponents.filter(
            (comp: IValidationComponent) => comp.componentType === type
          );
          return components[index] ? components[index].componentName.text : '';
        };

        const getStateFullName = (stateCode: string) => {
          return stateNameMapping[stateCode] || stateCode;
        };

        setSuggestedAddress({
          address1: `${getAddressComponent(
            'street_number'
          )} ${getAddressComponent('route')}`,
          address2: getAddressComponent('route', 1),
          city:
            getAddressComponent('postal_town') ||
            getAddressComponent('locality'),
          country: addressObj.country,
          countryName: addressObj.countryName,
          postalCode: getAddressComponent('postal_code'),
          addressState: getStateFullName(
            getAddressComponent('administrative_area_level_1') ||
              getAddressComponent('administrative_area_level_2') ||
              getAddressComponent('administrative_area_level_3')
          ),
        });
        setEditMode(false);
      } else {
        console.error('Validated address is undefined', validatedAddress);
      }
    } catch (error) {
      setSuggestedAddress(addressObj);
      handleAddressSelect(addressObj);
      setEditMode(false);
      console.error('Error validating address:', error);
    }
  };

  // Validate the form everytime the addressObj changes
  useEffect(() => {
    validateAllFields();
  }, [addressObj]);

  return (
    <>
      <StyledAddressContent>
        <InputField
          label="Address line 1"
          name="address1"
          type="text"
          min_length={3}
          required
          onChange={selectedValue => handleInputChange(selectedValue, false)}
          value={addressObj.address1 || ''}
        />
        <InputField
          label="Address line 2 (optional)"
          name="address2"
          type="text"
          required={false}
          onChange={selectedValue => handleInputChange(selectedValue, false)}
          value={addressObj.address2 || ''}
        />
        <InputField
          label="City"
          name="city"
          type="text"
          min_length={2}
          required
          onChange={selectedValue => handleInputChange(selectedValue, false)}
          value={addressObj.city || ''}
        />
        <InputField
          label="Post/Zip Code"
          name="postalCode"
          type="text"
          min_length={2}
          required
          onChange={selectedValue => handleInputChange(selectedValue, false)}
          value={addressObj.postalCode || ''}
        />
        <InputField
          label="State/County/Province"
          name="addressState"
          type="text"
          min_length={3}
          placeholder="Please enter the full state name"
          required
          onChange={selectedValue => handleInputChange(selectedValue, false)}
          value={addressObj.addressState || ''}
        />
        <SelectField
          label="Country/Region"
          name="country"
          placeholder="Select a country/region"
          options={countriesData}
          required
          onChange={selectedValue => handleInputChange(selectedValue, true)}
          value={addressObj.country || ''}
        />
      </StyledAddressContent>
      <Button
        label="Continue"
        icon={{
          name: 'arrow-right',
        }}
        variant={isValid ? 'primary' : 'disabled'}
        Wrapper="button"
        WrapperProps={{
          onClick: () => {
            validateAddress();
          },
        }}
      />
    </>
  );
};
