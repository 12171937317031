import React, { useState } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {useQuery} from '../../utils';
import {useGetPaymentSessionQuery} from '../../graphql';
import {PageLoader} from '../ui/pageLoader';
import {useAppContext} from '../../context/AppContext';
import {useSettingsContext} from '../../context/SettingsContext';
import {render} from 'storyblok-rich-text-react-renderer';
import {AlertSa} from 'hult-toolkit';
import {variantMapping} from '../../utils/variantMapping';
import {PaymentWrapper} from './PaymentWrapper';

export interface IAdyenPayment {
  step_name: string;
  handleCompletePayment: () => void;
  isPreview?: boolean;
  display_name?: string;
}

interface AdyenResult {
  resultCode: string;
}

export const AdyenPayment: React.FC<IAdyenPayment> = ({
  step_name,
  handleCompletePayment,
  isPreview,
  display_name = '',
}) => {
  const {selectedApplication} = useAppContext();
  const {errorMessage} = useSettingsContext();
  const [isError, setIsError] = useState<boolean>();
  let query = useQuery();

  const {loading, data, error} = useGetPaymentSessionQuery({
    variables: {
      guid: selectedApplication || '',
      step: step_name,
      type: 'adyen',
      url: window.location.href,
    },
    skip: !selectedApplication || isPreview,
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error || isError) {
    error && console.error(error);
    return (
      <>
        {errorMessage && (
          <AlertSa
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage.variant].icon_color}
            title={errorMessage.title || ''}
          />
        )}
      </>
    );
  }

  async function initPayment(data: any) {
    const configuration = {
      environment: process.env.REACT_APP_ADYEN_ENVIRONMENT || 'test', // Change to 'live' for the live environment.
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      session: {
        ...data?.getPaymentSession,
      },
      onPaymentCompleted: (result: AdyenResult, component: any) => {
        if (result.resultCode.toLowerCase() === 'authorised') {
          handleCompletePayment();
        } else {
          setIsError(true);
        }
      },
      onError: (error: any, component: any) => {
        console.error(error.name, error.message, error.stack, component);
        setIsError(true);
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: true,
        },
        ideal: {
          showImage: true,
        },
      },
      showPayButton: true,
      storePaymentMethod: true,
      locale: 'en_US',
      instantPaymentTypes: ['paywithgoogle'],
    };

    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await AdyenCheckout(configuration);

    // Create an instance of Drop-in and mount it to the container you created.
    checkout.create('dropin').mount('#dropin-container');
  }

  const success = query.get('success') ? true : false;

  if (!loading && data && !success) {
    initPayment(data);
  }

  const dataResult = data?.getPaymentSession?.result?.toLowerCase();

  return (
    <PaymentWrapper display_name={display_name} result={dataResult || ''}>
      <div id="dropin-container"></div>
    </PaymentWrapper>
  );
};
