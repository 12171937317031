import React from 'react';
import {dateFormatter} from './multiRecordSteps.helpers';
import {CardStepSa} from 'hult-toolkit';

export interface IMultiRecordEmploymentCard {
  step?: string;
  title?: string;
  startDate?: string;
  employmentCompanyName?: string;
  school?: string;
  endDate?: string;
  onClickDelete?: any;
  onClickEdit?: any;
  employmentCurrentRole: boolean;
  employmentStartDate?: Date;
  employmentEndDate?: Date;
  complete?: boolean;
}

export const MultiRecordEmploymentCard: React.FC<
  IMultiRecordEmploymentCard
> = ({
  title,
  onClickDelete,
  onClickEdit,
  employmentCompanyName,
  employmentStartDate,
  employmentEndDate,
  employmentCurrentRole,
  complete,
}) => {
  return (
    <CardStepSa
      desc={
        (employmentStartDate && !isNaN(employmentStartDate.valueOf())
          ? dateFormatter(employmentStartDate)
          : 'Unknown') +
        ' - ' +
        (employmentCurrentRole
          ? 'Present'
          : employmentEndDate && !isNaN(employmentEndDate.valueOf())
          ? dateFormatter(employmentEndDate)
          : 'Unknown')
      }
      multistep
      multistep_title={title}
      label={'Incomplete'}
      onEdit={() => onClickEdit()}
      onDelete={() => onClickDelete()}
      state={complete ? 'complete' : 'default'}
      title={employmentCompanyName}
    />
  );
};
