import { InputField } from 'hult-toolkit';

export const LoadingInput = () => {
  return (
    <InputField
      default_value=""
      label="Loading"
      min_length={0}
      name="Loading"
      onChange={() => {}}
      type="text"
      loading={true}
    />
  );
};
