import { useQuestionContext } from "../../context/QuestionContext";
import { useStepContext } from "../../context/StepContext";
import { StyledButtonContainer } from "../QuestionForm/questionForm.styles"
import { QuestionFormButton } from "../QuestionForm/questionFormButton"
import { useNavigate } from 'react-router-dom';
import { updateQuestions } from '../Steps/steps.helpers';
import { useState } from 'react';
import { useSubmitProgram } from './QuestionListSubmission.helpers';
import { ISbStoryData } from '@storyblok/react';
import { ISbQuestion } from '../../data/types';

export const QuestionListSubmission = () => {
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate()
  const submitProgram = useSubmitProgram()

  const { stepState, stepDispatch } = useStepContext();
  const { questionState, questionDispatch } = useQuestionContext();

  const {
    sessionValues,
    questionsOrder,
    questionMappingList,
    questions,
    submitObj,
    visibleQuestions,
    lastQuestion
  } = questionState;

  const [saving, setSaving] = useState(false);
  const questionParam = params.get('q')

  const changeQuestions = (slug: string) => {
    navigate(`/question?step=program&q=${slug}`)
  }

  const updateQuestionsList = (newQuestions: ISbStoryData<ISbQuestion>[], newSessionValues: any) => {

    const newQuestionMappingList = newQuestions.map((q) => q.content.question_mapping)
    const newQuestionOrder = newQuestions.map((q) => q.slug)
    const newFinalQuestion = newQuestionOrder.length - 1

    const currentIndex = newQuestionOrder.indexOf(questionParam || '')

    if (currentIndex === newFinalQuestion) {
      handleFinalQuestion()
    } else {
      const nextIndex = currentIndex + 1
      const nextQuestionSlug = newQuestionOrder[nextIndex]
      const nextQuestionMapping = newQuestionMappingList[nextIndex]
      const lastQuestion = newQuestionOrder.indexOf(nextQuestionSlug) === newFinalQuestion

      const nextQuestionPayload = {
        visibleQuestions: newQuestions,
        questionMappingList: newQuestionMappingList,
        questionsOrder: newQuestionOrder,
        questionMapping: nextQuestionMapping,
        questionSlug: nextQuestionSlug,
        firstQuestion: false,
        questionIndex: nextIndex,
        lastQuestion,
        sessionValues: newSessionValues,
        submitObj: {
          name: '',
          value: '',
          valid: false,
          qMapping: nextQuestionMapping,
          savedValue: stepState[nextQuestionMapping as string] ?? '',
          updated: false
        }
      }

      questionDispatch({
        type: 'update-question-context',
        payload: nextQuestionPayload
      })

      changeQuestions(nextQuestionSlug)
    }
  }

  const handleUpdate = async () => {

    const { value, qMapping, name } = submitObj

    const newState = {
      [qMapping]: value
    }

    const newSession = {
      [qMapping]: name
    }

    const newStateValues = { ...stepState, ...newState }
    const newSessionValues = { ...sessionValues, ...newSession }
    const newQuestions = updateQuestions(questions, newStateValues)
    const questionsModified = JSON.stringify(newQuestions) !== JSON.stringify(visibleQuestions)

    // If program changed, the step state is cleared so that it accurately reflects the state of the step.
    // If any other question is changed, just that attribute on the step state object is updated.
    if (newState['program'] && (newState['program'] !== stepState['program'])) {
      stepDispatch({
        type: 'update-program-context',
        payload: newState
      })
    } else {
      stepDispatch({
        type: 'update-step-context',
        payload: newState
      })
    }

    if (questionsModified) {
      updateQuestionsList(newQuestions, newSessionValues)
    } else {

      if (questionState.lastQuestion) {
        questionDispatch({
          type: 'update-question-context',
          payload: {
            ...questionState,
            sessionValues: newSessionValues
          }
        })
        handleFinalQuestion()
      } else {
        nextQuestion(newSessionValues)
      }
    }
  }

  const handleFinalQuestion = async () => {
    setSaving(true)
    await submitProgram()
  }

  const nextQuestion = (newSession?: any) => {

    const currentIndex = questionsOrder.indexOf(questionParam ?? '')
    const nextIndex = currentIndex + 1
    const nextQuestionMapping = questionMappingList[nextIndex]
    const nextQuestionSlug = questionsOrder[nextIndex]

    const newSubmitObj = {
      name: '',
      value: '',
      valid: false,
      qMapping: nextQuestionMapping,
      savedValue: stepState?.[nextQuestionMapping] || '',
      updated: false
    }

    const nextQuestionPayload = {
      firstQuestion: false,
      questionIndex: nextIndex,
      questionSlug: nextQuestionSlug,
      questionMapping: nextQuestionMapping,
      lastQuestion: nextIndex === questionsOrder.length - 1,
      submitObj: newSubmitObj,
      sessionValues: newSession ?? sessionValues
    }

    questionDispatch({
      type: 'update-question-context',
      payload: nextQuestionPayload
    })

    changeQuestions(nextQuestionSlug)
  }

  const submitClick = () => {
    if (submitObj.updated) {
      handleUpdate()
    } else {
      if (lastQuestion) {
        handleFinalQuestion()
      } else {
        nextQuestion()
      }
    }
  }

  return (
    <StyledButtonContainer>
      <QuestionFormButton
        label='Continue'
        saving={saving}
        onClick={submitClick}
        disabled={submitObj.valid === false}
      />
    </StyledButtonContainer>
  )
}