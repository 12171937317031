import {AlertSa} from 'hult-toolkit';
import {variantMapping} from '../../../utils/variantMapping';
import {IAlertWrapper} from './alertWrapper.types';

export const AlertWrapper = ({blok}: IAlertWrapper) => {
  return (
      <AlertSa
        desc={blok?.desc?.content[0]?.content[0]?.text}
        icon={variantMapping[blok.variant].icon}
        icon_color={variantMapping[blok?.variant].icon_color}
        title={blok?.title}
      />
  );
};