import {IDictionary} from '../../data/types';
import {setDataLayer} from '../../services/ga';
import {stripMultiStep} from '../../utils';
import {IAppState} from './appContext.types';
import { updateStudentApplication, updateStudentTypeCache} from './reducerFunctions';

export function appReducer(
  state: IAppState,
  action: {type: string; payload: any}
): IAppState {
  let nextState = Object.assign({}, state);

  switch (action.type) {
    case 'update-application-payers':
      nextState = Object.assign({}, nextState, {
        applicationPayers: action.payload,
      });
      break;
    case 'select-application':
      nextState = Object.assign({}, nextState, {
        selectedApplication: action.payload,
      });
      break;
    case 'update-applications':
      nextState = Object.assign({}, nextState, {
        applicationList: {...action.payload},
      });
      break;
    case 'update-user':
      nextState = Object.assign({}, nextState, {
        user: {...action.payload},
      });
      break;
    case 'update-state':
      nextState = Object.assign({}, nextState, {...action.payload});
      break;
    case 'update-acceptance-letter':
      nextState = Object.assign({}, nextState, {
        acceptanceLetter: {...action.payload},
      });
      break;
    case 'update-session':
      nextState = Object.assign({}, nextState, {
        session: {...action.payload},
      });
      break;
    case 'update-azure-details':
      nextState = Object.assign({}, nextState, {
        azureDetails: {...action.payload},
      });
      break;
    case 'update-completed-steps':
      nextState = Object.assign({}, nextState, {
        completedStepsDetails: {
          ...action.payload,
        },
      });
      break;
    case 'update-student-type-cache':
      updateStudentTypeCache(action.payload);
      break;

    case 'complete-step':
      let completedStep: IDictionary<any> = {};
      completedStep[`${stripMultiStep(action.payload.step)}Completed`] = true;

      const newObject = updateStudentApplication({
        guid: action.payload.guid,
        newObject: completedStep
      })

      nextState = Object.assign({}, nextState, newObject);

      break;
    case 'uncomplete-step':
      let uncompletedStep: IDictionary<any> = {};
      uncompletedStep[`${stripMultiStep(action.payload.step)}Completed`] =
        false;

      const uncompletedObj = updateStudentApplication({
        guid: action.payload.guid,
        newObject: uncompletedStep
      })

      nextState = Object.assign({}, nextState, uncompletedObj)

      break;
    case 'ga-event':
      setDataLayer({...action.payload});
      break;
    case 'update-program-details':
      nextState = Object.assign({}, nextState, {
        programData: {...action.payload},
      });
      break;
    default:
      throw Error('Unknown action: ' + action.type);
  }

  return nextState;
}
