import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {createAuthLink, AUTH_TYPE} from 'aws-appsync-auth-link';

import appSyncConfig from './aws-exports';
import {loginRequest} from '../msal/config';
import msal from '../msal';

async function getToken() {
  const response = await msal.getInstance().acquireTokenSilent({
    ...loginRequest,
  });

  return response.accessToken;
}

const url = appSyncConfig.aws_appsync_graphqlEndpoint;
const region = appSyncConfig.aws_appsync_region;
const auth = {
  token: () => getToken(),
  type: AUTH_TYPE.AWS_LAMBDA,
};

const httpLink = createHttpLink({uri: url});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path}) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

// @ts-ignore - createAuthLink complains
// about AWS_LAMBDA type even though its valid
const link = from([createAuthLink({url, region, auth}), httpLink, errorLink]);

export const cache = new InMemoryCache({
  typePolicies: {
    ApplicationStep: {
      keyFields: ['guid', 'step'],
    },
  },
});

export const client = new ApolloClient({
  link,
  cache: cache,
  queryDeduplication: true,
  connectToDevTools: process.env.NODE_ENV !== 'production',
});
