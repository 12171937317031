import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepGroup } from '../ui/stepGroup';
import { useAppContext } from '../../context/AppContext';
import { CardStepSa, Grid } from 'hult-toolkit';
import { IStep } from '../../data/types';
import { IStepListContainer } from './stepListContainer.types';
import { StepList } from './stepList';
import { checkCompletion, activePastPartialStage, parseSteps } from './helpers';
import { useGetRequirementsV2Query } from '../../graphql';
import { ICompletedSteps } from '../../context/AppContext/appContext.types';

const StepListContainerLoader: React.FC = () => (
  <Grid column={3}>
    <CardStepSa state="loading" title="Loading" />
    <CardStepSa state="loading" title="Loading" />
    <CardStepSa state="loading" title="Loading" />
  </Grid>
);

export const StepListContainer: React.FC<IStepListContainer> = ({
  steps,
  locked_steps,
  grid,
}) => {
  const { appStage, subStage, completedStepsDetails, selectedApplication } = useAppContext();
  const navigate = useNavigate();
  const [isPayer, setIsPayer] = useState<Boolean>(false)
  const [requirements, setRequirements] = useState<{
    completedSteps: IStep[];
    nonCompletedSteps: IStep[];
  }>({ completedSteps: [], nonCompletedSteps: [] });

  const isPartial =
    appStage?.toLowerCase() === 'partial application' &&
    completedStepsDetails?.submitApplicationCompleted;

  const skipFetchRequirements = !(
    selectedApplication &&
    (isPartial || activePastPartialStage(appStage))
  );

  const { loading, error } = useGetRequirementsV2Query({
    variables: {
      guid: selectedApplication || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: skipFetchRequirements,
    onCompleted({ getApplicationRequirements }) {
      if (getApplicationRequirements) {
        const reqNames: string[] = getApplicationRequirements.flatMap(
          r => r?.step as string
        );

        reqNames.push('decfinance');

        const clearedSteps = steps.filter(s => {
          const idx = reqNames.indexOf(s.content.step_name);
          if (idx > -1) {
            if (
              s.content.step_name === 'decfinance' &&
              !getApplicationRequirements[idx]?.showOnApp
            ) {
              return false;
            }
          }
          return true;
        });

        setRequirements(
          parseSteps(
            clearedSteps,
            completedStepsDetails as ICompletedSteps
          )
        );
      }
    },
    onError(err) {
      console.log("useGetRequirementsV2Query", err);
    },
  });

  useEffect(() => {
    if (skipFetchRequirements) {
      setRequirements(
        parseSteps(steps, completedStepsDetails as ICompletedSteps)
      );
    }
  }, []);

  useEffect(() => {
    setRequirements(parseSteps(steps, completedStepsDetails));
}, [completedStepsDetails, steps]);

  function handleClick(item?: IStep) {
    if (item) {
      const { slug, content } = item;
      if (content?.payment_type === 'tuition_fee') {
        navigate({
          pathname: `/billing`,
        });
      } else {
        navigate({
          pathname: `/question`,
          search: `?step=${slug}`,
        });
      }
    }
  }


  if (loading) return <StepListContainerLoader />;
    

  return (
    <StepList
      completedSteps={requirements.completedSteps}
      nonCompletedSteps={requirements.nonCompletedSteps}
      locked_steps={locked_steps}
      completionState={checkCompletion(appStage, subStage)}
      grid={grid}
      onClick={handleClick}
    />
  );
};
