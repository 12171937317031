import {FC, useState, useEffect, useRef} from 'react';
import {ITermsAndConditions} from './TermsAndConditions.types';
import {
  StyledTermsBox,
  StyledBottom,
  StyledPagination,
  StyledDocument,
  StyledPaginationButton,
  StyledTermsBoxText,
} from './terms.styles';
import {Page, Document, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {Button, Icon} from 'hult-toolkit';
import { StyledText } from '../../QuestionForm/questionForm.styles';
import {PageLoader} from '../../ui/pageLoader';

//  initialize a worker in the browser
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const TermsAndConditionsUI: FC<ITermsAndConditions> = ({
  handleChange,
  pdf,
  loading = false,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current !== null) {
      const {offsetWidth} = ref.current;
      setWidth(offsetWidth);
    }
  }, [ref.current]);

  function onDocumentLoadSuccess({numPages}: {numPages: number}): void {
    setNumPages(numPages);
  }

  function goToNextPage() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  function goToPreviousPage() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  const changeUrlDomain = (url: any) =>
    url.replace(
      'https://a.storyblok.com/',
      'https://s3.amazonaws.com/a.storyblok.com/'
    );

  return (
    <>
      <StyledText loading={loading} />
      <StyledDocument ref={ref}>
        <Document
          file={changeUrlDomain(pdf?.filename)}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<PageLoader />}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            loading={''}
          />
        </Document>
      </StyledDocument>
      <StyledBottom>
        <StyledPagination>
          <StyledPaginationButton
            onClick={goToPreviousPage}
            pagination={pageNumber <= 1}
            disabled={pageNumber <= 1}
          >
            <Icon icon="chevron-left" />
          </StyledPaginationButton>
          <p>
            {pageNumber} / {numPages}
          </p>
          <StyledPaginationButton
            onClick={goToNextPage}
            pagination={pageNumber >= numPages!}
            disabled={pageNumber >= numPages!}
          >
            <Icon icon="chevron-right" />
          </StyledPaginationButton>
        </StyledPagination>

        <StyledTermsBox>
          <StyledTermsBoxText>
            <p>
              I agree to the Terms & Conditions and the Refund and Cancellation
              policy.
            </p>
            <p>
              Click{' '}
              <a href={pdf?.filename} target="_blank">
                here
              </a>{' '}
              to download the Terms & Conditions.
            </p>
          </StyledTermsBoxText>
          <Button
            label={loading ? `Loading` : `Accept`}
            icon={
              loading
                ? {
                  animate: 'spin',
                  library: 'solid',
                  name: 'spinner',
                }
                : undefined
            }
            Wrapper="button"
            variant={loading ? 'disabled' : 'primary'}
            WrapperProps={{
              onClick: () => {
                handleChange({
                  value: true,
                  valid: true,
                });
              },
            }}
          />
        </StyledTermsBox>
      </StyledBottom>
    </>
  );
};
