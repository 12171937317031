import {AddressCard} from '../../ui/address';
import {IAddressObj} from './address.types';
import {QuestionFormButton} from '../../QuestionForm/questionFormButton';
import { useEffect } from 'react';

interface SavedAddressProps {
  addressObj: IAddressObj;
  buttonProps: any;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddressSelect: (address: IAddressObj) => void;
}

export const SavedAddress = ({
  addressObj,
  buttonProps,
  setEditMode,
  handleAddressSelect,
}: SavedAddressProps) => {

  // Set edit state to true to change view  
  const handleEdit = () => {
    setEditMode(true);
  };

  // Select the address when addressObj updates
  useEffect(() => {
    handleAddressSelect(addressObj);
  }, [addressObj]);

  return (
    <>
      <AddressCard
        title={'Saved'}
        onClick={handleEdit}
        address={{
          address1: addressObj.address1,
          address2: addressObj.address2,
          city: addressObj.city,
          postalCode: addressObj.postalCode,
          addressState: addressObj.addressState,
          countryName: addressObj.countryName,
        }}
        allowEdit
      />
      <QuestionFormButton {...buttonProps} onClick={buttonProps.onClick} />
    </>
  );
};
