import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext, useAppDispatch } from '../../context/AppContext';
import { useStepContext } from '../../context/StepContext';
import { useQuestionContext } from '../../context/QuestionContext';
import { StyledQuestionWrapper } from '../QuestionForm/questionForm.styles';
import { ApplicationStep, useCompleteApplicationStepMutation, useUpdateApplicationStepMutation } from '../../graphql';
import { LoadingInput } from '../QuestionInputs/TextInput/TextInput.helpers';
import { QuestionBackButton } from '../BackButton';
import { SingleQuestion } from '../SingleQuestion';
import { renderUpdateObj } from './QuestionList.helpers';
import { getSavedValue, renderStepName, updateQuestions } from '../Steps/steps.helpers';
import { ISbStoryData } from '@storyblok/react';
import { ISbQuestion } from '../../data/types';
import { ICompletedSteps } from '../../context/AppContext/appContext.types';
import { IQuestionsList } from './QuestionsList.types';

const QuestionsList = ({
  loading,
  step_name,
}: IQuestionsList) => {
  const dispatch = useAppDispatch()

  const params = new URLSearchParams(location.search);
  const navigate = useNavigate()

  const { stepState, stepDispatch } = useStepContext();
  const { selectedApplication, program, completedStepsDetails } = useAppContext();
  const { questionState, questionDispatch } = useQuestionContext();

  const { stepCompleted } = questionState;

  const [saving, setSaving] = useState(false);
  const [updateApplicationStepMutation] = useUpdateApplicationStepMutation();
  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation();

  const questionParam = params.get('q')

  const completionUrl = questionState.multiStepMode ? `/question?step=${questionState.stepCategory}` : '/'

  async function handleStepComplete() {

    await completeApplicationStepMutation({
      variables: {
        guid: selectedApplication || '',
        step: step_name || '',
      },
      onCompleted() {

        dispatch({
          type: 'complete-step',
          payload: {
            guid: selectedApplication || '',
            step: step_name,
          },
        });

        dispatch({
          type: 'ga-event',
          payload: {
            event: 'stdapp_sectionComplete',
            content: `(3.0) - ${step_name} - ${program}`,
          },
        });

        navigate(completionUrl)
      },
      onError(error) {
        console.log('completeApplicationStepMutation error', error)
      }
    })

  }

  const nextQuestion = () => {
    const currentIndex = questionState.questionsOrder.indexOf(questionParam || '')
    const nextIndex = currentIndex + 1

    const newQuestionSlug = questionState.questionsOrder[nextIndex]
    const newQuestionMapping = questionState.questionMappingList[nextIndex]
    const savedValue = getSavedValue(stepState, newQuestionMapping) ?? ''

    questionDispatch({
      type: 'update-question-context',
      payload: {
        questionMapping: newQuestionMapping,
        questionSlug: newQuestionSlug,
        firstQuestion: false,
        questionIndex: nextIndex,
        lastQuestion: nextIndex === questionState.questionsOrder.length - 1,
        submitObj: {
          name: '',
          value: '',
          valid: false,
          qMapping: newQuestionMapping,
          savedValue,
          updated: false
        }
      }
    });

    const stepUrl = questionState.multiStepMode ? questionState.stepCategory : questionState.stepName
    const multiStepUrl = questionState.multiStepMode ? `editing=${questionState.stepName}&` : ''

    navigate(`/question?step=${stepUrl}&${multiStepUrl}q=${newQuestionSlug}`)
  }

  async function completeUpdate(resData?: ApplicationStep) {

    const newQuestions = updateQuestions(questionState.questions, resData || stepState)
    const newQuestionsOrder = newQuestions?.map((question: ISbStoryData<ISbQuestion>) => question?.slug)


    const currentIndex = newQuestionsOrder.indexOf(questionParam || '');
    const nextIndex = currentIndex + 1;

    const newQuestionMappingList = newQuestions?.map((question: ISbStoryData<ISbQuestion>) => question?.content?.question_mapping)

    const newQuestionSlug = newQuestions[nextIndex].slug
    const nextQuestionMapping = newQuestionMappingList[nextIndex] || ''

    questionDispatch({
      type: 'update-question-context',
      payload: {
        questionsOrder: newQuestionsOrder,
        questionMappingList: newQuestionMappingList,
        visibleQuestions: newQuestions,
        questionMapping: nextQuestionMapping,
        questionSlug: newQuestionSlug,
        firstQuestion: false,
        questionIndex: nextIndex,
        lastQuestion: nextIndex === questionState.questionsOrder.length - 1,
        submitObj: {
          name: '',
          value: '',
          valid: false,
          qMapping: nextQuestionMapping,
          savedValue: stepState?.[nextQuestionMapping] ?? '',
          updated: false
        }
      }
    })

    const stepUrl = questionState.multiStepMode ? questionState.stepCategory : questionState.stepName
    const multiStepUrl = questionState.multiStepMode ? `editing=${questionState.stepName}&` : ''

    navigate(`/question?step=${stepUrl}&${multiStepUrl}q=${newQuestionSlug}`)
  }

  useEffect(() => {
    const questionQuery = params.get('q') || ''
    const stepName = renderStepName(step_name || '', questionQuery)
    const stepKey = `${stepName}Completed`
    const currentStepCompleted = completedStepsDetails?.[stepKey as keyof ICompletedSteps]

    if (stepCompleted && currentStepCompleted) {
      navigate('/')
    }
  }, [stepCompleted, completedStepsDetails])

  const submitValue = async () => {

    setSaving(true)

    const qSubObj = questionState.submitObj

    const updateVal = renderUpdateObj(qSubObj, {
      [qSubObj.qMapping]: qSubObj.value
    })

    await updateApplicationStepMutation({
      variables: {
        guid: selectedApplication || '',
        step: step_name || '',
        ...updateVal
      },
      onCompleted({ updateApplicationStep }) {

        if (questionState.lastQuestion) {
          handleStepComplete()
        } else {
          if (updateApplicationStep) {
            const newState = {
              ...stepState,
              ...updateVal
            }

            stepDispatch({
              type: 'update-step-context',
              payload: newState,
            })

            completeUpdate(newState)
          }
        }

        setSaving(false)
      },
      refetchQueries: questionState.multiStepMode ? ['GetApplicationMultiStep'] : [],
      onError(error) {
        console.error('updateApplicationStepMutation error', error);
      },
    });
  }

  const submitClick = async (qMap: string) => {
    if (questionState.submitObj.updated) {
      await submitValue()
    } else {
      if (questionState.lastQuestion) {
        handleStepComplete()
      } else {
        nextQuestion()
      }
    }
  }

  if (loading) {
    return <LoadingInput />
  }

  if (!questionState?.visibleQuestions.length) {
    return <></>
  }

  return (
    <StyledQuestionWrapper>
      <QuestionBackButton loading={loading} />
      {questionState?.visibleQuestions?.map((q) => {

        const currentQ = q?.slug === questionState.questionSlug;
        const qContentMapping = q?.content?.question_mapping || '';
        const defaultVal = stepState?.[qContentMapping];

        return currentQ ? (
          <SingleQuestion
            defaultVal={defaultVal}
            key={q.uuid}
            question={q}
            saving={saving}
            submitClick={() => submitClick(qContentMapping)}
          />
        ) : <></>
      })}
    </StyledQuestionWrapper>
  )
};

export default QuestionsList