export function transformMarkdown(text: string) {
    const markdownParser = (text: string) => {
      const toHTML = text
        .replace(/\*\*(.*?)\*\*/gim, '<strong>$1</strong>') 
        .replace(/\s\s/g, '<br>')
        .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>');
      return toHTML.trim();
    };
  
    return markdownParser(text);
  }