import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, DocumentUploadSa, PopUp } from 'hult-toolkit';
import { useStoryblok } from '@storyblok/react';
import {
  useCompleteApplicationStepMutation,
  useCreateApplicationMultiStepMutation,
  useGetApplicationPayersQuery,
  useUpdateApplicationPayerMutation
} from '../../../graphql';
import { StyledPayerManagement, StyledPopUp, StyledPopUpContent } from './payerManagement.styles';
import { useAppContext, useAppDispatch } from '../../../context/AppContext';
import { CheckboxContentType, IPayerInfo } from './payerManagement.types';

export const PayerManagement: React.FC = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [payerIndex, setPayerIndex] = useState(-1);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const navigate = useNavigate();
  const {selectedApplication} = useAppContext();
  const [createApplicationMultiStepMutation] = useCreateApplicationMultiStepMutation();
  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation()
  const dispatch = useAppDispatch();
  const [updateApplicationPayer] = useUpdateApplicationPayerMutation();
  const [records, setRecords] = useState<IPayerInfo[]>([]);
  const [accessLoading, setAccessLoading] = useState<Boolean>(false)

  const { content } = useStoryblok('sa3-1/payer-form', {
    version: (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') || 'published',
  });

  let checkboxContent: CheckboxContentType | undefined;

  if (content && content.Content) {
    content.Content.forEach((c: any) => {
      if (c.component === 'add-payer') {
        checkboxContent = c;
      }
    });
  }

  const { refetch } = useGetApplicationPayersQuery({
    variables: {
      guid: selectedApplication || "",
    },
    fetchPolicy: 'cache-and-network',
    onCompleted({ getApplicationPayers }) {
      setRecords(getApplicationPayers as IPayerInfo[] || []);
      setAccessLoading(false)
    },
    onError(error) {
      console.log("useGetApplicationPayersQuery error", { error })
    }
  });

  const completeStep = async (step: string | undefined) => {
    try {
      await completeApplicationStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: step || "",
        },
        onCompleted() {
          dispatch({
            type: 'complete-step',
            payload: {
              guid: selectedApplication || '',
              step: step
            },
          });
        },
        onError(error) {
          console.error("completeApplicationStepMutation error", error);
        }
      });

      setAccessLoading(false);
      refetch();
    } catch (err) {
      console.log(err);
      setAccessLoading(false);
    }
  };

  const handleNewPayer = () => {
    if (selectedApplication) {
      createApplicationMultiStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: "payer",
        },
        onCompleted({ createApplicationMultiStep }) {
          navigate({
            pathname: `/payer-form`,
            search: `editing=${createApplicationMultiStep?.stepId}`,
          });
        },
        onError(error) {
          console.error("createApplicationMultiStepMutation error", error);
        },
      });
    }
  };

  const handleAccess = (index: number) => {
    const payerToUpdate = records[index]; // Get the payer record to update
    if (selectedApplication && payerToUpdate.step) {
      updateApplicationPayer({
        variables: {
          guid: selectedApplication,
          step: payerToUpdate.step,
          payerEmail: payerToUpdate.payerEmail,
          revokeAccess: false,
        },
        onCompleted() {
          setShowPopUp(false)
          completeStep(payerToUpdate.step);
        },
        onError(error) {
          console.error("updateApplicationPayer error", { error })
        }
      })
    }
  };

  const handlePopUp = (index: number) => {
    setCheckboxChecked(false);
    setPayerIndex(index);
    setAccessLoading(true)
    const payerToUpdate = records[index]; // Get the payer record to update

    if (selectedApplication && payerToUpdate.step && !payerToUpdate.revokeAccess) {
      updateApplicationPayer({
        variables: {
          guid: selectedApplication,
          step: payerToUpdate.step,
          payerEmail: payerToUpdate.payerEmail,
          revokeAccess: true,
        },
        onCompleted() {
          completeStep(payerToUpdate.step);
        },
        onError(error) {
          console.error("updateApplicationPayer error", { error })
        }
      })
    }
    else {
      setShowPopUp(true)
    }
  };

  return (
    <StyledPayerManagement>
      {accessLoading ? (
        <DocumentUploadSa
          documents={[]}
          placeholder="Loading"
          title="Authorized users"
        />
      ) : (
        <DocumentUploadSa
          documents={
            records.length > 0
              ? records
                // .filter(data => data.payerFirstName !== null) // Filter out records with null payerFirstName
                .map((data, index) => ({
                  // visible: data.payerFirstName !== null,
                  buttons: data.payerFirstName === null ? [
                    {
                      label: 'Incomplete',
                      variant: 'disabled',
                      size: 'small',
                    },
                  ] : [
                    {
                      WrapperProps: {
                        onClick: () => handlePopUp(index),
                      },
                      label: data.revokeAccess
                        ? 'Grant Access'
                        : 'Revoke Access',
                      variant: 'tertiary',
                      size: 'small',
                    },
                  ],
                  document_data: [
                    {
                      data: data.payerFirstName === null ? `Incomplete` : `${data.payerFirstName} ${data.payerLastName}`,
                      label: 'Name',
                    },
                    {
                      data: data.payerFirstName === null ? `Incomplete` : data.payerRelationship,
                      label: 'Relationship',
                    },
                    {
                      data: data.payerFirstName === null ? `Incomplete` : data.revokeAccess
                        ? 'Access Revoked'
                        : 'Access Granted',
                      label: 'Status',
                    },
                  ],
                }))
              : []
          }
          placeholder="No authorized users added yet"
          title="Authorized users"
        />
      )}
      <Button
        label="Add new authorized user"
        icon={{
          animate: '',
          library: 'solid',
          name: 'arrow-right',
        }}
        Wrapper="button"
        variant="primary"
        WrapperProps={{
          onClick: handleNewPayer,
        }}
      />

      {showPopUp && (
        <StyledPopUp>
          <StyledPopUpContent>
            <PopUp
              button={[
                {
                  label: 'Confirm',
                  variant: checkboxChecked ? 'primary' : 'disabled',
                  WrapperProps: {
                    onClick: () => handleAccess(payerIndex),
                  },
                },
              ]}
              desc={checkboxContent?.consent_desc}
              title={checkboxContent?.consent_title}
              handleCheckbox={setCheckboxChecked}
              onClose={() => setShowPopUp(false)}
            />
          </StyledPopUpContent>
        </StyledPopUp>
      )}
    </StyledPayerManagement>
  );
};

export default PayerManagement;