import { useNavigate } from 'react-router-dom';
import { IBackLink, IQuestionBackButton, ISubmitButton } from './backButton.types';
import { useQuery } from '../../utils';
import { ISbStoryData } from '@storyblok/react';
import { ISbQuestion } from '../../data/types';
import { Button } from 'hult-toolkit';
import { FC } from 'react';
import { useQuestionContext } from '../../context/QuestionContext';
import { useStepContext } from '../../context/StepContext';
import { getSavedValue } from '../Steps/steps.helpers';

export const LoadingBackButton: FC = () => (
  <Button
    icon={{
      animate: 'spin',
      library: 'solid',
      name: 'spinner',
    }}
    label="Loading"
    size="small"
    variant="disabled"
  />
);

export const SubmitButton: FC<ISubmitButton> = ({ onClick }) => (
  <Button
    label="Back"
    icon={{
      animate: '',
      library: 'solid',
      name: 'arrow-left',
    }}
    icon_reverse='true'
    size="small"
    Wrapper="button"
    variant={'ghost'}
    WrapperProps={{
      onClick: () => {
        onClick()
      },
    }}
  />
)

export const QuestionProgramBackButton: FC<IQuestionBackButton> = ({
  loading = false,
}) => {
  const params = new URLSearchParams(location.search);
  const questionParam = params.get('q')
  const navigate = useNavigate();
  const { stepState } = useStepContext()
  const { questionState, questionDispatch } = useQuestionContext()

  const { firstQuestion, questionsOrder, questionMappingList } = questionState

  const navBack = () => {

    if (firstQuestion) {
      navigate('/')
    } else {
      const currentIndex = questionsOrder.indexOf(questionParam || '')
      const prevIndex = currentIndex - 1
      const prevQuestionSlug = questionsOrder[prevIndex]
      const prevQuestionMapping = questionMappingList[prevIndex]

      const savedValue = getSavedValue(stepState, prevQuestionMapping) ?? ''

      questionDispatch({
        type: 'update-question-context',
        payload: {
          questionMapping: prevQuestionMapping,
          questionSlug: prevQuestionSlug,
          firstQuestion: prevIndex === 0,
          questionIndex: prevIndex,
          lastQuestion: false,
          submitObj: {
            ...questionState.submitObj,
            savedValue: savedValue,
            qMapping: prevQuestionMapping,
            updated: false,
            valid: false,
          }
        }
      });

      navigate(`/question?step=program&q=${prevQuestionSlug}`)
    }
  }

  return (
    loading ? <LoadingBackButton /> : <SubmitButton onClick={navBack} />
  );

}

export const QuestionBackButton: FC<IQuestionBackButton> = ({
  loading = false,
}) => {

  const { stepState } = useStepContext()
  const { questionState, questionDispatch } = useQuestionContext()

  const {
    firstQuestion,
    questionSlug,
    questionsOrder,
    questionMappingList,
    multiStepMode,
    stepCategory,
    stepName
  } = questionState;

  const navigate = useNavigate();

  const navBack = () => {

    if (firstQuestion) {
      if (multiStepMode) {
        navigate(`/question?step=${stepCategory}`)
      } else {
        navigate('/')
      }
    } else {

      const currentIndex = questionsOrder.indexOf(questionSlug)
      const prevIndex = currentIndex - 1

      const newQuestionSlug = questionsOrder[prevIndex]
      const newQuestionMapping = questionMappingList[prevIndex]
      const savedValue = getSavedValue(stepState, newQuestionMapping) ?? ''

      questionDispatch({
        type: 'update-question-context',
        payload: {
          questionMapping: newQuestionMapping,
          questionSlug: newQuestionSlug,
          firstQuestion: prevIndex === 0,
          questionIndex: prevIndex,
          lastQuestion: false,
          submitObj: {
            ...questionState.submitObj,
            savedValue,
            value: savedValue ?? '',
            qMapping: newQuestionMapping,
            updated: false,
            valid: false,
          }
        }
      });

      const stepNameUrl = () => {
        if (multiStepMode) {
          return `${stepCategory}&editing=${stepName}`
        }

        return stepName
      }

      navigate(`/question?step=${stepNameUrl()}&q=${newQuestionSlug}`)
    }
  }

  return (
    loading ? <LoadingBackButton /> : <SubmitButton onClick={navBack} />
  );
};

export const BackButton: React.FC<IBackLink> = ({
  loading = false,
  questions,
  stepRecord,
}) => {
  const navigate = useNavigate();
  const query = useQuery();

  function backNav(questions: ISbStoryData<ISbQuestion>[] | undefined) {
    const stepType = query.get('step');
    const editingIndex = query.get('editing');
    const qIndex = query.get('q');
    const prevIndex = Number(qIndex) - 1;
    const stepParam = `/question?step=${stepType}`;

    const editingParam =
      editingIndex !== null ? `&editing=${editingIndex}` : '';
    let newIndex = Number(qIndex) - 1;

    if (questions) {
      const prevQuestionContent = questions[prevIndex]?.content;
      const isHideCondition = prevQuestionContent?.dependent_type === 'hide';
      const stepRecordVal =
        stepRecord?.[prevQuestionContent?.question_mapping || ''];

      if (
        isHideCondition &&
        prevQuestionContent?.dependent_value === stepRecordVal
      ) {
        newIndex -= 1;
      }
    }

    const questionParam = `&q=${newIndex}`;
    const prevQuestionUrl = `${stepParam}${editingParam}${questionParam}`;

    switch (stepType) {
      case 'education':
      case 'employment':
        if (qIndex === null) {
          navigate('/');
        } else {
          navigate(qIndex === '0' ? stepParam : prevQuestionUrl);
        }
        return;
      case 'terms-and-conditions':
        navigate('/');
        return;
      default:
        navigate(
          qIndex === '0' || qIndex === null
            ? '/'
            : `${stepParam}${editingParam}${questionParam}`
        );
        return;
    }
  }

  return loading ? (
    <LoadingBackButton />
  ) : (
    <Button
      label="Back"
      icon={{
        animate: '',
        library: 'solid',
        name: 'arrow-left',
      }}
      icon_reverse='true'
      size="small"
      Wrapper="button"
      variant={'ghost'}
      WrapperProps={{
        onClick: () => {
          backNav(questions);
        },
      }}
    />
  );
};
