import {createRoot} from 'react-dom/client';
import App from './App';

// eslint-disable-next-line no-restricted-globals
if (self !== top) {
  window.parent.postMessage('signUp3', '*');
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
