import React, {useEffect, useRef, useState} from 'react';
import {
  StyledPayerTermsContainer,
} from './payerTerms.styles';
import { useStoryblok } from '@storyblok/react';
import { PageLoader } from '../ui/pageLoader';
import { transformMarkdown } from '../../utils/markdownHelper';
import { Button } from 'hult-toolkit';
import {useUpdateApplicationPayerPortalTermsMutation} from '../../graphql';
import { useAppContext } from '../../context/AppContext';

export const PayerTermsCmp: React.FC = () => {
  const payerDescriptionRef = useRef<HTMLParagraphElement | null>(null);
  const story = useStoryblok(`sa3-1/config/payer-portal-questions/payer-portal-terms`, {
    version: (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') || 'published',
  });
  let payerDescriptionMarkdown = '';
  const {selectedApplication } = useAppContext();
  const [updateApplicationPayerPortalTerms] = useUpdateApplicationPayerPortalTermsMutation();
  const [loading, setLoading] = useState<Boolean>(false)

  useEffect(() => {
    if (story.content && story.content.description && payerDescriptionRef.current) {
      payerDescriptionMarkdown = transformMarkdown(story.content.description);
      payerDescriptionRef.current.innerHTML = payerDescriptionMarkdown;
    }
  }, [story.content]);

  if (!story.content) {
    return <PageLoader />;
  }

  function handleTermsAccept(){
    setLoading(true)
    if (selectedApplication){
      updateApplicationPayerPortalTerms({
        variables: {
          guid: selectedApplication,
        },
        refetchQueries: ['GetUserContext'],
        onCompleted(){
          setLoading(false)
        },
        onError(error){
          console.error("updateApplicationPayerPortalTerms error", {error})
        }
      });
    }
  }

  return (
    <StyledPayerTermsContainer>
      <h1>{story.content.display_title}</h1>
      {story.content.description && (
        <div ref={payerDescriptionRef} />
      )}
      <br />
      <Button 
        Wrapper='button'
        WrapperProps={{
          type: 'button',
          onClick: handleTermsAccept
        }}
        variant={loading ? "disabled" : "primary"}
        icon={
          loading ? {
          animate: "spin",
          library: 'solid',
          name: "spinner",
        } : undefined}
        label={loading ? `Loading` : `I accept`}
      />
    </StyledPayerTermsContainer>
  );
};