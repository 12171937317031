import React from 'react';
import {useAppContext} from '../../context/AppContext';
import './account.scss';

export const AccountInfo: React.FC = () => {
  const {user} = useAppContext();

  return (
    <div className={'account'}>
      <h1>Your Information</h1>
      {user && (
        <div className={'account__container'}>
          {user.studentId && (
            <div>
              <p className={'account__container__subtitle'}>Student ID</p>
              <p className={'account__container__data'}>{user.studentId}</p>
            </div>
          )}
          {(user.firstName || user.lastName) && (
            <div>
              <p className={'account__container__subtitle'}>Name</p>
              <p className={'account__container__data'}>{user.firstName} {user.lastName}</p>
            </div>
          )}
          {user.email && (
            <div>
              <p className={'account__container__subtitle'}>Email</p>
              <p className={'account__container__data'}>{user.email}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
