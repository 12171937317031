import React from 'react';
import {StyledPageLoaderContainer, StyledPageLoader} from './pageLoader.styles';

export const PageLoader: React.FC = () => {
  return (
    <StyledPageLoaderContainer>
      <StyledPageLoader>
        <div></div>
        <div></div>
      </StyledPageLoader>
    </StyledPageLoaderContainer>
  );
};
