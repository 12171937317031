import React from 'react';
import {IStepGroup} from './stepGroup.types';
import {StyledStepGroup, StyledStepGroupGrid} from './stepGroup.styles';

export const StepGroup: React.FC<IStepGroup> = ({title, children}) => {
  return (
    <StyledStepGroup data-testid="step-group">
      {title && (
        <header>
          <p>{title}</p>
        </header>
      )}
      <StyledStepGroupGrid>{children}</StyledStepGroupGrid>
    </StyledStepGroup>
  );
};
