import styled from 'styled-components';

export const StyledQuestionWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const StyledQuestion = styled.div``;

export const StyledDocumentUpload = styled.div`
  margin: 16px 0;

  &:nth-of-type(2) {
    margin-top: 0;
  }
`;

export const StyledAlertWrapper = styled.div`
  margin: 16px 0;
`;

export const StyledPayment = styled.div`
display: flex;
flex-direction: column;
gap: 24px;

  h1 {
    margin: 0;
  }
`;
