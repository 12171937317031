import React from 'react';
import {INavigationTab} from './navigationTab.types';
import {StyledNavigationTab, StyledBorderBottom} from './navigationTab.styles';

export const NavigationTab: React.FC<INavigationTab> = ({
  title,
  onClick,
  active,
}) => {
  return (
    <StyledNavigationTab active={active}>
      <div onClick={onClick}>
        <p>{title}</p>
      </div>
      <StyledBorderBottom active={active}/>
    </StyledNavigationTab>
  );
};
