import styled from 'styled-components';

export interface IStyledTerms {
  pagination: boolean;
}

export const StyledTermsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const StyledTermsBoxText = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;

  @media (max-width: 768px) {
    gap: 8px;
  }
  
  & a {
    font-family: ${props => props.theme.fonts.circularMedium};
  }
  `;

export const StyledTermsEmbed = styled.object`
  width: 100%;
  display: block;
  margin-top: 20px;
`;

export const StyledBottom = styled.div`
  background: white;
  padding: 24px;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;
export const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
export const StyledPaginationButton = styled.button<IStyledTerms>`
  background: white;
  border: none;
  padding: 12px 16px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background: ${props => props.theme.colors.black5};
  }
  :first-of-type {
    ${props =>
      props.pagination &&
      `
    :hover {
      background: none;
      cursor: auto;
    }`}
  }
  :last-of-type {
    ${props =>
      props.pagination &&
      `
    :hover {
      background: none;
      cursor: auto;
    }`}
  }
  @media (max-width: 768px) {
    padding: 8px 12px;
  }
`;

export const StyledDocument = styled.div`
  display: flex;
  justify-content: center;

  & .react-pdf__Document {
    width: 100% !important;
    & .react-pdf__Page {
      background: ${props => props.theme.colors.black20} !important;
      width: 100% !important;
      border-radius: 4px 4px 0 0;
      padding: 8px;

      @media (max-width: 768px) {
        padding: 4px;
      }

      & canvas {
        width: 100% !important;
        max-width: 65% !important;
        height: auto !important;
        margin: 0 auto !important;
        @media (max-width: 768px) {
          max-width: 100% !important;
        }
      }
      & .react-pdf__Page__textContent {
        width: 100% !important;
      }
    }
  }
`;
