import {IAddressObj} from './address.types';
import {AddressCard} from '../../ui/address';
import {StyledAddressContent} from './address.styles';
import {QuestionFormButton} from '../../QuestionForm/questionFormButton';
import {useEffect} from 'react';
import {setDataLayer} from '../../../services/ga';
import {Button} from 'hult-toolkit';

interface AddressConfirmationProps {
  addressObj: IAddressObj;
  suggestedAddress: IAddressObj;
  selectedAddress: IAddressObj;
  buttonProps: any;
  handleAddressSelect: (address: IAddressObj) => void;
}

export const AddressConfirmation = ({
  addressObj,
  suggestedAddress,
  selectedAddress,
  buttonProps,
  handleAddressSelect,
}: AddressConfirmationProps) => {
  // Handle selection of google suggestion
  useEffect(() => {
    if (suggestedAddress) {
      handleAddressSelect(suggestedAddress);
      setDataLayer({
        event: 'stdapp_address',
        content: '(3.0) - Selection Suggested',
      });
    } else {
      handleAddressSelect(addressObj);
      setDataLayer({
        event: 'stdapp_address',
        content: '(3.0) - Selection Current',
      });
    }
  }, [suggestedAddress, addressObj]);

  // If the address matches the google suggested address: save and continue
  if (JSON.stringify(addressObj) === JSON.stringify(suggestedAddress)) {
    buttonProps.onClick();
    return (
      <Button
        Wrapper="button"
        label={'Saving address'}
        icon={{
          animate: 'spin',
          library: 'solid',
          name: 'spinner',
        }}
        variant={'disabled'}
        hidden={true}
      />
    );
  } else {
    return (
      <>
        <StyledAddressContent>
          <AddressCard
            title={'You entered'}
            onClick={() => handleAddressSelect(addressObj)}
            address={{
              address1: addressObj.address1,
              address2: addressObj.address2,
              city: addressObj.city,
              postalCode: addressObj.postalCode,
              addressState: addressObj.addressState,
              countryName: addressObj.countryName,
            }}
            active={selectedAddress === addressObj}
          />
          <AddressCard
            title={'Suggested'}
            onClick={() => handleAddressSelect(suggestedAddress)}
            address={{
              address1: suggestedAddress.address1,
              address2: suggestedAddress.address2,
              city: suggestedAddress.city,
              postalCode: suggestedAddress.postalCode,
              addressState: suggestedAddress.addressState,
              countryName: suggestedAddress.countryName,
            }}
            active={selectedAddress === suggestedAddress}
          />
        </StyledAddressContent>
        <QuestionFormButton {...buttonProps} onClick={buttonProps.onClick} />
      </>
    );
  }
};
