import {IBillingDocument} from './billing.types';

export function getUTCDate(date: string) {
  return Date.UTC(
    parseInt(date.split('-')[0]),
    parseInt(date.split('-')[1]) - 1,
    parseInt(date.split('-')[2])
  );
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export function dateFormat(date: string) {
  return new Date(getUTCDate(date)).toLocaleString('default', dateOptions);
}
export function addCommasToNumber(value: string) {
  const [integerPart, decimalPart] = String(value).split('.');

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );

  const formattedValue = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  return formattedValue;
}

export function addDecimalIfNeeded(value: string | number):string {
  if (!value) {
    return '0';
  } else {
    const floatValue = parseFloat(value.toString());

    if (Number.isInteger(floatValue)) {
      return addCommasToNumber(value.toString());
    } else {
      const formattedValue = floatValue.toFixed(2);
      return addCommasToNumber(formattedValue);
    }
  }
}

export function formatAndToggleSignIfNeeded(value: string | number): string {
  if (!value) {
    return '0';
  } else {
    const numericValue = parseFloat(value.toString());
    if (!isNaN(numericValue)) {
      const signedValue = -numericValue;

      if (Number.isInteger(numericValue)) {
        return addCommasToNumber(signedValue.toString());
      } else {
        const formattedValue = signedValue.toFixed(2);
        return addCommasToNumber(formattedValue);
      }
    }
    return value.toString();
  }
}

export function filterBillingDocuments(billingDocuments: IBillingDocument[]): {
  pastdue: IBillingDocument[];
  duetoday: IBillingDocument[];
  upcoming: IBillingDocument[];
  paid: IBillingDocument[];
} {
  let pastdue: IBillingDocument[] = [];
  let duetoday: IBillingDocument[] = [];
  let upcoming: IBillingDocument[] = [];
  let paid: IBillingDocument[] = [];

  const currentDate = new Date();

  billingDocuments.forEach((billingDoc: IBillingDocument) => {
    if (billingDoc.documentDueDate) {
      const dueDateUTC = new Date(getUTCDate(billingDoc.documentDueDate));

      if (parseInt(billingDoc.documentOutstandingValue) <= 0) {
        duetoday = duetoday.filter(item => item !== billingDoc);
        pastdue = pastdue.filter(item => item !== billingDoc);
        upcoming = upcoming.filter(item => item !== billingDoc);
        paid.push(billingDoc);
      } else {
        duetoday = duetoday.filter(item => item !== billingDoc);
        pastdue = pastdue.filter(item => item !== billingDoc);
        upcoming = upcoming.filter(item => item !== billingDoc);
        if (dueDateUTC.toDateString() === currentDate.toDateString()) {
          duetoday.push(billingDoc);
        } else if (dueDateUTC < currentDate) {
          pastdue.push(billingDoc);
        } else {
          upcoming.push(billingDoc);
        }
      }
    }
  });

  return {pastdue, duetoday, upcoming, paid};
}
