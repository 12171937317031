import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppContext } from '../../../context/AppContext';
import { IStep } from '../../../data/types';
import { useCompleteApplicationStepMutation, useUpdateApplicationStepMutation } from '../../../graphql';
import { TermsAndConditionsUI } from './TermsAndConditionsUi';

export const TermsAndConditions: FC<IStep> = ({
  step_name,
  questions = [],
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation()
  const [updateApplicationStepMutation] = useUpdateApplicationStepMutation()

  const { program, selectedApplication, completedStepsDetails } = useAppContext();

  const [termsConfig, setTermsConfig] = useState({
    value: false,
    valid: false,
  });

  const completeStep = async () => {
    setLoading(true);

    try {
      await updateApplicationStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: 'termsAndConditions',
          termsAndConditionsDateTime: new Date().toISOString(),
        },
        onError(error) {
          console.log("updateApplicationStepMutation", error)
        }
      });

      await updateApplicationStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: 'session_data',
          termsAndConditionsDateTime: new Date().toISOString(),
        },
        onError(error) {
          console.log("updateApplicationStepMutation", error)
        }
      });

      await completeApplicationStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: step_name || '',
        },
        onCompleted() {
          dispatch({
            type: 'complete-step',
            payload: {
              guid: selectedApplication || '',
              step: step_name,
            },
          });

          dispatch({
            type: 'ga-event',
            payload: {
              event: 'stdapp_sectionComplete',
              content: `(3.0) - ${step_name} - ${program}`,
            },
          });
        },
        onError(error) {
          console.log("completeApplicationStepMutation", error)
        }
      });

    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (termsConfig.value) {
      completeStep()
    }
  }, [termsConfig]);

  useEffect(() => {
    if (completedStepsDetails?.termsAndConditionsCompleted && termsConfig.valid) {
      setLoading(false);
      navigate('/');
    }
  }, [completedStepsDetails?.termsAndConditionsCompleted, termsConfig.valid]);

  const correctTerms = questions?.find(obj => {
    const allQuestionTermTypes = obj?.content?.dependent_value
      ?.split(',')
      .map(str => str.toUpperCase());
    return program && allQuestionTermTypes?.includes(program.toUpperCase());
  });

  const displayedTerm = questions.length === 1 ? questions[0] : correctTerms

  if (!displayedTerm) {
    return <h1>No Terms</h1>;
  }

  return (
    <TermsAndConditionsUI
      loading={loading}
      handleChange={setTermsConfig}
      pdf={displayedTerm?.content?.pdf}
    />
  );
};
